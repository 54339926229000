import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import TopMenu from "../TopMenu";
import Spinner from "react-bootstrap/Spinner";
import SharedEditNotesForm from "./SharedEditNotesForm";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../Constants/api-path";

const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const SharedEditNotes = ({ noteid }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [noteDetailsData, setNoteDetailsData] = useState("");

  return (
    <>
      <div
        className="dasb-div bk-div float-start w-100"
        style={{ paddingTop: "0px" }}
      >
        <div className="faq-section">
          <SharedEditNotesForm noteid={noteid} />
        </div>
      </div>
    </>
  );
};

export default SharedEditNotes;
