import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { categoryArray } from "../components/categories";
import { categoryArrayFour } from "../components/categoryFour";
import { categoryList } from "../components/CategoryList";
import { categoryArrayThree } from "../components/categoryThree";
import { categoryArrayTwo } from "../components/categoryTwo";
import API_PATH from "../Constants/api-path";
import Sidebar from "../Sidebar";

function EditCategories() {
  const history = useNavigate();
  const [selected, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [category, setCategory] = useState("0");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [open, setOpen] = useState(false);
  console.log(userData, "userData");
  const [currentPage, setCurrentPage] = useState(1);
  const [hide1, setHide] = useState(true);
  const [list, setList] = useState([]);
  const handleChange = (e) => {
    let value = e.target.value;
    if (value === "selectAll") {
    }
    if (Array.isArray(selected) && selected.includes(value)) {
      let array = selected;
      let index = array.indexOf(value);

      if (index >= 0) {
        array.splice(index, 1);
        setChecked([...array]);
        localStorage.setItem("selected", JSON.stringify([...array]));
      }
    } else {
      console.log([...selected, value]);
      setChecked([...selected, value]);
      localStorage.setItem("selected", JSON.stringify([...selected, value]));
    }
    // setChecked([...selected, e.target.value]);
    // localStorage.setItem(
    //   "selected",
    //   JSON.stringify([...selected, e.target.value])
    // );
  };

  useEffect(() => {
    let { ctPage } = queryString.parse(window.location.search);
    if (ctPage) {
      ctPage = atob(ctPage);
      if (!isNaN(parseInt(ctPage))) {
        setCurrentPage(parseInt(ctPage));
      }
    } else {
      setCurrentPage(1);
    }
    setChecked(userData.user.selectedCategory);
    if (selected.length == 30) {
      setIsCheckAll(true);
    }
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  console.log(selected.length, "selected.length");

  useEffect(() => {
    setList(categoryList);
  }, [list]);

  console.log(isCheckAll, "isCheckAll");

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      if (selected.length < 1) {
        return toast.error("Please select atleast 1 category", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        const res = await fetch(
          `${API_PATH.CATEGORY_UPDATE}/${userData.user._id}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              selectedCategory: selected,
            }),
          }
        );
        const result = await res.json();
        console.log("result", result);
        if (result) {
          if (result.success === true) {
            setHide(true);
            userData.user["selectedCategory"] = selected;
            localStorage.setItem("userData", JSON.stringify(userData));
            setCategory(selected);
            setCurrentPage(1);
            history("/find-expert");
            return toast.success("Your Categories are updated now", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      console.log(error);
      return toast.error("Please Login First to continue", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  function addHyphen(str) {
    return str.replace(/[^A-Z0-9]/gi, "_");
  }
  const setCategoryArray = () => {
    return categoryArray.map((category) => {
      return (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input categoryInput"
            name="expertView"
            for="select-all"
            value={category.key}
            id={addHyphen(category.key)}
            onChange={handleChange}
            checked={selected.includes(category.key)}
            // disabled={selected.length === 5}
            hidden

            // value={category.key}
            // onChange={handleChange}
            // checked={selected.includes(category.key)}
            // hidden
          />
          <label
            className="form-check-label ltsStrd"
            for={addHyphen(category.key)}
          >
            {category.label}
          </label>
        </div>
      );
    });
  };

  const setCategoryArrayTwo = () => {
    return categoryArrayTwo.map((category, index) => {
      return (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input categoryInput"
            name="expertView"
            for="select-all"
            value={category.key}
            id={addHyphen(category.key)}
            onChange={handleChange}
            checked={selected.includes(category.key)}
            hidden
          />
          <label
            className="form-check-label ltsStrd"
            for={addHyphen(category.key)}
          >
            {category.label}
          </label>
        </div>
      );
    });
  };

  const setCategoryArrayThree = () => {
    return categoryArrayThree.map((category, index) => {
      return (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input categoryInput"
            name="expertView"
            for="select-all"
            value={category.key}
            id={addHyphen(category.key)}
            onChange={handleChange}
            checked={selected.includes(category.key)}
            // disabled={selected.length === 5}
            hidden
          />
          {/* <input
            type="checkbox"
            className="form-check-input categoryInput"
            name="expertView"
            for="select-all"
            value={category.key}
            onChange={handleChange}
            checked={selected.includes(category.key)}
          /> */}
          <label
            className="form-check-label ltsStrd"
            for={addHyphen(category.key)}
          >
            {category.label}
          </label>
        </div>
      );
    });
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setChecked(list.map((li) => li.key));
    if (isCheckAll) {
      setChecked([]);
    }
    console.log(selected.length, "selected.length");
  };

  const setCategoryArrayFour = () => {
    return categoryArrayFour.map((category, index) => {
      return (
        <>
          {category.key === "selectAll" ? (
            <>
              {selected.length == 30 ? (
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input categoryInput"
                    name="expertView"
                    for="select-all"
                    value={category.key}
                    id={addHyphen(category.key)}
                    checked
                    onChange={handleSelectAll}
                    hidden
                  />
                  <label
                    className="form-check-label ltsStrd"
                    for={addHyphen(category.key)}
                  >
                    {category.label}
                  </label>
                </div>
              ) : (
                <>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input categoryInput"
                      name="expertView"
                      for="select-all"
                      value={category.key}
                      id={addHyphen(category.key)}
                      checked={isCheckAll}
                      onChange={handleSelectAll}
                      hidden
                    />
                    <label
                      className="form-check-label ltsStrd"
                      for={addHyphen(category.key)}
                    >
                      {category.label}
                    </label>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input categoryInput"
                name="expertView"
                for="select-all"
                value={category.key}
                id={addHyphen(category.key)}
                onChange={handleChange}
                checked={selected.includes(category.key)}
                hidden
              />
              <label
                className="form-check-label ltsStrd"
                for={addHyphen(category.key)}
              >
                {category.label}
              </label>
            </div>
          )}
        </>
      );
    });
  };

  return (
    <div>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages">
                <div className="row">
                  <div className="col-12">
                    <h4>Select Categories</h4>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">{setCategoryArray()}</div>
                  <div className="col">{setCategoryArrayTwo()}</div>
                  <div className="col">{setCategoryArrayThree()}</div>
                  <div className="col">{setCategoryArrayFour()}</div>
                </div>

                <div className="row justify-content-center mt-4">
                  <div className="col-12 col-md-4 col-lg-3 col-xxl-2">
                    <button
                      className="btn new-reguest active"
                      id="profile"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditCategories;
