import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Icon from "react-icons-kit";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpiredPage from "../components/ExpiredPage";
import API_PATH from "../Constants/api-path";

function ResetPasswordForm() {
  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const [visible2, setVisible2] = useState(false);
  const InputType2 = visible2 ? "text" : "password";
  const [passwordError, setPasswordError] = useState("");
  const loginData = JSON.parse(localStorage.getItem("userData"));
  const [isExpired, setExpired] = useState(false);
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [trackerBox, setTrackerBox] = useState(false);
  const [emailCode, setEmailCode] = useState("");
  const [isAuth, setIsAuth] = useState(false);

  // const [isExpired, setIsExpired] = useState(false);
  // const [isUsed, setIsUsed] = useState(false);
  // const [isExpired, setIsExpired] = useState(false);
  // const [isUsed, setIsUsed] = useState(false);

  const navigate = useNavigate();
  const [state, setstate] = useState({
    newPasswords: "",
    confirmPasswords: "",
  });

  function handleTrackerBox() {
    setTrackerBox(true);
  }

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...state, [name]: value };
    setstate(list);
  };

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setstate({ ...state, newPasswords: value });
    const minMaxLength = /^[\s\S]{8,16}$/;
    const upper = new RegExp("[A-Z]");
    const lower = new RegExp("[a-z]");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const number = new RegExp("[0-9]");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (minMaxLength.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true)
    } else {
      setSpecialValidated(false)
    }
  };

  useEffect(() => {
    getExpiredTime();
  }, [isExpired]);

  const getExpiredTime = async () => {
    try {
      let d = new Date();
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const res = await fetch(`${API_PATH.EXPIRE_TIME}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: atob(params.email),
        }),
      });
      const result = await res.json();
      if (result.data) {
        console.log(result.data);
        if (result.data == 0) {
          setExpired(true);
        }
        let expiredDate = result.data;
        console.log("bgfsdgsggahsjag", expiredDate);
        let token = atob(params.token);
        if (expiredDate < d.getTime()) {
          setExpired(true);
        }
        if (result.rData) {
          if (parseInt(token) !== atob(result.rData)) {
            setExpired(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const checkPassword = (password) => {
    var minMaxLength = /^[\s\S]{8,16}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/;

    if (
      minMaxLength.test(password) &&
      upper.test(password) &&
      lower.test(password) &&
      number.test(password)
    ) {
      //console.log("Is a Valid password")
      setPasswordError("");
    } else {
      //console.log("PassWord Should contain atleast one Uppercase, Lowercase and Numeric")
      return setPasswordError(
        "PassWord Should contain atleast one Uppercase, Lowercase and Numeric"
      );
    }
  };

  useEffect(() => {
    // Function to extract query parameters from the URL
    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'code' query parameter from the URL
    const codeFromURL = getQueryParameter("email");
    if (codeFromURL) {
      // If 'code' exists in the URL, set it in the state
      console.log("email from URL:", codeFromURL);
      setEmailCode(codeFromURL);
      // You can perform any additional actions with the 'code' here if needed
    } else {
      console.log("email not found in URL");
    }
  }, []);

  const saveResetPassword = async (e) => {
    e.preventDefault();

    const { newPasswords, confirmPasswords } = state;
    if (!newPasswords || !confirmPasswords) {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    if (newPasswords !== confirmPasswords) {
      return setPasswordError("Password does not match")
      // return toast.error("New Password and confirm password not matching!", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
    }
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      return setPasswordError("Password should meet the criteria")
      // return toast.error("Invalid Password", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 2000,
      // });;
    } else {
      setPasswordError("")
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const obj = {
      email: atob(params.email),
      token: atob(params.token),
      password: state.newPasswords,
    };

    const objDataFromAdmin = {
      email: loginData?.user?.email,
      password: state?.newPasswords,
    };

    try {
      if (emailCode) {
        getExpiredTime();
        const res = await fetch(`${API_PATH.RESET_PASSWORD}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        });
        const data = await res.json();
        if (data && data.status === 200) {
          // if (data.status === 200) {
          //   setIsUsed(true);
          // }
          navigate("/");
          // setVisited(!visited);
          return toast.success("Password changed successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error("Reset password link expired", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        const res = await fetch(`${API_PATH.RESET_PASSWORD}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(objDataFromAdmin),
        });
        const result = await res.json();
        if (result) {
          if (result.status === 200) {
            result.users = JSON.parse(atob(result?.users));
            result.access_token = atob(result?.access_token);
            result.refresh_token = atob(result?.refresh_token);
            setIsAuth(true);
            localStorage.setItem("userData", JSON.stringify(result));
            localStorage.setItem("isAuth", true);
            localStorage.setItem(
              "ChangePassword",
              JSON.stringify(state.confirmPasswords)
            );
            navigate("/dashboard");
            // setVisited(!visited);
            return toast.success("Login Successful", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          return toast.error("Reset password link expired", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      // if (!toastClosed) {
      // console.log(error, "error");
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        // onClose: () => setToastClosed(true),
      });
      // }
    }
  };

  useEffect(() => {
    if (isAuth) {
      let redirectPath = localStorage.getItem("redirectPath");
      if (redirectPath) {
        localStorage.removeItem("redirectPath");
        navigate(redirectPath);
      } else {
        navigate("/dashboard");
        setTimeout(() => {
          return toast.success("Login Successful!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }, 100);
      }
    }
  }, [isAuth]);

  const something = (event) => {
    if (event.keyCode === 13) {
      saveResetPassword(event);
    }
  };

  return (
    <>
      {isExpired ? (
        <ExpiredPage />
      ) : (
        <>
          <div className="form-group">
            <label> Password* </label>
            <div className="lable-input">
              <input
                name="newPasswords"
                id="password-field"
                type={InputType}
                // onChange={handleInputs}
                className="form-control"
                onFocus={() => handleTrackerBox()}
                required
                onChange={handleChange}
                onKeyDown={(e) => something(e)}
              />
              <span onClick={() => setVisible((visibilty) => !visibilty)}>
                {visible ? (
                  <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
                ) : (
                  <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
                )}
              </span>
            </div>
            <main className={trackerBox ? "tracker-box active" : "tracker-box"}>
              <div className={lowerValidated ? "validated" : "not-validated"}>
                {lowerValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one lowercase letter
              </div>
              <div className={upperValidated ? "validated" : "not-validated"}>
                {upperValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one uppercase letter
              </div>
              <div className={numberValidated ? "validated" : "not-validated"}>
                {numberValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one number
              </div>
              <div
                className={
                  specialValidated ? "validated" : "not-validated"
                }
              >
                {specialValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one special character
              </div>
              <div className={lengthValidated ? "validated" : "not-validated"}>
                {lengthValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                Must be at least 8 characters
              </div>
            </main>
            {passwordError && passwordError !== "Password does not match" && (
              <p className="text-danger" style={{ fontSize: 13 }}>{passwordError}</p>
            )}
          </div>

          <div className="form-group">
            <label>Confirm Password* </label>
            <div className="lable-input">
              <input
                name="confirmPasswords"
                id="password-field2"
                type={InputType2}
                onChange={handleInputs}
                className="form-control"
                required
                onKeyDown={(e) => something(e)}
              />
              <span onClick={() => setVisible2((visibilty2) => !visibilty2)}>
                {visible2 ? (
                  <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
                ) : (
                  <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
                )}
              </span>
            </div>
            {passwordError && passwordError === "Password does not match" && (
              <p className="text-danger" style={{ fontSize: 13 }}>{passwordError}</p>
            )}
          </div>

          <div className="form-group">
            <Button className="btn login-btn" onClick={saveResetPassword}
              disabled={state.newPasswords === "" || state.confirmPasswords === ""}

            >
              {" "}
              Set New Password
            </Button>
          </div>

          <div className="form-group">
            <p className="text-center">
              <NavLink to="/" className="register-btn px-2">
                Login
              </NavLink>
            </p>
          </div>
        </>
      )}
    </>
  );
}
export default ResetPasswordForm;
