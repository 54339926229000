import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
// import ResetPasssword from "../ResetPassword/ResetPassword";
import nextyn_logo from "../assests/images/NX19.png"
import nextyn_logo2 from "../assests/images/Nextyn_logo.png"

function ForgotPasssword() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });


  document.title = "Client | Login";
  return (
    <>
      <div className="login-page">
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col d-none d-md-block">
              <div className="login-left-part">
                <NavLink to="/" className="logo">
                <img src={nextyn_logo} alt="logo" style={{ width: "35%" }} />
                </NavLink>
                <div className="logo-in-div col-lg-7 mx-auto">
                  <h1 className="text-left text-white  " data-aos="fade-down">
                    Consulting experts is <br /> as easy as a click of a button.
                  </h1>
                </div>

                <div className="col-lg-7 mx-auto">
                  <div className="img-box-1">
                    <img src="images/Login.gif"></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="col position-relative">
              <div className="comon-fild col-lg-8 login-page-div">
                <div className="inside-login">
                  <NavLink to="" className="logo d-block d-lg-none mb-5">
                    <img src={nextyn_logo2} alt="logo" />
                  </NavLink>
                  <h4> Forgot Password? 🔒 </h4>
                  <p style={{ color: "#5746ec" }}>
                    It happens to all of us! Don't worry, we're here to help
                  </p>
                  <ForgotPasswordForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ResetPasssword /> */}
    </>
  );
}
export default ForgotPasssword;
