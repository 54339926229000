import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import React from "react";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { FaRedo } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotesImg from "../assests/images/Notes.svg";
import userColored from "../assests/images/userColored.svg";
import API_PATH from "../Constants/api-path";

function NotesSetions({ noteList, spinner }) {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      let p = `${localDateString} ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  function RemoveHtml(myString) {
    return myString.replace(/(<([^>]+)>)/gi, "");
  }

  const headers = {
    "x-access-token": userData.access_token,
  };

  const updateApiData = async (payload) => {
    try {
      const res = await fetch(`${API_PATH.NOTE_UPDATE}?NoteID=${payload._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        //getNotes();
      } else {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const UpdateApi = async (id) => {
    try {
      const res = await fetch(`${API_PATH.NOTE_DETAILS}/${id}`, {
        method: "GET",
        headers: headers,
      });

      const result = await res.json();
      if (result) {
        let payload = {
          ...result,
        };
        payload.viewedOn = new Date();

        updateApiData(payload);
      } else {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getNewsTable = () => {
    if (noteList instanceof Array) {
      return noteList?.map(function (object, i) {
        const { _id, name, description, createdAt, permittedUsers } =
          object;
        return (
          <>
            <div
              key={i}
              className="col notesParent"
              onClick={() => {
                UpdateApi(_id);
              }}
            >
              <NavLink to={"/call_recordings/notes-details?noteId=" + _id}>
                <div className="comon-notes">
                  <h6 className="note-titel "> {name} </h6>
                  <p className="paragraph">
                    {" "}
                    <p className="text-wrap-afte-three-line">
                      {RemoveHtml(description)}
                    </p>{" "}
                  </p>
                  <div className="auto-sec">
                    <p
                      style={{ fontSize: "12px", fontWeight: 500 }}
                      className="m-0"
                    >
                      {" "}
                      Created By{" "}
                      <span className="createdByName">
                        {object.userId.firstNmae
                          ? object.userId.firstNmae
                          : object.userId.username}
                      </span>
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mt-3 mb-0"> {convertDate(createdAt)} </h6>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        key={i}
                        placement={"top"}
                        overlay={
                          <Popover id={`popover-positioned-${i}`}>
                            <Popover.Body className="p-2">
                              {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                                Public link enabled
                              </h6> */}
                              <div>
                                <small>
                                  Shared with {permittedUsers?.length} people
                                </small>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {permittedUsers?.length > 0 ? (
                          <img
                            style={{ position: "relative", top: "11px" }}
                            src={userColored}
                            alt="img"
                          />
                        ) : (
                          <></>
                        )}
                        {/* //pus */}
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </>
        );
      });
    }
  };

  const createTable = () => {
    if (noteList instanceof Array) {
      return (
        <>
          <NavLink to="/call_recordings/create-notes">
            <div className="col notesParent ">
              <div className="comon-notes">
                <div className="auto-sec">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "23.55rem" }}
                  >
                    <div>
                      <div className="openfile">
                        <FileOpenOutlinedIcon
                          style={{
                            width: "30px",
                            color: "#ffffff",
                            fontSize: "30px",
                          }}
                        />
                      </div>

                      <h6
                        className="total-notes mt-3"
                        style={{ fontSize: "15px", fontWeight: "600" }}
                      >
                        Create a new note
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-flex top-ps-seection justify-content-between align-items-center">
        <h2 className="mb-0 search-btn-back">
          {" "}
          Notes
          {/* ({noteList ? noteList.length : 0}){" "} */}
        </h2>
        {/* <button
          className="btn add-ps-btn"
          data-bs-toggle="modal"
          data-bs-target="#NotesModal"
        >
          {" "}
          <BsPlusLg /> Create{" "}
        </button> */}
      </div>

      <div className="notes-d-section">
        <div className="row row-cols-1 mt-4 row-cols-sm-2 row-cols-lg-2 row-cols-xxl-4 row-cols-xl-3 scrolling-wrapper flex-row flex-nowrap mt-4 pb-4 pt-2 align-items-center">

          {spinner ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "20px" }}
            >
              <div role="status">
                <Spinner animation="border" />
              </div>
            </div>) : noteList?.length !== 0 ? (
              <>
                {userData?.user?.role_id?.collaborate?.notesAdd === true ? (
                  <>
                    {createTable()}
                  </>
                ) : null}
                {userData?.user?.role_id?.collaborate?.notesView === true ? (
                  <>
                    {noteList.length !== 0 ? (
                      getNewsTable()
                    ) : (
                      <>
                        <div className="col">
                          <img src={NotesImg} alt="img" />
                        </div>
                        <div className="col">
                          <p style={{ fontSize: "14px", fontWeight: "300" }}>
                            Like our managers always say "Note it down"
                          </p>
                          <p style={{ fontSize: "14px", fontWeight: "300" }}>
                            Keep creating notes here and never forget a task!
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : (
            <>
              <div className="col">
                <img src={NotesImg} alt="img" />
              </div>
              <div className="col">
                <p style={{ fontSize: "14px", fontWeight: "300" }}>
                  Like our managers always say "Note it down"
                </p>
                <p style={{ fontSize: "14px", fontWeight: "300" }}>
                  Keep creating notes here and never forget a task!
                </p>
              </div>
            </>
          )}

        </div>


        {noteList.length > 6 ? (
          <span
            id="#"
            className="loading-btn btn text-center mx-auto d-table mt-4"
          >
            {" "}
            <FaRedo /> Load More Notes{" "}
          </span>
        ) : null}
      </div>
    </>
  );
}
export default NotesSetions;
