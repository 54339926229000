/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import { DownloadOutlined } from "@ant-design/icons";
import Button from "@mui/material/Button";
import { DatePicker, Radio, Space } from "antd";
import { Markup } from "interweave";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import "../App.css";
import "../assests/css/custom.css";
import expert_call from "../assests/images/Call-center-rafiki.svg";
import no_result from "../assests/images/Empty-pana.svg";

const { RangePicker } = DatePicker;

function CompleteTables({
  expertCellData,
  setSdate,
  setEdate,
  setCallType,
  callType,
  getExpertcall
}) {
  console.log(expertCellData, "expertCellData");
  // console.log(setCallType, "setCallType")
  // console.log(setEdate, "setEdate")
  // console.log(setSdate, "setSdate")
  console.log(callType, "callType");
  const [CompletedExperts, setCompletedExperts] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);

  const [expert_popup, setExpertPopup] = useState({});
  const [modalToggle, setModalToggle] = useState(false);
  const [modalToggle1, setModalToggle1] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [expertID, setExpertID] = useState("");
  const [status, setStatus] = useState("");
  const headers = {
    "Content-Type": "text/plain",
    "x-access-token": UserData.access_token,
  };
  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };
  //console.log(UserData.user.email)
  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle(false);
  const handleClose2 = () => setModalToggle1(false);
  const csvDataArry = [];
  expertCellData.map((records) =>
    csvDataArry.push({
      "Most Relevant Title": records?.admin_invite_experts
        ?.admin_expert_experience?.current_designation === null
        ? records?.admin_invite_experts
          ?.admin_expert_experience
          ?.previous_designation
        : records?.admin_invite_experts
          ?.admin_expert_experience
          ?.current_designation,
      Company: records?.curElem?.admin_invite_experts
        ?.admin_expert_experience?.current_compny === null
        ? records?.admin_invite_experts
          ?.admin_expert_experience?.previous_compny
        : records?.admin_invite_experts
          ?.admin_expert_experience?.current_compny,
      Duration: records?.call_duration,
      Rate: records?.admin_invite_experts
        ?.admin_forward_experts_detail?.expertCurrency
        + records?.admin_invite_experts
          ?.admin_forward_experts_detail
          ?.hourly_consulting_rate,
      "Date Added": convertDate(records?.admin_invite_experts?.admin_expert_experience?.created_at),
    })
  );
  const csvReport = {
    filename: "Report.csv",
    data: csvDataArry,
  };

  const getcurrentMonth = () => {
    let d = new Date();
    let m = d.getMonth() + 1;
    if (m < 10) m = `0${m}`;
    setSdate(d.getFullYear() + "-" + m + "-01");
    setEdate(d.getFullYear() + "-" + m + "-31");
    setCallType("currentMonth");
    console.log("currentMonth");
  };

  const getprevMonth = () => {
    let d = new Date();
    let m = d.getMonth();
    if (m < 10) m = `0${m}`;
    setSdate(d.getFullYear() + "-" + m + "-01");
    setEdate(d.getFullYear() + "-" + m + "-31");
    setCallType("prevMonth");
    console.log("prevMonth");

    // setSdate(
    //   moment(new Date())
    //     .subtract(1, "months")
    //     .startOf("month")
    //     .format("YYYY-MM-DD")
    // );
    // setEdate(
    //   moment(new Date())
    //     .subtract(1, "months")
    //     .endOf("month")
    //     .format("YYYY-MM-DD")
    // );
  };

  useEffect(() => {
    recentData();
  }, []);

  const recentData = () => {
    setCallType("recent");
    setSdate('');
    setEdate('');
    getExpertcall();
    console.log("recent");
  };

  const rangeData = (date, dateString) => {
    console.log("dateString", dateString);
    setSdate(moment(dateString[0]).startOf("month").format("YYYY-MM-DD"));
    setEdate(moment(dateString[1]).endOf("month").format("YYYY-MM-DD"));
    setCallType("rangeData");
    console.log("");
  };

  // useEffect(() => {
  //   getExperts();
  // }, []);

  useEffect(() => {
    // setCompletedExperts(data);
    // console.log("data", data);
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  // const getExperts = () => {
  //   // console.log(arr);
  //   axios
  //     .post(
  //       `${API_PATH.ALL_CLIENT_PROJECT_EXPERT_LIST}`,
  //       {
  //         email: UserData.user.email,
  //       },
  //       {
  //         headers,
  //       }
  //     )
  //     .then((res) => {
  //       console.log("res.data", res.data);
  //       setLoading(true);
  //       setCompletedExperts([...res.data.completed]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // console.log("CompletedExperts", CompletedExperts);
  // const getExpertDetails = (id) => {
  //   setExpertID(id);
  //   axios
  //     .post(
  //       `${API_PATH.EXPERT_POPUP_DETAILS}/${id}/${project_id}`,
  //       UserData.user.email,
  //       { headers }
  //     )
  //     .then((res) => {
  //       setLoading1(true);
  //       setExpertPopup({ ...res.data.data });
  //       //console.log("results", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  //console.log(data);
  // console.log("expertpopup", expert_popup);

  const expertStatusChanger = (status) => {
    setStatus(status.trim());
    setModalToggle(true);
    //console.log(status);
  };

  const first_charc_uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const statusSetter = (status) => {
    if (status === "Schedule" || status === "Scheduled") {
      return (
        <h4>
          Are you sure you want to schedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.{" "}
        </h4>
      );
    } else if (status === "Decline") {
      return <h4>Are you sure you want to decline a call with this expert?</h4>;
    } else if (status === "Cancel") {
      return <h4>Are you sure you want to cancel a call with this expert?</h4>;
    } else if (status === "Reschedule" || status === "Rescheduled") {
      return (
        <h4>
          Are you sure you want to reschedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.
        </h4>
      );
    }
  };

  const statusButton = (status) => {
    if (status === "Schedule" || status === "Scheduled") {
      return "Schedule";
    } else if (status === "Decline") {
      return "Decline";
    } else if (status === "Cancel") {
      return "Cancel";
    } else if (status === "Reschedule" || status === "Rescheduled") {
      return "Reschedule";
    }
  };

  // const handleSubmit = async (status) => {
  //   if (
  //     status === "Schedule" ||
  //     status === "Reschedule" ||
  //     status === "Scheduled" ||
  //     status === "Rescheduled"
  //   ) {
  //     axios
  //       .post(
  //         `${API_PATH.CLIENT_SCHEDULE}/${project_id}/${expertID}`,
  //         UserData.user.email,
  //         { headers }
  //       )
  //       .then((res) => {
  //         // console.log(res);
  //         setModalToggle(false);
  //         setModalToggle1(true);
  //         setTimeout(() => {
  //           setModalToggle1(false);
  //           window.location.reload();
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else if (status === "Decline") {
  //     axios
  //       .post(
  //         `${API_PATH.CLIENT_DECLINE}/${project_id}/${expertID}`,
  //         UserData.user.email,
  //         { headers }
  //       )
  //       .then((res) => {
  //         // console.log(res);
  //         setModalToggle(false);
  //         setModalToggle1(true);
  //         setTimeout(() => {
  //           setModalToggle1(false);
  //           window.location.reload();
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else if (status === "Cancel") {
  //     axios
  //       .post(
  //         `${API_PATH.CLIENT_SCHEDULECANCEL}/${project_id}/${expertID}`,
  //         UserData.user.email,
  //         { headers }
  //       )
  //       .then((res) => {
  //         // console.log(res);
  //         setModalToggle(false);
  //         setModalToggle1(true);
  //         setTimeout(() => {
  //           setModalToggle1(false);
  //           window.location.reload();
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  const getCallHistory = () => {
    if (expertCellData?.length > 0) {
      if (callType === "recent") {
        return (
          <>
            <div>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th title="Gender" style={{ width: "40%" }}>
                      Most Relevant Title
                    </th>
                    <th title="Age">Company</th>
                    {/* <th title="Age" style={{ width: "140px" }}>
                      Duration{" "}
                    </th> */}
                    <th title="Age" style={{ width: "140px" }}>
                      Rate
                    </th>
                    <th style={{ width: "90px" }}>DATE ADDED</th>
                  </tr>
                </thead>
                <tbody>
                  {expertCellData?.map((curElem, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td style={{ color: "#526fd7", fontWeight: "500" }}>
                            {curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_designation ===
                              null
                              ? curElem?.admin_invite_experts
                                ?.admin_expert_experience
                                ?.previous_designation
                              : curElem?.admin_invite_experts
                                ?.admin_expert_experience
                                ?.current_designation}
                          </td>
                          <td>
                            {curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_compny === null
                              ? curElem?.admin_invite_experts
                                ?.admin_expert_experience?.previous_compny
                              : curElem?.admin_invite_experts
                                ?.admin_expert_experience?.current_compny}
                          </td>
                          {/* <td>{`$${rate[1]}`}</td> */}
                          {/* <td>call_duration</td> */}
                          <td style={{ color: "#526fd7", fontWeight: "500" }}>
                            {" "}
                            {
                              curElem?.admin_invite_experts
                                ?.admin_forward_experts_detail?.expertCurrency
                            }{" "}
                            {
                              curElem?.admin_invite_experts
                                ?.admin_forward_experts_detail
                                ?.hourly_consulting_rate
                            }{" "}
                          </td>

                          <td style={{ width: "150px" }}>
                            {convertDate(
                              curElem?.admin_invite_experts
                                ?.admin_expert_experience?.created_at
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      } else if (callType === "currentMonth") {
        return (
          <div>
            <table className="table mt-4">
              <thead>
                <tr>
                  <th title="Gender" style={{ width: "40%" }}>
                    Most Relevant Title
                  </th>
                  <th title="Age">Company</th>
                  <th title="Age" style={{ width: "140px" }}>
                    Duration{" "}
                  </th>
                  <th title="Age" style={{ width: "140px" }}>
                    Rate
                  </th>
                  <th style={{ width: "90px" }}>DATE ADDED</th>
                </tr>
              </thead>
              <tbody>
                {expertCellData?.map((curElem, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td style={{ color: "#526fd7", fontWeight: "500" }}>
                          {curElem?.admin_invite_experts
                            ?.admin_expert_experience?.current_designation ===
                            null
                            ? curElem?.admin_invite_experts
                              ?.admin_expert_experience?.previous_designation
                            : curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_designation}
                        </td>
                        <td>
                          {curElem?.admin_invite_experts
                            ?.admin_expert_experience?.current_compny === null
                            ? curElem?.admin_invite_experts
                              ?.admin_expert_experience?.previous_compny
                            : curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_compny}
                        </td>
                        {/* <td>{`$${rate[1]}`}</td> */}
                        <td>call_duration</td>
                        <td style={{ color: "#526fd7", fontWeight: "500" }}>
                          {" "}
                          {
                            curElem?.admin_invite_experts
                              ?.admin_forward_experts_detail?.expertCurrency
                          }{" "}
                          {
                            curElem?.admin_invite_experts
                              ?.admin_forward_experts_detail
                              ?.hourly_consulting_rate
                          }{" "}
                        </td>

                        <td style={{ width: "150px" }}>
                          {convertDate(
                            curElem?.admin_invite_experts
                              ?.admin_expert_experience?.created_at
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      } else if (callType === "rangeData") {
        return (
          <>
            <div>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th title="Gender" style={{ width: "40%" }}>
                      Most Relevant Title
                    </th>
                    <th title="Age">Company</th>
                    <th title="Age" style={{ width: "140px" }}>
                      Duration{" "}
                    </th>
                    <th title="Age" style={{ width: "140px" }}>
                      Rate
                    </th>
                    <th style={{ width: "90px" }}>DATE ADDED</th>
                  </tr>
                </thead>
                <tbody>
                  {expertCellData?.map((curElem, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td style={{ color: "#526fd7", fontWeight: "500" }}>
                            {curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_designation ===
                              null
                              ? curElem?.admin_invite_experts
                                ?.admin_expert_experience
                                ?.previous_designation
                              : curElem?.admin_invite_experts
                                ?.admin_expert_experience
                                ?.current_designation}
                          </td>
                          <td>
                            {curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_compny === null
                              ? curElem?.admin_invite_experts
                                ?.admin_expert_experience?.previous_compny
                              : curElem?.admin_invite_experts
                                ?.admin_expert_experience?.current_compny}
                          </td>
                          {/* <td>{`$${rate[1]}`}</td> */}
                          <td>call_duration</td>
                          <td style={{ color: "#526fd7", fontWeight: "500" }}>
                            {" "}
                            {
                              curElem?.admin_invite_experts
                                ?.admin_forward_experts_detail?.expertCurrency
                            }{" "}
                            {
                              curElem?.admin_invite_experts
                                ?.admin_forward_experts_detail
                                ?.hourly_consulting_rate
                            }{" "}
                          </td>

                          <td style={{ width: "150px" }}>
                            {convertDate(
                              curElem?.admin_invite_experts
                                ?.admin_expert_experience?.created_at
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      } else if (callType === "prevMonth") {
        return (
          <>
            <div>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th title="Gender" style={{ width: "40%" }}>
                      Most Relevant Title
                    </th>
                    <th title="Age">Company</th>
                    <th title="Age" style={{ width: "140px" }}>
                      Duration{" "}
                    </th>
                    <th title="Age" style={{ width: "140px" }}>
                      Rate
                    </th>
                    <th style={{ width: "90px" }}>DATE ADDED</th>
                  </tr>
                </thead>
                <tbody>
                  {expertCellData?.map((curElem, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td style={{ color: "#526fd7", fontWeight: "500" }}>
                            {curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_designation ===
                              null
                              ? curElem?.admin_invite_experts
                                ?.admin_expert_experience
                                ?.previous_designation
                              : curElem?.admin_invite_experts
                                ?.admin_expert_experience
                                ?.current_designation}
                          </td>
                          <td>
                            {curElem?.admin_invite_experts
                              ?.admin_expert_experience?.current_compny === null
                              ? curElem?.admin_invite_experts
                                ?.admin_expert_experience?.previous_compny
                              : curElem?.admin_invite_experts
                                ?.admin_expert_experience?.current_compny}
                          </td>
                          {/* <td>{`$${rate[1]}`}</td> */}
                          <td>call_duration</td>
                          <td style={{ color: "#526fd7", fontWeight: "500" }}>
                            {" "}
                            {
                              curElem?.admin_invite_experts
                                ?.admin_forward_experts_detail?.expertCurrency
                            }{" "}
                            {
                              curElem?.admin_invite_experts
                                ?.admin_forward_experts_detail
                                ?.hourly_consulting_rate
                            }{" "}
                          </td>

                          <td style={{ width: "150px" }}>
                            {convertDate(
                              curElem?.admin_invite_experts
                                ?.admin_expert_experience?.created_at
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="text-center noResultFound">
              <div className="container">
                <div
                  className="row d-flex justify-content-center align-items-center"
                  style={{ height: "calc(100vh - 250px)" }}
                >
                  <div className="col-md-12">
                    <img
                      src={expert_call}
                      alt="srv"
                      style={{ marginTop: "10px", height: "450px" }}
                    ></img>
                  </div>
                  {/* <div className="col-md-6">
                    <h3
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>Oops,</span> Looks
                      like you're yet to successfully complete a consultation.
                    </h3>
                    <h3
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      Fingers crossed, this page will be full of successful
                      calls, shortly.
                    </h3>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          {loading ? (
            <div className="text-center noResultFound">
              <div className="container">
                <div
                  className="row d-flex justify-content-center align-items-center"
                  style={{ height: "calc(100vh - 250px)" }}
                >
                  <div className="col-md-6">
                    <img
                      src={no_result}
                      alt="srv"
                      style={{ marginTop: "10px", height: "450px" }}
                    ></img>
                  </div>
                  <div className="col-md-6">
                    <h3
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>Oops,</span> Looks
                      like you're yet to successfully complete a consultation.
                    </h3>
                    <h3
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      Fingers crossed, this page will be full of successful
                      calls, shortly.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  const getExperienceList = (data) => {
    if (
      data.previous_designation.length <= 0 ||
      data.previous_compny.length <= 0 ||
      data.previous_date.length <= 0
    ) {
      return null;
    } else {
      let experienceList = [];
      for (let i = 0; i < data.previous_designation.length; i++) {
        let obj = {};
        obj.previous_designation = data.previous_designation[i];
        obj.previous_compny = data.previous_compny[i];
        obj.previous_date = data.previous_date[i];
        experienceList.push(obj);
      }
      return experienceList;
    }
  };

  // console.log(getExperienceList(expert_popup));

  if (!loading) {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="mt-2 d-flex justify-content-lg-between align-items-center">
          <Space direction="horizontal" size={12}>
            <RangePicker
              picker="month"
              onChange={rangeData}
              format={"YYYY-MMM"}
            />
            <Radio.Group defaultValue="Recent">
              <Radio.Button
                value="Current month"
                onClick={() => getcurrentMonth()}
              >
                Current month
              </Radio.Button>
              <Radio.Button
                value="Previous month"
                onClick={() => getprevMonth()}
              >
                Previous month
              </Radio.Button>
              <Radio.Button
                // defaultChecked={true}
                defaultChecked
                value="Recent"
                onClick={() => recentData()}
              >
                Recent
              </Radio.Button>
            </Radio.Group>
          </Space>
          <Space>
            {expertCellData.length > 0 ? (
              <>
                <CSVLink className="csvlink" {...csvReport}>
                  <button
                    className="btn viewButton"
                    style={{ fontSize: "14px" }}
                  >
                    <DownloadOutlined />  CSV
                  </button>
                </CSVLink>
              </>
            ) : (
              <></>
            )}
          </Space>
        </div>
        <>
          {/* {CompletedExperts.length > 0 ? ( */}
          {getCallHistory()}
          {/* ) : (
            <>
              {loading ? (
                <div className="text-center noResultFound">
                  <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-6">
                        <img
                          src={no_result}
                          alt="srv"
                          style={{ marginTop: "10px", height: "450px" }}
                        ></img>
                      </div>
                      <div className="col-md-6">
                        <h3
                          style={{
                            fontSize: "18px",
                            textAlign: "left",
                            fontWeight: "300",
                            lineHeight: "26px",
                          }}
                        >
                          <span style={{ fontWeight: "500" }}>Oops,</span> Looks
                          like you're yet to successfully complete a
                          consultation for this project. Fingers crossed, this
                          page will be full of successful calls, shortly.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )} */}

          <div
            className="offcanvas offcanvas-end comon-tabs-expertcalls"
            tabIndex="-1"
            id="Completed"
            aria-labelledby="offcanvasRightLabel"
          >
            {loading1 ? (
              <>
                <div
                  className="offcanvas-header align-items-center justify-content-between"
                  style={{
                    borderBottom: "1px solid rgb(197, 195, 195)",
                  }}
                >
                  <button
                    type="button"
                    className="btn-close "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                  <h5 id="offcanvasRightLabel">Expert Profile</h5>
                </div>
                <div className="offcanvas-body">
                  <div>
                    <h2
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#000",
                      }}
                    >
                      {expert_popup.current_designation}
                    </h2>
                    <div
                      className="nameAndCompany_M"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#444",
                        marginTop: "-8px",
                      }}
                    >
                      <strong
                        id="nameAndCompany_M"
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                          color: "#444",
                        }}
                      >
                        {expert_popup.current_compny}
                      </strong>
                      <span> {expert_popup.current_location}</span>
                    </div>
                  </div>
                  {/* <div className="d-flex mt-2 gap-4">
                  {expertPopupStatus(expert_popup.status)}
                </div> */}
                  <div className="mt-2">
                    <div className="expertPanel_3Zo3_">
                      <div className="section_3ByhA">
                        <div className="detailsRows_2038l">
                          <span className="detailsRowLeft_3h_zH">Region</span>
                          <span className="detailsRowRight_3h_zH">
                            {expert_popup.current_location}
                          </span>
                        </div>
                        <div className="detailsRows_2038l">
                          <span className="detailsRowLeft_3h_zH">
                            Consulting Rate
                          </span>
                          <span className="detailsRowRight_3h_zH">
                            $ {expert_popup.hourly_consulting_rate}/hr
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="overview mt-2">
                    <h2
                      style={{
                        fontWeight: "500",
                        color: "#000",
                        fontSize: "14px",
                      }}
                    >
                      Overview
                    </h2>
                    <Markup
                      className="markup-para"
                      content={expert_popup.overview}
                    />
                  </div>
                  <div className="experien-list mt-4">
                    <h6
                      style={{
                        fontWeight: "500",
                        color: "#000",
                        fontSize: "14px",
                      }}
                    >
                      Experience
                    </h6>
                    <div>
                      <ul className="nextyn_list_items">
                        <li className="nextyn_list_item">
                          <div className="expertCompany_2UyI2">
                            <span className="title_1p_LH">
                              {expert_popup.current_designation}
                            </span>
                            <div className="companyName_1dSwA">
                              {" "}
                              {expert_popup.current_compny}
                            </div>
                          </div>
                          <span className="careerDates_3sJ2b">
                            {" "}
                            {expert_popup.current_date}
                          </span>
                        </li>

                        {getExperienceList(expert_popup).map((curElem, i) => {
                          return (
                            <>
                              <li className="nextyn_list_item" key={i}>
                                <div className="expertCompany_2UyI2">
                                  <span className="title_1p_LH">
                                    {" "}
                                    {curElem.previous_designation}
                                  </span>
                                  <div className="companyName_1dSwA">
                                    {curElem.previous_compny}
                                  </div>
                                </div>
                                <span className="careerDates_3sJ2b">
                                  {curElem.previous_date}
                                </span>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="offcanvas-header">
                  <h6 style={{ color: "#39c4a3" }} id="offcanvasRightLabel">
                    Completed
                  </h6>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
        <Modal
          show={modalToggle}
          onHide={handleClose1}
          dialogClassName="my-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ marginBottom: "20px" }} closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              <h2>{first_charc_uppercase(status)} a call with the expert</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "100%", textAlign: "left" }}>
              {statusSetter(status)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              data-bs-dismiss="modal"
              onClick={() => {
                // handleSubmit(status);
              }}
            >
              {statusButton(status)}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalToggle1}
          onHide={handleClose2}
          dialogClassName="my-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
            >
              <img
                src="images/right-s.svg"
                alt="svg"
                style={{ marginBottom: "20px" }}
              />
              <h3> {status} Successfully </h3>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default CompleteTables;
