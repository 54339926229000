import React, { useState } from "react";
import { Dropdown, FormControl, InputGroup } from "react-bootstrap";
import { Tooltip, Tag } from "antd";
import { createSearchParams, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import "../assests/css/custom.css";
import company_icon3 from "../assests/images/company_icons3.png";
import starredExpert from "../assests/images/starredExpert.svg";
import StarredButton from "../components/StarredButton";
import API_PATH from "../Constants/api-path";
import { CalculateExpertRate } from "../Constants/CalculateExpertRate";
import { CurrencyIcon } from "../Utils/CurrencyIcon";
import { Col, Row } from "react-bootstrap";

const text = <span>Industry</span>;
const text2 = <span>Tags</span>;
const customColors = ["#5746ec"];

function Starred({ props }) {
  if (props) {
    console.log(props);
  }

  const [payload, setPayload] = useState({
    email: "",
    permission: "view",
  });
  const [userEmail, setEmail] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log("userData", userData);
  const [show, setShow] = useState(false);
  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  const [workData, setworkData] = useState({});
  const history = useNavigate();
  const [expertDetails, setExpertDetails] = useState();

  const goToDetailsPage = (expertId) => {
    history({
      pathname: "/expert-profile",
      search: `?${createSearchParams({ expertId })}`,
    });
  };

  const getData = (data) => {
    //console.log(data);
    setworkData({ ...data });
    //console.log(workData);
  };

  const modalClose = () => {
    setEmail("");
  };

  const goToFcPage = (data) => {
    history({
      pathname: "/fc",
      search: `?${createSearchParams({
        expertId: data?._id ? data?._id : data?._id,
      })}`,
    });
    return toast.success("Please select a time slot", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const getExpertDetails = async (expertId) => {
    try {
      const res = await fetch(
        `${API_PATH.GETSINGLE_EXPERT}/${expertId}/${userData.user._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result);
        setExpertDetails(result.data);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const stopSharing = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${API_PATH.SHARE_EXPERT}/${workData?._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({ permittedUsers: [] }),
        }
      );

      const result = await res.json();
      if (result) {
        getExpertDetails(workData.expert_id);
        return toast.success("User removed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };
  const headers = {
    "x-access-token": userData.access_token,
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };

  const getPUserList = () => {
    if (expertDetails) {
      if (expertDetails.permittedUsers instanceof Array) {
        return expertDetails.permittedUsers.map(function (object, i) {
          const { _id, firstNmae, email, permission, username, expert_id } =
            object;
          return (
            <>
              <div className="shareWrppr text-start" id={_id}>
                <div className="emailSection">
                  <div className="profileIconWrppr">
                    <div className="avatarImg">
                      <img
                        width="45px"
                        src={
                          "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                          email
                        }
                      />
                    </div>

                    {/* <div
                    className="profileIcon"
                    style={{
                      backgroundImage: `url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${email})`,
                    }}
                  ></div> */}
                    <div className="ms-3">
                      <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                        <span className="">
                          {firstNmae ? firstNmae : username}
                        </span>
                      </div>
                      <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                        <span className="">{email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Yourr_9w71MjtL3S9I4G">
                  <div className="">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdownBtn"
                        id="dropdown-basic"
                        style={{
                          background: "none",
                          border: "none",
                          color: "#000000",
                        }}
                      >
                        {getDefaultText(permission)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() =>
                            updatePermission("view", email, expert_id)
                          }
                        >
                          Can view
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          );
        });
      }
    }
  };

  const updatePermission = async (permission, email, expert_id) => {
    try {
      const res = await fetch(`${API_PATH.SHARE_EXPERT}/${expert_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({ permission: permission, email: email }),
      });

      const result = await res.json();
      if (result) {
        getExpertDetails(expert_id);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const makeUpdate = async (event) => {
    event.preventDefault();
    if (userEmail !== "") {
      try {
        const res = await fetch(
          `${API_PATH.SHARE_EXPERT}/${workData?._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: payload.permission,
              email: payload.email,
            }),
          }
        );

        const result = await res.json();
        if (result) {
          if (result.status === 200) {
            setEmail("");
            getExpertDetails(workData._id);
            return toast.success(result.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            return toast.error(result.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  //console.log(workData);
  const ConstantsArray = (array) => {
    var type = typeof array;
    let newArray = array;
    if (type == "string") {
      newArray = array?.split(",");
    }
    return newArray;
  };

  if (props) {
    console.log("1111111111111111111111111");
    if (props.length === 0 || !Array.isArray(props)) {
      return (
        <>
          <div
            className="text-center noResultFound"
            style={{ maxWidth: "900px" }}
          >
            <div className="container">
              <div
                className="row d-flex justify-content-center align-items-center"
                style={{ height: "calc(100vh - 320px)" }}
              >
                <div className="col-md-7">
                  <img
                    src={starredExpert}
                    style={{ width: "100%", marginTop: "50px" }}
                    alt="starredExpert"
                  />
                </div>
                <div className="col-md-5">
                  <h3
                    style={{
                      fontSize: "18px",
                      textAlign: "left",
                      fontWeight: "300",
                      lineHeight: "26px",
                    }}
                  >
                    No experts starred yet!
                  </h3>
                  <h3
                    style={{
                      fontSize: "18px",
                      textAlign: "left",
                      fontWeight: "300",
                      lineHeight: "26px",
                    }}
                  >
                    {" "}
                    Please visit the "Find Experts" page to select and add
                    experts to your starred list for ease of reference.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {props.map((hit, index) => {
            hit.isStarred = true;
            {
              console.log(hit, "hitIstarred");
            }
            return (
              <div
                className="comon-divs-re d-md-flex align-items-center justify-content-between findExperts"
                key={index}
              >
                <div
                  className="col-lg-9"
                  onClick={() => goToDetailsPage(hit?._id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex align-items-center mb-4">
                    <span>
                      {hit.company_logo !== null &&
                        hit.company_logo !== undefined &&
                        hit.company_logo !== "" ? (
                        <img
                          loading="lazy"
                          className="me-2"
                          style={{ width: "95px", borderRadius: "5px" }}
                          src={hit.company_logo}
                          onError={addDefaultSrc}
                          alt="sv"
                        />
                      ) : (
                        <div
                          className="bg-grayLight flex justify-center items-center w-8 h-8 rounded-xl"
                          data-testid="gray-icon"
                        >
                          <img
                            loading="lazy"
                            className="me-2"
                            style={{ maxWidth: "95px", borderRadius: "5px" }}
                            src={company_icon3}
                            alt="sv"
                          />
                        </div>
                      )}
                    </span>
                    <div className="ms-2">
                      {hit.company !== null &&
                        hit.company !== undefined &&
                        hit.company !== "" ? (
                        <h4
                          className="find_companyName"
                          style={{ marginBottom: "10px" }}
                        >
                          {hit.company}{" "}
                        </h4>
                      ) : null}
                      <h5 className="linkH5">{hit.designation}</h5>
                      <p
                        className="mb-0"
                        style={{ fontSize: "13px", color: "gray" }}
                      >
                        {hit.country !== null &&
                          hit.country !== undefined &&
                          hit.country !== "" ? (
                          <>
                            <i className="las la-globe-americas me-2"></i>
                            {hit.country}{" "}
                          </>
                        ) : null}
                      </p>
                    </div>
                  </div>

                  <Row className="mt-2">
                    <Col>
                      {hit.industry !== null || hit.industry !== undefined ? (
                        <div className="d-flex next-list mt-3 align-items-top">
                          <span className="me-2">
                            {customColors?.map((color) => (
                              <Tooltip
                                placement="top"
                                color={color}
                                title={text}
                              >
                                <svg
                                  style={{
                                    fontSize: "20px",
                                    color: "rgb(87, 70, 236)",
                                  }}
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 24 24"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path
                                      fill="none"
                                      d="M0 0h24v24H0z"
                                    ></path>
                                    <path d="M10 10.111V1l11 6v14H3V7l7 3.111zm2-5.742v8.82l-7-3.111V19h14V8.187L12 4.37z"></path>
                                  </g>
                                </svg>
                              </Tooltip>
                            ))}
                          </span>

                          <div>
                            {ConstantsArray(hit.industry).map((curElem, index) => {
                              return (
                                <>
                                  {index < 3 ? (
                                    <Tag
                                      color="blue"
                                      bordered={false}
                                      key={index}
                                    >
                                      {curElem}
                                    </Tag>
                                  ) : (
                                    <>
                                      {index === 3 ? (
                                        <Tag
                                          color="blue"
                                          bordered={false}
                                          key={index}
                                        >
                                          {"+" +
                                            (ConstantsArray(hit.industry).length -
                                              3)}
                                        </Tag>

                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      {hit.tags !== null || hit.tags !== null ? (
                        <div className="d-flex next-list align-items-top mt-2">
                          <span className="me-2">
                            {customColors.map((color) => (
                              <Tooltip
                                placement="top"
                                color={color}
                                title={text2}
                              >
                                <svg
                                  style={{
                                    fontSize: "20px",
                                    color: "rgb(87, 70, 236)",
                                  }}
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-width="1.5"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                                  ></path>
                                </svg>
                              </Tooltip>
                            ))}
                          </span>

                          <div>
                            {ConstantsArray(hit.tags).map((curElem, index) => {
                              if (curElem.length > 1) {
                                return (
                                  <>
                                    {index < 3 ? (
                                      <Tag
                                        color="volcano"
                                        bordered={false}
                                        key={index}
                                      >
                                        {curElem}
                                      </Tag>
                                    ) : (
                                      <>
                                        {index === 3 ? (
                                          <Tag
                                            color="volcano"
                                            bordered={false}
                                            key={index}
                                          >
                                            {"+" +
                                              (ConstantsArray(hit?.tags)?.length -
                                                3)}
                                          </Tag>

                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              } else {
                              }
                            })}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="col-lg-2">
                  <div className="right-divs-2 mt-5 mt-md-0 text-center">
                    <h5 className="expertRate">
                      <CurrencyIcon
                        currencyType={userData?.user?.company_id?.currency}
                      />
                      {userData?.user?.company_id?.expertRate == 0 ? (
                        <CalculateExpertRate
                          rate={hit.hourly_rate_call_cunsltn}
                        />
                      ) : userData?.user?.company_id?.expertRate ? (
                        userData?.user?.company_id?.expertRate
                      ) : (
                        <CalculateExpertRate
                          rate={hit.hourly_rate_call_cunsltn}
                        />
                      )}
                      <span> / hour </span>{" "}
                    </h5>
                    <div className="mt-4" onClick={() => goToFcPage(hit)}>
                      <NavLink to={""} className="btn new-reguest">
                        Request
                      </NavLink>
                    </div>
                    <div
                      className="d-flex justify-content-around mt-4 w-75 mx-auto"
                      onClick={() => getData(hit)}
                    >
                      <NavLink
                        to="#"
                        className="me-4"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal2"
                        onClick={() => {
                          getExpertDetails(
                            hit.expertId ? hit.expertId : hit.expert_id
                          );
                          return;
                        }}
                      >
                        {" "}
                        <img
                          src="/images/share.png"
                          width="16px"
                          alt="png"
                        />{" "}
                      </NavLink>
                      <StarredButton datas={hit} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div
            className="modal fade pro-jects1"
            id="shareModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ border: "none", marginTop: "-5px" }}
                >
                  <h5
                    className="modal-titles"
                    id="exampleModalLabel"
                    style={{ visibility: "visible" }}
                  >
                    Share
                  </h5>
                  <button
                    id="closeContentModal"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={modalClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="mt-4 inviteSection text-left">
                      <div className="inviteTitle text-start">
                        Invite someone
                      </div>
                      <InputGroup className="mb-3 inputGroupWrppr">
                        <FormControl
                          placeholder="Enter Email"
                          aria-label="Enter Email"
                          aria-describedby="basic-addon2"
                          className="inputGroupInput"
                          name="email"
                          value={userEmail}
                          onChange={(event) => {
                            handleRequestInputs(event);
                            handleChange(event);
                          }}
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="inputGroups"
                          style={{ backgroud: "#fff" }}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              className="dropdownBtn"
                              id="dropdown-basic"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#000",
                              }}
                            >
                              {getDefaultText(payload.permission)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#/action-3"
                                onClick={() =>
                                  updatePermission(
                                    "view",
                                    userEmail,
                                    workData?._id
                                  )
                                }
                              >
                                Can view
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>

                    {show ? (
                      <div
                        className="d-flex justify-content-end"
                        style={{ border: "none" }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ marginRight: "1rem" }}
                          onClick={() => {
                            setEmail("");
                          }}
                        >
                          Cancel
                        </button>
                        {expertDetails?.permittedUsers?.filter(
                          (o) => o?.email === userEmail?.trim()
                        )?.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#5746ec",
                              border: "none",
                            }}
                            disabled
                          // onClick={() => makeUpdate()}
                          >
                            Invite
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#5746ec",
                              border: "none",
                            }}
                            onClick={makeUpdate}
                          >
                            Invite
                          </button>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div>
                      <div className="sharedInfoWrapper">
                        <div className="sharedInfoChild">
                          <div className="sharedInfoProfile">
                            {getPUserList()}
                          </div>
                        </div>
                      </div>
                    </div>

                    {expertDetails?.permittedUsers?.length > 0 ? (
                      <>
                        <div className="ZP9BDnxHhGA9rSLoFsa3">
                          <button
                            id="qa-SHARE_DIALOG_STOP"
                            className="cDCnsp1vcViX1pVJM7MV"
                            type="button"
                            tabIndex="0"
                            onClick={stopSharing}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Stop sharing
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default Starred;
