export const categoryArray = [
  {
    label: "E-Commerce",
    key: "E-Commerce",
  },
  {
    label: "Travel & Hospitality",
    key: "Travel",
  },
  {
    label: "Lifestyle",
    key: "Lifestyle",
  },
  {
    label: "Healthcare & Wellness",
    key: "Healthcare",
  },
  {
    label: "Pharmaceutical",
    key: "Pharmaceutical",
  },
  {
    label: "Food & Beverage",
    key: "Food & Beverage",
  },
  {
    label: "Retail & Consumer Goods (FMCG)",
    key: "Retail",
  },
  {
    label: "Entertainment",
    key: "Entertainment",
  },
];
