export const convertToHtmlTranscript = (recordingData) => {
  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }
  function convertMS(time) {
    var hour = "";
    var min = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      sec = Math.floor((time / 1000) % 60);
    if (hours) {
      hour = `${doubleDigit(hours)}:`;
    }
    return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
  }
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "-";
    }
  };
  function replaceTxt(txt, para) {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }
  return `<div id="scriptBody" style="width: 880px">
  <h6 style="text-align:center;color:#5746ec;font-weight:600">${
    recordingData?.companyName
  } - ${replaceTxt(recordingData?.topic, [
    "nextyn",
    "micro",
    "consulting",
    "-consulting",
    "call - ",
    "call",
    "-",
  ])?.toUpperCase()} - ${convertDate(recordingData?.recording_start)}</h6>
  <div style="height:1px; background: #000"></div>
    ${recordingData?.assembly_response?.utterances?.map(
      (value) => `<div style='position:relative;margin-top:20px;'>
                      <div>
                          <h6 style='margin-bottom: 1;display:flex;align-items:center;font-weight:600;color:#000;font-size: 12px'>
                              <strong>Speaker ${value.speaker}</strong>
                              <span style='color:#8294a5;font-size:12px;font-weight:400;display:inline-block;margin-left: 14px'>
                                  ${convertMS(value?.end)}
                              </span>
                          </h6>
                          <p style='font-size:12px;color:#05294b;cursor:pointer';>${
                            value.text
                          }</p>
                      </div>
                  </div>`
    )}
    </div>`;
};
