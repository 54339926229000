import { FaRegEnvelope } from "react-icons/fa";
import {
  BsCheck2Circle,
  BsFillCartFill,
  BsFillGeoAltFill,
} from "react-icons/bs";

const Data = {
  AllPaymentDetails: [
    {
      id: 1,
      titel: "Amazon gift card",
      subtext: "Receive an amazon gift cards",
      img: "/images/payment-icon-s1.svg",
    },
    {
      id: 2,
      titel: "Add Bank account",
      subtext: "Receive a Direct Wire Transfer",
      img: "/images/payment-icon-s2.svg",
    },
    {
      id: 3,
      titel: "Add Paypal Account",
      subtext: "Recieve using a PayPal ID",
      img: "/images/payment-icon-s3.svg",
    },
    {
      id: 4,
      titel: "Charitable Donation",
      subtext: "Donate to a Charity ",
      img: "/images/payment-icon-s4.svg",
    },
  ],
};
export default Data;
