import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { toast } from "react-toastify";
import API_PATH from "../Constants/api-path";
import "./AddMemberModal.css";

const AddMemberModal = ({ ID }) => {
  // console.log(ID);
  const [emailList, setEmailList] = useState([{ email: "" }]);
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [ModalToggle, setModalToggle] = useState(false);
  const loginDetails = JSON.parse(localStorage.getItem("userData"));

  const handleInputChangeEmail = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    checkEmail(list[index].email);
    setEmailList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...emailList];
    list.splice(index, 1);
    setEmailList(list);
  };

  const handleAddClick = () => {
    setEmailList([...emailList, { email: "" }]);
  };

  const checkEmail = (email) => {
    //let emailData = [];

    //for (var Email in emailList) {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      //setChekemail(true)
      setMessage("");
    } else {
      //console.log("empty")
      //console.log("Email is invalid");
      return setMessage("Email is invalid");
    }
    // }
  };

  const handleSubmit = async () => {
    const { email } = emailList;

    // if (email === "") {
    //   return toast.warning("All fields are Mandatory", {
    //     position: toast.POSITION.RIGHT,
    //     autoClose: 3000,
    //   });
    // }
    setSpinner(true);

    let emailData = [];
    // console.log(emailData, "emailData");

    for (var Email in emailList) {
      if (emailList[Email].email !== "") emailData.push(emailList[Email].email);
    }
    console.log(emailData, "emailData");

    let payload = {
      project_id: ID,
      invite_team: emailData,
    };
    console.log(payload, "payload");
    console.log(emailData.length, "emailData");
    if (payload.invite_team.length === 0 || emailData.length === 0) {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      try {
        const res = await fetch(`${API_PATH.CLIENT_ADD_TEAM_MEMBER}`, {
          method: "POST",
          body: JSON.stringify(payload),
           headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginDetails?.token}`,
          },
        });

        const result = await res.json();
        if (result?.status === 200 || result?.status === true) {
          setSpinner(false);
          document.getElementById("exampleModal").click();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          return toast.success("Invited successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <div>
      <button
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        variant="outlined"
        className="btn add-pls"
      >
        {" "}
        <BsPlusLg /> Add{" "}
      </button>

      <div
        className="modal fade "
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "10px" }}
            >
              {/* <h4 className="modal-title text-center" id="exampleModalLabel">
                
              </h4> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-3  p-0">
              <h2 className="text-center mb-5">
                Add a team member to the project
              </h2>
              <form>
                <div className="">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group ">
                        <div id="addnewEmails">
                          <div
                            className="pos-relative "
                            style={{
                              width: "100%",
                            }}
                          >
                            {emailList.map((x, i) => {
                              return (
                                <div
                                  className="box d-flex justify-content-around align-items-center "
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  <div className="col-sm-10 my-1">
                                    <label
                                      className="sr-only"
                                      htmlFor="inlineFormInputGroupUsername"
                                    >
                                      Enter Email
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="inlineFormInputGroupUsername"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={x.email}
                                        onChange={(e) =>
                                          handleInputChangeEmail(e, i)
                                        }
                                      />
                                      <div className="input-group-prepend">
                                        <div className="input-group-text">
                                          {"@" +
                                            loginDetails.user.email.split(
                                              "@"
                                            )[1]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="btn-box d-grid">
                                    {emailList.length !== 1 && (
                                      <DangerousOutlinedIcon
                                        className="mr10"
                                        onClick={() => handleRemoveClick(i)}
                                      />
                                    )}
                                    {emailList.length - 1 === i && (
                                      <AddCircleOutlineIcon
                                        onClick={handleAddClick}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            {message === "" ? (
                              <></>
                            ) : (
                              <p
                                className="text-danger"
                                style={{ marginLeft: "3%" }}
                              >
                                {message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
            <div
              className="modal-footer d-flex"
              style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {message === "" ? (
                <button
                  variant="contained"
                  className="btn btnsm w-25"
                  onClick={handleSubmit}
                  id="closebtn1"
                  loading={spinner}
                >
                  Invite
                </button>
              ) : (
                <button
                  type="primary"
                  variant="contained"
                  className="btn btnsm w-25"
                  data-bs-dismiss="modal"
                  disabled
                  onClick={handleSubmit}
                >
                  Invite
                </button>
              )}
            </div>
          </div>
          {/* <ToastContainer /> */}
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
