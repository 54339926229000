import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Sidebar";
import { BsChevronLeft } from "../Utils/Icons";
import CollaborateCreateNotesForm from "./CollaborateCreateNotesForm";

const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const CollaborateCreateNotes = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [noteDetailsData, setNoteDetailsData] = useState("");

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start mt-3" id="header">
                {/* <TopMenu /> */}
                <h4 className="d-flex align-items-center">
                  <Link to={"/call_recordings"}>
                    <BsChevronLeft className="me-3 search-btn-back" />
                  </Link>
                  Back
                </h4>
              </div>
            </div>

            <div className="dasb-div2 bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="faq-section">
                  <CollaborateCreateNotesForm />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default CollaborateCreateNotes;
