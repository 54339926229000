import { Markup } from "interweave";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "reactstrap";
import "../App.css";
import "../assests/css/custom.css";
import API_PATH from "../Constants/api-path";
import axios from "axios";
import { message } from "antd";
import no_result from "../assests/images/Slipping-Woman.svg";
import { Drawer } from "antd";
import { NavLink, createSearchParams } from "react-router-dom";

function ScheduledTable({ project_id }) {
  //console.log(project_id)
  const [ScheduledExperts, setScheduledExperts] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [expert_popup, setExpertPopup] = useState({});
  const [modalToggle, setModalToggle] = useState(false);
  const [modalToggle1, setModalToggle1] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [expertID, setExpertID] = useState("");
  const [status, setStatus] = useState("");
  const [submitPop, setSubmitPop] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  };
  //console.log(UserData.user.email)
  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle(false);
  const handleClose2 = () => setModalToggle1(false);

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      // Check if the date is in the format "08, 2023 - 09, 2023"
      if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 - "
      if (/^\d{2}, \d{4} - $/.test(date)) {
        const [startDate] = date.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 "
      if (/^\d{2}, \d{4}$/.test(date)) {
        const [startMonthNum, startYear] = date.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "2018-04"
      if (/^\d{4}-\d{2}$/.test(date)) {
        const [year, monthNum] = date.split("-");
        const month = getMonthName(monthNum);
        return `${month}, ${year}`;
      }

      // Check if the date is in the format "10, 2014 - 01, 2020"
      if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "02, 2018   -    "
      if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
        const [startDate] = date.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // For other formats, convert the date to the desired format
      var d = new Date(date);
      var day = d.getDate();
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[d.getMonth()];
      var year = d.getFullYear();
      let formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    } else {
      return "---------";
    }
  };

  const getMonthName = (monthNum) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const index = parseInt(monthNum, 10) - 1;
    return monthNames[index];
  };

  useEffect(() => {
    getExperts();
    fetchData();
  }, []);

  const getExperts = () => {
    axios
      .get(`${API_PATH.Client_Project_Expert_List}/${project_id}`, { headers })
      .then((res) => {
        console.log(res?.data?.data);
        setLoading(true);
        setScheduledExperts(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("ScheduledExperts", ScheduledExperts);

  const fetchData = (_id) => {
    axios
      .get(
        `${API_PATH.PROJECT_EXPERT_GET}/${_id}`,
        // { email: UserData.user.email },
        { headers }
      )
      .then((res) => {
        setLoading(true);
        console.log(res, "resExpertIdData");
        setSubmitPop(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExpertDetails = (_id) => {
    console.log(_id, "expertId");
    setExpertID(_id, "expertId");
    fetchData(_id);
    // console.log("obj found update func res", res);
    // setSubmitPop(res);
  };

  console.log("submitPopppp", submitPop);

  const expertPopupStatus = (statuses) => {
    console.log(statuses, "Edit");
    return statuses.map((status, index) => {
      if (status === "Edit") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#2a55e", cursor: "pointer", fontSize: "14px" }}
            >
              <NavLink
                to={{
                  pathname: "/dashboard/addExpert",
                  search: `?${createSearchParams({ project_id, expertID })}`,
                }}
                key={index}
              >
                Edit
              </NavLink>
            </span>
          </div>
        );
      } else if (status === "Request") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#2ca8ff", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Request
            </span>
          </div>
        );
      } else if (status === "Schedule") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#f96332", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Schedule
            </span>
          </div>
        );
      } else if (status === "Complete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#39c4a3", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Complete
            </span>
          </div>
        );
      } else if (status === "Decline") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "maroon", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Decline
            </span>
          </div>
        );
      } else if (status === "Delete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "red", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Delete
            </span>
          </div>
        );
      }
    });
  };

  let serialNumber = 0;

  const statusData = expertPopupStatus(["Request", "Complete", "Decline"]);

  const expertStatusChanger = (status) => {
    setStatus(status);
    setModalToggle(true);
    //console.log(status);
  };

  const first_charc_uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const statusSetter = (status) => {
    if (status === "Edit") {
      return (
        <p>
          Are you sure you want to schedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.{" "}
        </p>
      );
    } else if (status === "Request") {
      return <p>Are you sure, you want to mark this call as Requested?</p>;
    } else if (status === "Schedule") {
      return <p>Are you sure, you want to mark this call as Scheduled?</p>;
    } else if (status === "Complete") {
      return <p>Are you sure, you want to mark this call as completed?</p>;
    } else if (status === "Decline") {
      return <p>Are you sure, you want to mark this call as Declined?</p>;
    } else if (status === "Delete") {
      return <p>Are you sure, you want to delete this expert?</p>;
    }
  };

  const statusButton = (status) => {
    if (status === "Edit" || status === "Edit") {
      return "Edit";
    } else if (status === "Request") {
      return "Request";
    } else if (status === "Schedule") {
      return "Schedule";
    } else if (status === "Complete") {
      return "Complete";
    } else if (status === "Decline") {
      return "Decline";
    } else if (status === "Delete") {
      return "Delete";
    }
  };

  const handleSubmit = async (status) => {
    setSpinner(true);
    console.log(status, "statuss");
    document.getElementById("closebutton1").disabled = true;

    let valueToAdd;
    if (status === "Request") {
      valueToAdd = 3;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            getExperts();
            toggleDrawer();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            message.success(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Schedule") {
      valueToAdd = 4;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            getExperts();
            toggleDrawer();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            message.success(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Complete") {
      valueToAdd = 5;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            getExperts();
            toggleDrawer();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            message.success(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Decline") {
      valueToAdd = 6;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            getExperts();
            toggleDrawer();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            message.success(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Delete") {
      axios
        .post(
          `${API_PATH.PROJECT_EXPERT_DELETE}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            getExperts();
            toggleDrawer();
            message.success(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // if (
    //   status === "Schedule" ||
    //   status === "Reschedule" ||
    //   status === "Scheduled" ||
    //   status === "Rescheduled"
    // ) {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_SCHEDULE}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       //console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 3000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else if (status === "Decline") {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_DECLINE}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 3000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else if (status === "Cancel") {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_SCHEDULECANCEL}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 3000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  const getFormattedDate = (date) => {
    if (!date) {
      return null;
    }

    const dates = date.split(" - ");
    const startDate = convertDate(dates[0].trim(), "month");
    const endDate = dates[1] ? convertDate(dates[1].trim(), "month") : "";

    return `${startDate} - ${endDate}`;
  };

  const getExperienceList = (data) => {
    console.log(data, "datas");
    if (!data?.expert_experience || data.expert_experience.length === 0) {
      return [];
    }

    const experienceList = data.expert_experience.map((experience) => {
      const obj = {};
      obj.previous_designation = experience.previous_designation || "";
      obj.previous_compny = experience.previous_compny || "";
      obj.previous_date = getFormattedDate(
        experience.previous_designation_date
      );

      // Filter out entries with invalid or empty previous_date
      if (!obj.previous_date || obj.previous_date === "NaN undefined, NaN") {
        return null;
      }

      return obj;
    });

    // Filter out null entries to remove the invalid data
    const filteredExperienceList = experienceList.filter(
      (item) => item !== null
    );

    return filteredExperienceList;
  };

  return (
    <>
      <>
        {console.log(ScheduledExperts, "ScheduledExperts.length")}
        {ScheduledExperts.filter(
          (elem) => elem?.admin_invite_experts[0]?.status === 4
        ).length > 0 ? (
          <>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    {/* <th title="Name" style={{ width: "55px" }}>
                      #
                    </th> */}
                    {/* <th title="Name" style={{ width: "120px" }}>
                      Status
                    </th> */}
                    <th title="Gender" style={{ width: "35%" }}>Most Relevant Title</th>
                    <th title="Age" style={{ width: "20%" }}>
                      Company
                    </th>
                    <th title="Age" style={{ width: "55px" }}>
                      RATE/HR{" "}
                    </th>
                    <th title="Age" style={{ width: "130px" }}>
                      DATE
                    </th>
                    <th style={{ width: "80px" }}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {ScheduledExperts.map((curElem, index) =>
                    curElem?.admin_invite_experts[0]?.status === 4 ? (
                      <tr key={curElem._id}>
                        {/* <td>
                          <span className="scheduled-text"> Scheduled </span>
                        </td> */}
                        <td
                          style={{
                            color: "rgb(82, 111, 215)",
                            fontWeight: "500",
                          }}
                        >
                          {curElem.admin_expert_experience[0]
                            ?.current_designation
                            ? curElem.admin_expert_experience[0]
                              ?.current_designation
                            : curElem.admin_expert_experience[0]
                              ?.previous_designation}
                        </td>
                        <td>
                          {curElem.admin_expert_experience[0]?.current_compny
                            ? curElem.admin_expert_experience[0]?.current_compny
                            : curElem.admin_expert_experience[0]
                              ?.previous_compny}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {curElem?.expert_client_Currency}{" "}
                          {curElem.hourly_consulting_rate}/hr
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>{convertDate(curElem?.created_at)}</td>
                        <td>
                          <a
                            type="button"
                            style={{ fontWeight: "600", color: "#5746EC" }}
                            key={index}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#Scheduled"
                            aria-controls="offcanvasRight"
                            onClick={() => {
                              toggleDrawer();
                              getExpertDetails(curElem?._id);
                            }}
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="text-center noResultFound">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-6">
                    <img src={no_result} style={{ height: "350px" }}></img>
                  </div>
                  <div className="col-md-6">
                    <h3
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      It could be that your requested experts are yet to share
                      their availability with us. We're following up, without
                      being too pushy.{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <Drawer
          title={
            <>
              <div style={{ float: "left" }}>Expert Profile</div>
              <div style={{ float: "right", fontWeight: 400 }}>
                {submitPop?.expert_id}
              </div>
            </>
          }
          placement="right"
          closable={true}
          onClose={toggleDrawer}
          visible={isDrawerVisible}
          width={550}
        >
         
          <div>
            {console.log(submitPop)}
            {submitPop && (
              <>
                <div>
                  <h2
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#333",
                      marginBottom: "15px",
                    }}
                  >
                    {submitPop?.expert_experience[0]?.current_designation}
                  </h2>
                  <div
                    className="nameAndCompany_M"
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      color: "#333",
                      marginTop: "-8px",
                    }}
                  >
                    <strong
                      id="nameAndCompany_M"
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "#333",
                      }}
                    >
                      {submitPop?.expert_experience[0]?.current_compny}
                    </strong>
                    <span> {submitPop?.current_location}</span>
                  </div>
                </div>
                <div className="d-flex mt-2 gap-2" data-bs-dismiss="offcanvas">
                  {statusData}
                </div>
                <div className="mt-2">
                  <div className="expertPanel_3Zo3_">
                    <div className="section_3ByhA">
                      <div className="detailsRows_2038l">
                        <span className="detailsRowLeft_3h_zH">Region</span>
                        <span className="detailsRowRight_3h_zH">
                          {submitPop?.current_location}
                        </span>
                      </div>
                      <div className="detailsRows_2038l">
                        <span className="detailsRowLeft_3h_zH">Linkedin</span>
                        <span className="detailsRowRight_3h_zH">
                          <a href={submitPop?.link} target="_blank">
                            View Profile
                          </a>
                        </span>
                      </div>
                      <div className="detailsRows_2038l">
                        <span className="detailsRowLeft_3h_zH">
                          Consulting Rate (Expert)
                        </span>
                        <span className="detailsRowRight_3h_zH">
                          {submitPop?.expertCurrency}{" "}
                          {submitPop?.consulting_rate}/hr
                        </span>
                      </div>
                      <div className="detailsRows_2038l">
                        <span className="detailsRowLeft_3h_zH">
                          Consulting Rate (Client)
                        </span>
                        <span className="detailsRowRight_3h_zH">
                          {submitPop?.expert_client_Currency}{" "}
                          {submitPop?.hourly_consulting_rate}/hr
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overview mt-2">
                  <h2
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Overview
                  </h2>
                  <Markup
                    className="markup-para"
                    content={submitPop?.overview}
                  />
                </div>
                <div className="experien-list mt-4">
                  <h6
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Experience
                  </h6>
                  <div>
                    <ul className="nextyn_list_items">
                      <li className="nextyn_list_item">
                        <div className="expertCompany_2UyI2">
                          <span className="title_1p_LH">
                            {
                              submitPop?.expert_experience[0]
                                ?.current_designation
                            }
                          </span>
                          <div className="companyName_1dSwA">
                            {" "}
                            {submitPop?.expert_experience[0]?.current_compny}
                          </div>
                        </div>
                        <span className="careerDates_3sJ2b">
                          {" "}
                          {convertDate(
                            submitPop?.expert_experience[0]
                              ?.current_designation_date,
                            "month"
                          )}
                          - Present
                        </span>
                      </li>
                      {getExperienceList(submitPop).map((curElem, i) => {
                        return (
                          <li className="nextyn_list_item" key={i}>
                            <div className="expertCompany_2UyI2">
                              <span className="title_1p_LH">
                                {curElem?.previous_designation}
                              </span>
                              <div className="companyName_1dSwA">
                                {curElem?.previous_compny}
                              </div>
                            </div>
                            <span className="careerDates_3sJ2b">
                              {curElem?.previous_date}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </Drawer> */}
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="Scheduled"
          aria-labelledby="offcanvasRightLabel"
        >
          <>
            <div
              className="offcanvas-header align-items-center justify-content-between"
              style={{
                borderBottom: "1px solid rgb(197, 195, 195)",
              }}
            >
              <h5 id="offcanvasRightLabel mb-0" style={{ marginBottom: "0px" }}>Expert Profile</h5>
              <button
                type="button"
                className="btn-close "
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div>
                {console.log(submitPop)}
                {submitPop && (
                  <>
                    <div>
                      <h2
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#333",
                          marginBottom: "15px",
                        }}
                      >
                        {submitPop?.expert_experience[0]?.current_designation}
                      </h2>
                      <div
                        className="nameAndCompany_M"
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#333",
                          marginTop: "-8px",
                        }}
                      >
                        <strong
                          id="nameAndCompany_M"
                          style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            color: "#333",
                          }}
                        >
                          {submitPop?.expert_experience[0]?.current_compny}
                        </strong>
                        <span> {submitPop?.current_location}</span>
                      </div>
                    </div>
                    <div
                      className="d-flex mt-2 gap-2"
                      data-bs-dismiss="offcanvas"
                    >
                      {statusData}
                    </div>
                    <div className="mt-2">
                      <div className="expertPanel_3Zo3_">
                        <div className="section_3ByhA">
                          <div className="detailsRows_2038l">
                            <span className="detailsRowLeft_3h_zH">Region</span>
                            <span className="detailsRowRight_3h_zH">
                              {submitPop?.current_location}
                            </span>
                          </div>

                          <div className="detailsRows_2038l">
                            <span className="detailsRowLeft_3h_zH">
                              Consulting Rate
                            </span>
                            <span className="detailsRowRight_3h_zH">
                              {submitPop?.expert_client_Currency}{" "}
                              {submitPop?.hourly_consulting_rate}/hr
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="overview mt-2">
                      <h2
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Overview
                      </h2>
                      <Markup
                        className="markup-para"
                        content={submitPop?.overview}
                      />
                    </div>
                    <div className="experien-list mt-4">
                      <h6
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Experience
                      </h6>
                      <div>
                        <ul className="nextyn_list_items">
                          <li className="nextyn_list_item">
                            <div className="expertCompany_2UyI2">
                              <span className="title_1p_LH">
                                {
                                  submitPop?.expert_experience[0]
                                    ?.current_designation
                                }
                              </span>
                              <div className="companyName_1dSwA">
                                {" "}
                                {
                                  submitPop?.expert_experience[0]
                                    ?.current_compny
                                }
                              </div>
                            </div>
                            <span className="careerDates_3sJ2b">
                              {" "}
                              {convertDate(
                                submitPop?.expert_experience[0]
                                  ?.current_designation_date,
                                "month"
                              )}
                              - Present
                            </span>
                          </li>
                          {getExperienceList(submitPop).map((curElem, i) => {
                            return (
                              <li className="nextyn_list_item" key={i}>
                                <div className="expertCompany_2UyI2">
                                  <span className="title_1p_LH">
                                    {curElem?.previous_designation}
                                  </span>
                                  <div className="companyName_1dSwA">
                                    {curElem?.previous_compny}
                                  </div>
                                </div>
                                <span className="careerDates_3sJ2b">
                                  {curElem?.previous_date}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="offcanvas-header">
              <h6 style={{ color: "#39c4a3" }} id="offcanvasRightLabel">
                Submitted
              </h6>
            </div>
          </>
        </div>
      </>

      <Modal
        show={modalToggle}
        onHide={handleClose1}
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ marginBottom: "10px", border: "none", marginTop: "10px" }}
          closeButton

        >
          <Modal.Title style={{ textAlign: "center" }}>
            <h4 className="modalHeader">{first_charc_uppercase(status)} a call with the expert</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "left", fontSize: "24px" }}>
            {statusSetter(status)}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-end"
          style={{ border: "none" }}
        >
          {status === "Cancel" ? (
            <>
              <Button
                variant="primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
                data-bs-dismiss="modal"
                onClick={() => {
                  handleSubmit(status);
                }}
              >
                {spinner ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    {statusButton(status)}
                  </>
                ) : (
                  statusButton(status)
                )}
              </Button>
            </>
          ) : (
            <>
              <Button className="btn btn-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                id="closebutton1"
                variant="primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
                data-bs-dismiss="modal"
                onClick={() => {
                  handleSubmit(status);
                }}
              >
                {spinner ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    {statusButton(status)}
                  </>
                ) : (
                  statusButton(status)
                )}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ScheduledTable;
