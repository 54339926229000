import { FaRegEnvelope } from "react-icons/fa";
import {
  BsCheck2Circle,
  BsFillCartFill,
  BsFillGeoAltFill,
} from "react-icons/bs";

const Data = {
  ExpertSerachtable: [
    {
      id: 1,
      // icon:<FaRegEnvelope/>,
      titel:
        "Business analyst for developing my idea and make it live to users",
      date: "mar 13, 2022",
      time: " 30mins",
      call: " +91234567890",
      location: "New York, USA",
      img: "/images/expert-user-img.svg",
      company: "Yahoo Company",
      price: "$560",
      status: " $ Processed",
      payment: "Apr 22, 2022",
    },

    {
      id: 2,
      // icon:<FaRegEnvelope/>,
      titel:
        "Business analyst for developing my idea and make it live to users",
      date: "mar 13, 2022",
      time: " 30mins",
      call: " +91234567890",
      location: "New York, USA",
      img: "../../assests/images/expert-user-img.svg",
      company: "Yahoo Company",
      price: "$560",
      status: " $ Processed",
      payment: "Apr 22, 2022",
    },
  ],
};
export default Data;
