import React from "react";
import "./ExpiredPage.css";

const ExpiredPage = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center main-message">
            <div>
              <div className="FullPageMessage-Message">
                <div className="mb2">
                  <h2 className="Text Text-color--gray400 Text-fontSize--16 Text-fontWeight--500">
                    Expired link
                  </h2>
                </div>
                <div className="mb3">
                  <p className="mb2 FullPageMessage-Message-Detail Text Text-color--gray400 Text-fontSize--13 Text-fontWeight--400">
                    Please close this window and generate fresh link to reset
                    your password
                  </p>
                </div>
                <p className="Text Text-color--gray400 Text-fontSize--13 Text-fontWeight--400">
                  <a className="Link Link--primary" href="/" target="_self">
                    Back
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExpiredPage;
