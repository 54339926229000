import axios from "axios";
import $ from "jquery";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Pagination1 from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import "../App.css";
import API_PATH from "../Constants/api-path";
import TopMenu1 from "../TopMenu1";
import "./Dashboard.css";
import TopIndustry from "./TopIndustry";
import TopSelectOption from "./TopSelectOptions";

function FindExpert() {
  let queryParams = queryString.parse(window.location.search);
  const [loading, setLoading] = useState(false);
  const [experts, setExperts] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [currentPage, setCurrentPage] = useState(
    queryParams?.ctPage !== undefined ? parseInt(queryParams.ctPage) : 1
  );
  const [allDatas, setAlldatas] = useState(0);
  const [company, setCompany] = useState(
    queryParams?.company !== undefined ? queryParams?.company : ""
  );
  const [previousCompany, setpCompany] = useState(
    queryParams?.previousCompany !== undefined
      ? queryParams?.previousCompany
      : ""
  );
  const [industry, setIndustry] = useState(
    queryParams?.industry !== undefined ? queryParams?.industry : ""
  );
  const [tags, setExpertise] = useState(
    queryParams?.expertise !== undefined ? queryParams?.expertise : ""
  );
  const history = useNavigate();
  const [country, setCountry] = useState(
    queryParams?.country !== undefined ? queryParams?.country : ""
  );
  const [searchRgx, setSearchRgx] = useState(
    queryParams?.search !== undefined ? queryParams?.search : ""
  );

  const headers = {
    "x-access-token": userData.access_token,
  };

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handlePageChange = (pageNumber) => {
    Replacepagechange(
      pageNumber,
      country,
      company,
      previousCompany,
      tags,
      industry,
      searchRgx
    );
    setCurrentPage(pageNumber);
  };

  const Replacepagechange = (
    ctPage,
    country,
    company,
    previousCompany,
    industry,
    tags,
    searchRgx
  ) => {
    history({
      pathname: `/find-expert`,
      search: `?country=${country}&company=${company}&previousCompany=${previousCompany}&industry=${industry}&expertise=${tags}&search=${searchRgx}&ctPage=${btoa(
        ctPage
      )}`,
    });
  };

  const changeStatus = () => {
    return setLoading(true);
  };

  const changeQueryStatus = () => {
    return setLoading(false);
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const getExperts = (ctPage) => {
    axios
      .get(`${API_PATH.EXPERT_LIST}/${ctPage}/${userData.user._id}`, {
        headers,
        params: {
          country,
          company,
          pcompany: previousCompany,
          tags,
          industry,
          searchRgx,
          category: userData.user
            ? userData.user.selectedCategory?.join(",")
            : "",
        },
      })
      .then((res) => {
        setLoading(true);
        setExperts([...res.data.data]);
        setAlldatas(res.data.count);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let queryParams = queryString.parse(window.location.search);
    if (queryParams.ctPage) {
      // console.log("queryParams", queryParams);
      queryParams.ctPage = atob(queryParams.ctPage);
      if (!isNaN(parseInt(queryParams.ctPage))) {
        // setCompany(queryParams.company);
        // setpCompany(queryParams.previousCompany);
        // setIndustry(queryParams.industry);
        // setExpertise(queryParams.expertise);
        //setCountry(queryParams.country);
        // setSearchRgx(queryParams.search);
        setCurrentPage(parseInt(queryParams.ctPage));
        getExperts(queryParams.ctPage);

        Replacepagechange(
          queryParams.ctPage,
          queryParams.country,
          queryParams.company,
          queryParams.previousCompany,
          queryParams.industry,
          queryParams.expertise,
          queryParams.search
        );
      }
    } else {
      setCurrentPage(1);
      getExperts(1);
      Replacepagechange(
        1,
        country,
        company,
        previousCompany,
        tags,
        industry,
        searchRgx
      );
    }

    setLoading(false);
    setTimeout(() => {
      setLoading(true);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    country,
    company,
    previousCompany,
    tags,
    industry,
    searchRgx,
  ]);

  let isAuth = localStorage.getItem("isAuth");
  document.title = "Expert Cloud -  Find Experts";
  if (!isAuth) {
    return (window.location.href = "/");
  }
  return (
    <>
      {/* <LoadingScreen
        loading={isLoding}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        text="Loading..."
      /> */}

      <div className="container-fluid">
        <div className="d-block mobile-fill-width">
          <div className="top-bar-menu float-start" id="header">
            <TopMenu1 setSearchRgx={setSearchRgx} searchRgx={searchRgx} />
          </div>
        </div>

        <div className="dasb-div2 bk-div float-start w-100">
          <TopSelectOption
            setCompany={setCompany}
            dcompany={company}
            setpCompany={setpCompany}
            dpcompany={previousCompany}
            setIndustry={setIndustry}
            dindustry={industry}
            setExpertise={setExpertise}
            dexpertise={tags}
            setCountry={setCountry}
            dcountry={country}
          />

          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <h2 className="mt-2"> Explore Our Customised Panel of Experts</h2>
            {/* <h6
              className="browseTopRated mb-4"
              style={{ color: "#5746ec", fontSize: "20px" }}
            >
              Customised based on your choice of industry
            </h6> */}

            <div className="tab-content maxWidth" id="pills-tabContent">
              <div
                className="tab-pane pt-2 fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {loading ? (
                  <>
                    <TopIndustry props={experts} />
                    {experts.length >= 1 ? (
                      <>
                        <div className="pagination-background">
                          <Pagination1
                            activePage={currentPage}
                            itemsCountPerPage={10}
                            totalItemsCount={allDatas}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                            showLast
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "20px" }}
                    >
                      <div role="status">
                        <Spinner animation="border" />
                      </div>
                    </div>
                  </>
                )}

                {/* <TopIndustry /> */}
                {/* <TopIndustry props={experts} /> */}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade projects-modal"
        id="expertmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="projects-modal-divs">
                <div className="d-flex position-relative align-items-center">
                  <h2 className="text-center mx-auto">
                    {" "}
                    How many experts would you like to consult?
                  </h2>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-8 p-0 mx-auto d-flex justify-content-center">
                    <a id="#" className="one-ex-btn btn">
                      {" "}
                      One Expert{" "}
                    </a>
                    <a id="#" className="more-ex-btn btn">
                      {" "}
                      More than one{" "}
                    </a>
                  </div>
                  <hr className="col-lg-8 mt-4 mx-auto " />

                  <div className="notes col-lg-8 mx-auto p-0">
                    <span> NOTE: </span>{" "}
                    <span>
                      {" "}
                      If your project requires multiple experts, please click on
                      "More than one" and our consultants will reach out to you
                      to assist with a custom recruitment.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindExpert;
