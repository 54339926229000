import React, { useEffect, useState } from "react";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import UserProfileForm from "./UserProfileForm";
import { BsChevronLeft, Calendar, Timer } from "../Utils/Icons";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const [Loading, setLoading] = useState(true);
  const UserData = JSON.parse(localStorage.getItem("userData"));
  const [user, setUser] = useState({});
  const headers = {
    "x-access-token": UserData.access_token,
  };
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
  }, []);

  document.title = "Client | Profile";

  var userdata = [UserData]

  const handleIconClick = () => {
    navigate(-1);
  };

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="inside-support">
                  <h2
                    className="d-flex align-items-center"
                    style={{ fontSize: "24px", cursor: "pointer" }}
                    onClick={handleIconClick}
                  >
                    <BsChevronLeft className="search-btn-back me-3" />
                    My Profile
                  </h2>
                 
                </div>
                <div className="faq-section mt-4">
                  {userdata.length !== 0 ? (
                    <UserProfileForm user={userdata} />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default UserProfile;
