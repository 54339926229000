import React, { useState, useEffect } from "react";
// import MobileMenu from "../MobileMenu";
import { Helmet } from "react-helmet";
import Sidebar from "../Sidebar";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import API_PATH from "../Constants/api-path";
import { useLocation } from "react-router-dom";
import { Markup } from "interweave";
import { Modal } from "react-bootstrap";
import { Button, notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Spinner } from "reactstrap";

function ExpertDetails() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [submitPop, setSubmitPop] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let expertId = query.get("expertId");

  let project_id = query.get("project_id");

  console.log(expertId, "expertId");

  const fetchData = (expertId) => {
    axios
      .get(
        `${API_PATH.PROJECT_EXPERT_GET}/${expertId}`,
        // { email: UserData.user.email },
        { headers }
      )
      .then((res) => {
        setLoading(true);
        console.log(res, "resExpertIdData");
        setSubmitPop(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFormattedDate = (date) => {
    if (!date || typeof date !== "string") {
      return null;
    }

    const dates = date?.split(" - ");
    if (dates?.length < 2) {
      return null;
    }

    const startDate = convertDate(dates[0].trim(), "month");
    const endDate = dates[1] ? convertDate(dates[1].trim(), "month") : "";

    return `${startDate} - ${endDate}`;
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      // Check if the date is in the format "08, 2023 - 09, 2023"
      if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 - "
      if (/^\d{2}, \d{4} - $/.test(date)) {
        const [startDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 "
      if (/^\d{2}, \d{4}$/.test(date)) {
        const [startMonthNum, startYear] = date.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "2018-04"
      if (/^\d{4}-\d{2}$/.test(date)) {
        const [year, monthNum] = date?.split("-");
        const month = getMonthName(monthNum);
        return `${month}, ${year}`;
      }

      // Check if the date is in the format "10, 2014 - 01, 2020"
      if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "02, 2018   -    "
      if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
        const [startDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // For other formats, convert the date to the desired format
      var d = new Date(date);
      var day = d.getDate();
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[d.getMonth()];
      var year = d.getFullYear();
      let formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    } else {
      return "---------";
    }
  };

  const getMonthName = (monthNum) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const index = parseInt(monthNum, 10) - 1;
    return monthNames[index];
  };

  const getExperienceList = (data) => {
    console.log(data, "datas");
    if (!data?.expert_experience || data.expert_experience.length === 0) {
      return [];
    }

    const experienceList = data.expert_experience.map((experience) => {
      const obj = {};
      obj.previous_designation = experience.previous_designation || "";
      obj.previous_compny = experience.previous_compny || "";
      obj.previous_date = getFormattedDate(
        experience?.previous_designation_date
      );

      // Filter out entries with invalid or empty previous_date
      if (!obj.previous_date || obj.previous_date === "NaN undefined, NaN") {
        return null;
      }

      return obj;
    });

    // Filter out null entries to remove the invalid data
    const filteredExperienceList = experienceList.filter(
      (item) => item !== null
    );

    return filteredExperienceList;
  };

  const expertPopupStatus = (statuses) => {
    console.log(statuses, "Edit");
    return statuses.map((status, index) => {
      if (status === "Edit") {
        return (
          <div className="statusBtnOffCanvas">
            {/* <span
              style={{ color: "#2a55e", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Edit
            </span> */}
          </div>
        );
      } else if (status === "Request") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#2ca8ff", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Request
            </span>
          </div>
        );
      } else if (status === "Schedule") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#f96332", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Schedule
            </span>
          </div>
        );
      } else if (status === "Complete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#39c4a3", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Complete
            </span>
          </div>
        );
      } else if (status === "Decline") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "maroon", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Decline
            </span>
          </div>
        );
      } else if (status === "Delete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "red", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Delete
            </span>
          </div>
        );
      }
    });
  };

  const expertStatusChanger = (status) => {
    setStatus(status);
    setModalToggle(true);
    //console.log(status);
  };

  const statusData = expertPopupStatus([
    "Edit",
    "Request",
    "Schedule",
    "Decline",
    "Delete",
  ]);

  const first_charc_uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle(false);

  const statusSetter = (status) => {
    if (status === "Edit") {
      return (
        <p>
          Are you sure you want to schedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.{" "}
        </p>
      );
    } else if (status === "Request") {
      return <p>Are you sure, you want to mark this call as Requested?</p>;
    } else if (status === "Schedule") {
      return <p>Are you sure, you want to mark this call as Scheduled?</p>;
    } else if (status === "Complete") {
      return <p>Are you sure, you want to mark this call as completed?</p>;
    } else if (status === "Decline") {
      return <p>Are you sure, you want to mark this call as Declined?</p>;
    } else if (status === "Delete") {
      return <p>Are you sure, you want to delete this expert?</p>;
    }
  };

  const statusButton = (status) => {
    if (status === "Edit" || status === "Edit") {
      return "Edit";
    } else if (status === "Request") {
      return "Request";
    } else if (status === "Schedule") {
      return "Schedule";
    } else if (status === "Complete") {
      return "Complete";
    } else if (status === "Decline") {
      return "Decline";
    } else if (status === "Delete") {
      return "Delete";
    }
  };

  const handleSubmit = async (status) => {
    setSpinner(true);
    console.log(status, "statuss");
    document.getElementById("closebutton1").disabled = true;

    let valueToAdd;
    if (status === "Request") {
      valueToAdd = 3;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertId,
            project_id: project_id,
            user_id: expertId,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            // message.success(res.data.message);
            notification.success({
              message: "Status has been changed",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Schedule") {
      valueToAdd = 4;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertId,
            project_id: project_id,
            user_id: expertId,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: res.data.message,
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Complete") {
      valueToAdd = 5;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertId,
            project_id: project_id,
            user_id: expertId,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: res.data.message,
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Decline") {
      valueToAdd = 6;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertId,
            project_id: project_id,
            user_id: expertId,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: res.data.message,
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Delete") {
      axios
        .post(
          `${API_PATH.PROJECT_EXPERT_DELETE}`,
          {
            experts_detail_id: expertId,
            project_id: project_id,
          },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === true) {
            //console.log(res);
            setSpinner(false);
            setModalToggle(false);
            notification.success({
              message: res.data.message,
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // if (
    //   status === "Schedule" ||
    //   status === "Reschedule" ||
    //   status === "Scheduled" ||
    //   status === "Rescheduled"
    // ) {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_SCHEDULE}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       //console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else if (status === "Decline") {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_DECLINE}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else if (status === "Cancel") {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_SCHEDULECANCEL}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  useEffect(() => {
    fetchData(expertId);
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      <Helmet>
        <title>{"Client | Projects"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>

      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div
                className="body-expart-div projects-pages"
                style={{ paddingTop: "0px" }}
              >
                <div className="d-md-flex project-sec-1 top-ps-seection align-items-center m-queries ">
                  <a class="" href="/projects" style={{ textAlign: "left" }}>
                    <div class="me-3 search-btn-back">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <h2 className="mb-0">Expert Profile</h2>
                </div>
                <div className="mt-4 p-3" style={{ background: "#fff" }}>
                  <Row>
                    <Col>
                      <div>
                        <h5 style={{ fontSize: "16px" }}>
                          {submitPop?.expert_experience[0]?.current_designation}
                          {""}
                          <small
                            className="ms-2"
                            style={{
                              fontWeight: 400,
                              fontSize: "12px",
                              color: "rgb(57, 196, 163)",
                            }}
                          >
                            {/* (Submitted) */}
                          </small>
                        </h5>
                        <p>
                          {submitPop?.expert_experience[0]?.current_compny}
                          <span className="text-muted ms-2">
                            {submitPop?.current_location}
                          </span>
                        </p>
                        <div
                          class="d-flex mt-2 gap-2"
                          data-bs-dismiss="offcanvas"
                        >
                          {/* {statusData} */}
                        </div>
                        <div class="mt-2">
                          <div class="expertPanel_3Zo3_">
                            <div class="section_3ByhA">
                              <div class="detailsRows_2038l">
                                <span class="detailsRowLeft_3h_zH">Region</span>
                                <span class="detailsRowRight_3h_zH">
                                  {" "}
                                  {submitPop?.current_location}
                                </span>
                              </div>
                              <div class="detailsRows_2038l">
                                <span class="detailsRowLeft_3h_zH">
                                  Consulting Rate
                                </span>
                                <span class="detailsRowRight_3h_zH">
                                  {submitPop?.expertCurrency}{" "}
                                  {submitPop?.hourly_consulting_rate}/hr
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="overview mt-2">
                          <h2 style={{ fontWeight: 500, fontSize: "15px" }}>
                            Overview
                          </h2>
                          <Markup
                            className="markup-para"
                            content={submitPop?.overview}
                          />
                        </div>
                        <div class="experien-list mt-4">
                          <h6 style={{ fontWeight: 500, fontSize: "15px" }}>
                            Experience
                          </h6>
                          <div>
                            <ul className="nextyn_list_items">
                              <li className="nextyn_list_item">
                                <div className="expertCompany_2UyI2">
                                  <span className="title_1p_LH">
                                    {
                                      submitPop?.expert_experience[0]
                                        ?.current_designation
                                    }
                                  </span>
                                  <div className="companyName_1dSwA">
                                    {" "}
                                    {
                                      submitPop?.expert_experience[0]
                                        ?.current_compny
                                    }
                                  </div>
                                </div>
                                <span className="careerDates_3sJ2b">
                                  {" "}
                                  {convertDate(
                                    submitPop?.expert_experience[0]
                                      ?.current_designation_date,
                                    "month"
                                  )}
                                  - Present
                                </span>
                              </li>
                              {getExperienceList(submitPop).map(
                                (curElem, i) => {
                                  return (
                                    <li className="nextyn_list_item" key={i}>
                                      <div className="expertCompany_2UyI2">
                                        <span className="title_1p_LH">
                                          {curElem?.previous_designation}
                                        </span>
                                        <div className="companyName_1dSwA">
                                          {curElem?.previous_compny}
                                        </div>
                                      </div>
                                      <span className="careerDates_3sJ2b">
                                        {curElem?.previous_date}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <Modal
        show={modalToggle}
        onHide={handleClose1}
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: "none", marginTop: "-8px" }} closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            <h4 className="modalHeader">
              {first_charc_uppercase(status)} a call with the expert
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "left", fontSize: "18px" }}>
            {statusSetter(status)}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-end"
          style={{ border: "none" }}
        >
          {status === "Cancel" ? (
            <>
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#5746ec",
                  border: "none",
                  color: "#ffff",
                }}
                data-bs-dismiss="modal"
                onClick={() => {
                  handleSubmit(status);
                }}
              >
                {spinner ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    {statusButton(status)}
                  </>
                ) : (
                  statusButton(status)
                )}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                id="closebutton1"
                type="primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleSubmit(status);
                }}
              >
                {spinner ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    {statusButton(status)}
                  </>
                ) : (
                  statusButton(status)
                )}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExpertDetails;
