export const categoryArrayThree = [
  {
    label: "Real Estate",
    key: "Real Estate",
  },
  {
    label: "Energy",
    key: "Energy",
  },
  {
    label: "Climate & Sustainability",
    key: "Climate & Sustainability",
  },
  {
    label: "Pet Care",
    key: "Pets",
  },
  {
    label: "Media",
    key: "Media",
  },
  {
    label: "Sports & Gaming",
    key: "Gaming",
  },
  {
    label: "Agriculture & Plants",
    key: "Agriculture & Plants",
  },
  {
    label: "Insurance",
    key: "Insurance",
  },
];
