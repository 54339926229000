import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams, NavLink } from "react-router-dom";
import "./CollaborateSearchDetails.css";

const { TabPane } = Tabs;
const onChange = (key) => {
  // console.log(key);
};

const CollaborateSearchDetails = () => {
  const history = useNavigate();

  let searchResult = null;
  let detaildObj = {
    video: [],
    audio: [],
    image: [],
    pdf: [],
    excel: [],
    doc: [],
    text: [],
    others: [],
  };
  if (localStorage.getItem("searchResult")) {
    searchResult = JSON.parse(localStorage.getItem("searchResult"));
  }

  const prepareArray = () => {
    if (Array.isArray(searchResult)) {
      searchResult.forEach((element) => {
        let type = checkFileType(element._source.publicUrl);
        if (type === "video") {
          detaildObj.video.push(element._source);
        }
        if (type === "audio") {
          detaildObj.audio.push(element._source);
        }
        if (type === "image") {
          detaildObj.image.push(element._source);
        }
        if (type === "pdf") {
          detaildObj.pdf.push(element._source);
        }
        if (type === "excel") {
          detaildObj.doc.push(element._source);
        }
        if (type === "doc") {
          detaildObj.doc.push(element._source);
        }
        if (type === "text") {
          detaildObj.doc.push(element._source);
        }
        if (type === "system") {
          detaildObj.others.push(element._source);
        }
      });
    }
  };

  const getExtentionFromFileNmae = (fileName) => {
    return (
      fileName
        .substring(fileName.lastIndexOf(".") + 1, fileName.length)
        .toLowerCase() || fileName.toLowerCase()
    );
  };

  const checkFileType = (fileName) => {
    const videoType = ["mp4", "mov", "mkv", "webm", "avi"];
    const audioType = ["mp3", "aav"];
    const imageType = [
      "jpeg",
      "png",
      "gif",
      "tiff",
      "psd",
      "eps",
      "ai",
      "indd",
      "raw",
    ];
    const pdfType = ["pdf"];
    const docType = ["doc", "docx", "odt", "ppt"];
    const excelType = ["xls", "xlsx", "ods"];
    const textType = ["txt", "js", "html", "css", "py", "java", "ts"];
    const ext = getExtentionFromFileNmae(fileName);
    if (videoType.includes(ext)) return "video";
    else if (audioType.includes(ext)) return "audio";
    else if (imageType.includes(ext)) return "image";
    else if (pdfType.includes(ext)) return "pdf";
    else if (docType.includes(ext)) return "doc";
    else if (excelType.includes(ext)) return "excel";
    else if (textType.includes(ext)) return "text";
    else return "system";
  };

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      let p = `${localDateString} ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  const goToDetailsPage = (id, name) => {
    // console.log(id, name)
    if (id !== undefined) {
      if (name === undefined) {
        //console.log("content", id);
        name = "content";
        history({
          pathname: "/call_recordings/recent-details",
          search: `?${createSearchParams({ id, name })}`,
        });
      } else {
        //console.log("sleeve", id);
        name = "sleeve";
        history({
          pathname: "/call_recordings/recent-details",
          search: `?${createSearchParams({ id, name })}`,
        });
      }
    }
  };

  useEffect(() => {
    prepareArray();
  }, [prepareArray()]);

  const getDetailsContent = (arrayToDisplay) => {
    if (arrayToDisplay instanceof Array) {
      return arrayToDisplay.map(function (object, i) {
        const {
          sleeveId,
          name,
          sleeveName,
          description,
          transcript,
          createdAt,
        } = object;
        // console.log(object);
        return (
          <>
            <div key={sleeveId}>
              <h2
                className="elastic-header"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  goToDetailsPage(sleeveId, name ? name : sleeveName)
                }
              >
                {name ? name : sleeveName}
              </h2>
              {/* <p className="elastic-para">{convertDate(createdAt)}</p> */}
              <span className="elastic-para text-wrap-afte-two-line">
                {transcript}
              </span>
            </div>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="container rounded bg-white p-4">
        <Tabs defaultActiveKey="1" onChange={onChange}>
          {detaildObj.video.length > 0 ? (
            <TabPane tab="Video" key="1">
              {getDetailsContent(detaildObj.video)}
            </TabPane>
          ) : (
            ""
          )}
          {detaildObj.audio.length > 0 ? (
            <TabPane tab="Audio" key="2">
              {getDetailsContent(detaildObj.audio)}
            </TabPane>
          ) : (
            ""
          )}
          {detaildObj.doc.length > 0 ? (
            <TabPane tab="Doc" key="3">
              {getDetailsContent(detaildObj.doc)}
            </TabPane>
          ) : (
            ""
          )}
          {detaildObj.pdf.length > 0 ? (
            <TabPane tab="Pdf" key="4">
              {getDetailsContent(detaildObj.pdf)}
            </TabPane>
          ) : (
            ""
          )}
          {detaildObj.image.length > 0 ? (
            <TabPane tab="Image" key="5">
              {getDetailsContent(detaildObj.image)}
            </TabPane>
          ) : (
            ""
          )}
          {detaildObj.doc.length > 0 ? (
            <TabPane tab="Document" key="6">
              {getDetailsContent(detaildObj.doc)}
            </TabPane>
          ) : (
            ""
          )}
          {detaildObj.others.length > 0 ? (
            <TabPane tab="others" key="7">
              {getDetailsContent(detaildObj.others)}
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      </div>
    </>
  );
};

export default CollaborateSearchDetails;
