import moment from "moment";
import React from "react";
import { useState } from "react"; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../Constants/api-path";
import FlashCard from "../Utils/FlashCard";
import SuccessModal from "../Utils/SuccessModal";

function TranscriptModal({ datas, expertId, selected,setIsSuccessModal,setIsErrorModal,selectEvent }) {
  let clientDatas = JSON.parse(localStorage.getItem("userData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  let str="";
selectEvent.forEach((item,index)=>{
  str= str+`<a style="font-size: 14px;">Start: ${new Date(item.start).toLocaleDateString()} ${new Date(item.start).toLocaleTimeString()} GMT</a><br><a style="font-size: 14px;">End :${new Date(item.end).toLocaleDateString()} ${new Date(item.end).toLocaleTimeString()} GMT</a><br>`
}
);
console.log(str);
  if (datas && Object.keys(datas).length !== 0) {
    var payload = {
      currentDesignation: datas.currentDesignation
        ? datas.currentDesignation
        : datas.designation,
      currentCompany: datas.currentCompany
        ? datas.currentCompany
        : datas.company,
      country: datas.country,
      expertRate: datas.expertRate ? datas.expertRate : datas.expert_rate,
      expertName: datas.name,
      expert_id: datas.expertId ? datas.expertId : datas.expert_id,
      clientName: clientDatas.user.firstNmae,
      clientCompany: clientDatas.user.company_id.name,
      clientCountry: clientDatas.user.country,
      clientContactNumber: clientDatas.user.phoneNumber,
      clientEmail: clientDatas.user.email,
      availableTimes:str,
      
      
    };
    //console.log(payload);
  }
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": userData.access_token,
  };

  const handelAdminMail = async (type) => {
    //console.log(payload);
    try {
      const res = await fetch(`${API_PATH.ONE_EXPERT}/${type}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const result = await res.json();
      if (result) {
        setIsSuccessModal(true);
        // return toast.success("Your availability added successfully", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 3000,
        // });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };



  const handelRequestedExpert = async (type) => {
    //console.log(payload);
    try {
      const res = await fetch(`${API_PATH.ADD_REQUESTED_EXPERT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": clientDatas.access_token,
        },
        body: JSON.stringify({
          expertId: expertId,
          userId: clientDatas.user._id,
          scheduledTime: selected,
          transcriptType: type,
        }),
      });
      const result = await res.json();
      if (result) {
        // window.location.reload();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handelSubmit = (type) => {
    handelAdminMail("requested");
    handelRequestedExpert();
  };

  return (
    <>
    <SuccessModal />
    <div
      className="modal fade pro-jects1"
      id="createProjectModal1"
      tabIndex="-1"
      aria-labelledby="createProjectModal1Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Modal title
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ marginTop: "-20px" }}
            ></button>
          </div>
          <div className="modal-body">
            <div
              className="add-ps-modal-1"
              style={{ maxWidth: "90%", margin: "auto" }}
            >
              <h2 className="text-center">
                How would you like to transcript your call?
              </h2>
              <div className="d-flex justify-content-center mt-5">
                <div className="text-center me-5">
                  <button
                    className="btn blnkBttn"
                    onClick={() => handelSubmit("Automated")}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Automated
                  </button>
                  <p className="mt-3 freeTrans">FREE!</p>
                  
                </div>
                <div className="text-center">
                <button
                    className="btn nextBttn"
                    onClick={() => handelSubmit("Manual")}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Manual
                  </button>  
                  <p className="mt-3 min15">$1.5/Min.</p>
                </div>
              </div>
            </div>
            <div style={{ width: "90%", margin: "auto" }}>
              <hr />
              <div className="d-flex">
                <h4
                  style={{
                    marginRight: "20px",
                    fontSize: "16px",
                    color: "#5C5F62",
                    fontWeight: 600,
                  }}
                >
                  NOTE:
                </h4>
                <div>
                  <p style={{ color: "#5C5F62", lineHeight: "21px" }}>
                    <strong>Automated: </strong>We use a third party software
                    for automated transcription. Accuracy may be compromised in
                    such instances and is measured at around 65%.
                  </p>
                  <p style={{ color: "#5C5F62", lineHeight: "21px" }}>
                    <strong>Manual: </strong>Manual transcription usually takes
                    36 hours post completion of the consulting call. Accuracy in
                    a manual transcript is > 97%.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>


</>
  );
}

export default TranscriptModal;
