import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import "../App.css";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import CompleteTables from "./CompleteTables";
import RequestTableCell from "./RequestTableCell";

function ExpertCell() {
  let d = new Date();
  let m = d.getMonth() + 1;
  if (m < 10) m = `0${m}`;
  const [loading, setLoading] = useState(false);
  const [expertCellData, setExpertCell] = useState([]);
  const [sdate, setSdate] = useState();
  const [edate, setEdate] = useState();
  const [callType, setCallType] = useState("recent");
  document.title = "Expert Cloud -  Expert Cell";
  const userData = JSON.parse(localStorage.getItem("userData"));

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const headers = {
    "Content-Type": "text/plain",
    "x-access-token": userData.access_token,
  };

  const getExpertcall = async () => {
    try {
      let url = `${API_PATH.CALL_LIST}/${userData?.user?.email}?sdate=${sdate}&edate=${edate}`;

      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.refresh_token}`,
          },
          params: {
            callType,
          },
        })
        .then((res) => {
          console.log(res, "resData-->");
          let expertData = res?.data?.data;

          if (callType === "recent") {
            expertData = expertData.slice(0, 5);
          }

          setExpertCell(expertData);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(".......expertCellData", expertCellData);
  console.log("....callType", callType);
  useEffect(() => {
    getExpertcall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdate, edate, callType]);

  // const getRequestedTab = () => {
  //   if (expertCellData) {
  //     if (expertCellData instanceof Array) {
  //       return (<RequestTableCell data={expertCellData} />)
  //     }
  //   }
  // }

  const getCompletedTab = () => {
    if (expertCellData) {
      if (expertCellData instanceof Array) {
        return (
          <CompleteTables
            expertCellData={expertCellData}
            setSdate={setSdate}
            setEdate={setEdate}
            setCallType={setCallType}
            callType={callType}
            loading={loading}
            getExpertcall={getExpertcall}
          />
        );
      }
    }
  };

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages2 expert-cell-page">
                <div className="d-flex top-ps-seection justify-content-between align-items-center">
                  <h2> Expert Calls </h2>

                  <ul
                    className="nav nav-pills mb-3 mt-4 mt-md-0 mb-md-0"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      // onClick={() => getExpertcall()}
                      >
                        Requested Calls
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      // onClick={() => getExpertcall()}
                      >
                        Completed Calls
                      </button>
                    </li>
                  </ul>
 
                </div>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane pt-2 fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <RequestTableCell />
                  </div>
                  <div
                    className="tab-pane pt-2 fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {getCompletedTab()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div
        className="modal fade pro-jects1"
        id="carteProjectModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-ps-modal-1">
                <h2 className="text-center"> Create New Project </h2>
                <p className="text-center">
                  {" "}
                  Detail out your reqiurement to help us match you with the best
                  in the industry{" "}
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Project title * </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Number of expected calls *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value>Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Project deadline *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value>Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Project Overview * </label>
                      <div className="mainl">
                        <textarea
                          className="form-control"
                          placeholder="Project brief"
                        ></textarea>
                        <figure className="mainl-c">
                          <img src="images/mail-pic.png" />
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#projects-modal"
                      data-bs-dismiss="modal"
                      className="btn col-lg-5 mx-auto btnsm"
                    >
                      {" "}
                      Create{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade projects-modal"
        id="projects-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="projects-modal-divs">
                <div className="d-flex position-relative align-items-center">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#carteProjectModal"
                    data-bs-dismiss="modal"
                    className="btn back-n-bt mr-auto"
                  >
                    {" "}
                    <FaAngleLeft /> Back{" "}
                  </button>
                  <h2 className="text-center mx-auto"> You are almost done </h2>
                </div>

                <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p>

                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Geography of experts *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value>Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Target Companies *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value>Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Invite your team (Optional)*</label>
                      <div className="new-input1 d-flex justify-content-between">
                        <input
                          type="form-control"
                          placeholder="Enter Email.."
                        />
                        <span> techrefic.com</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Request calls to be recorded
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-5">
                    <button
                      data-bs-dismiss="modal"
                      type="button"
                      className="btn col-lg-5 mx-auto btnsm"
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpertCell;
