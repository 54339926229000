import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Helmet from "react-helmet";
import Pagination1 from "react-js-pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import no_result from "../assests/images/no_result.svg";
import { CreateProjectModal } from "../components/CreateProjectModal";
import API_PATH from "../Constants/api-path";
import MailEditor from "../MailEditor";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import ComonProjectTable from "./ComonProjectTable";

const config = {
  readonly: false,
  height: "280px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

function Projects() {
  const [, setValue] = useState("");
  const [projectListObj, setProjectListObj] = useState("");
  const [holdprojectListObj, setHoldProjectListObj] = useState("");
  const [completeprojectListObj, setCompleteProjectListObj] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit,] = useState(10);
  document.title = "Client | Projects";

  const [tabClick, setTabClick] = useState('Ongoing')

  const getProjectList = React.useCallback(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log(userData);
    const headers = {
      Accept: "*/*",
      "Content-Type": "text/plain;charset=UTF-8",
      "x-access-token": userData.access_token,
    };
    try {
      const res = await fetch(
        `${API_PATH.LIVE_PROJECT}/${userData.user.email}?page=${currentPage}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(true);
        console.log(result, "resultss");
        setProjectListObj(result);
        // setAlldatas(result.count);
        // console.log(projectListObj);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [currentPage, limit]);

  const getholdProjectList = React.useCallback(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log(userData);
    const headers = {
      Accept: "*/*",
      "Content-Type": "text/plain;charset=UTF-8",
      "x-access-token": userData.access_token,
    };
    try {
      const res = await fetch(
        `${API_PATH.HOLD_PROJECT}/${userData.user.email}?page=${currentPage}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(true);
        setHoldProjectListObj(result);
        //setAlldatas(result.count);
        // console.log(projectListObj);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [currentPage, limit]);

  const getcompleteProjectList = React.useCallback(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log(userData);
    const headers = {
      Accept: "*/*",
      "Content-Type": "text/plain;charset=UTF-8",
      "x-access-token": userData.access_token,
    };
    try {
      const res = await fetch(
        `${API_PATH.COMPLETE_PROJECT}/${userData.user.email}?page=${currentPage}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(true);
        setCompleteProjectListObj(result);
        //setAlldatas(result.count);
        //console.log(projectListObj);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [currentPage, limit]);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const handlePageChange = React.useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  useEffect(() => {
    getProjectList();
    getholdProjectList();
    getcompleteProjectList();
  }, [currentPage, getProjectList, getcompleteProjectList, getholdProjectList]);

  const handleTabClick = React.useCallback((tab) => {
    setTabClick(tab)
  }, []);

  return (
    <>
      <MobileMenu />
      <Helmet>
        <title>{"Client | Projects"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages" style={{ paddingTop: "0px" }}>
                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries ">
                  <h2> My Projects </h2>

                  <ul
                    className="nav mx-auto nav-pills mb-3 mt-4"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => handleTabClick('Ongoing')}
                      >
                        Ongoing
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => handleTabClick('On Hold')}
                      >
                        On Hold
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                        onClick={() => handleTabClick('Completed')}
                      >
                        Completed
                      </button>
                    </li>
                  </ul>

                  <div className="create-btn">
                    <CreateProjectModal />
                  </div>
                </div>
                <div className="d-md-flex project-sec-2 top-ps-seection justify-content-between align-items-center m-queries d-md-none">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2> My Projects </h2>

                    <div className="create-btn">
                      <CreateProjectModal />
                    </div>
                  </div>
                  <ul
                    className="nav mx-auto nav-pills mb-3 mt-4"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Ongoing
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        On Hold
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Completed
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane pt-2 fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <ComonProjectTable
                      projectList={
                        projectListObj?.data &&
                          projectListObj?.data?.length > 0 ? (
                          projectListObj?.data
                        ) : (
                          <>
                            <div className="text-center noResultFound">
                              <img src={no_result} alt="img_no_result" />
                              <h3 className="mb-5">No result found </h3>
                              <CreateProjectModal />
                            </div>
                          </>
                        )
                      }
                      load={loading}
                      tabClick={tabClick}
                    />
                    {loading ? (
                      <>
                        {projectListObj.countData >= 10 &&
                          projectListObj.data.length > 0 ? (
                          <div className="pagination-background">
                            <Pagination1
                              activePage={currentPage}
                              itemsCountPerPage={10}
                              totalItemsCount={projectListObj.countData}
                              pageRangeDisplayed={10}
                              onChange={handlePageChange}
                              itemclassName="page-item"
                              linkclassName="page-link"
                              showLast
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "20px" }}
                        >
                          <div role="status">
                            <Spinner animation="border" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <ComonProjectTable
                      projectList={
                        holdprojectListObj?.data &&
                          holdprojectListObj?.data?.length > 0 ? (
                          holdprojectListObj?.data
                        ) : (
                          <>
                            <div className="text-center noResultFound">
                              <img src={no_result} alt="img_no_result" />
                              <h3 className="mb-5">No result found </h3>
                              <CreateProjectModal />
                            </div>
                          </>
                        )
                      }
                      load={loading}
                      tabClick={tabClick}
                    />

                    {loading ? (
                      <>
                        {holdprojectListObj?.countData >= 10 &&
                          holdprojectListObj?.data?.length > 0 ? (
                          <div className="pagination-background">
                            <Pagination1
                              activePage={currentPage}
                              itemsCountPerPage={10}
                              totalItemsCount={holdprojectListObj.totalCount}
                              pageRangeDisplayed={10}
                              onChange={handlePageChange}
                              itemclassName="page-item"
                              linkclassName="page-link"
                              showLast
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "20px" }}
                        >
                          <div role="status">
                            <Spinner animation="border" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <ComonProjectTable
                      projectList={
                        completeprojectListObj?.data &&
                          completeprojectListObj?.data?.length > 0 ? (
                          completeprojectListObj?.data
                        ) : (
                          <>
                            <div className="text-center noResultFound">
                              <img src={no_result} alt="img_no_result" />
                              <h3 className="mb-5">No result found </h3>
                              <CreateProjectModal />
                            </div>
                          </>
                        )
                      }
                      load={loading}
                      tabClick={tabClick}
                    />

                    {loading ? (
                      <>
                        {completeprojectListObj?.countData >= 10 &&
                          completeprojectListObj?.data?.length > 0 ? (
                          <div className="pagination-background">
                            <Pagination1
                              activePage={currentPage}
                              itemsCountPerPage={10}
                              totalItemsCount={
                                completeprojectListObj.totalCount
                              }
                              pageRangeDisplayed={10}
                              onChange={handlePageChange}
                              itemclassName="page-item"
                              linkclassName="page-link"
                              showLast
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "20px" }}
                        >
                          <div role="status">
                            <Spinner animation="border" />
                          </div>
                        </div>
                      </>
                    )}
                    {/* {console.log(
                      "projectListObjcompleted",
                      projectListObj.completed
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div
        className="modal fade pro-jects1"
        id="carteProjectModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-ps-modal-1">
                <h2 className="text-center"> Create New Project </h2>
                <p className="text-center">
                  {" "}
                  Detail out your reqiurement to help us match you with the best
                  in the industry{" "}
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Project title * </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Number of expected calls *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Project deadline *</label>
                      <DatePicker
                        className="form-select"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Project Overview * </label>
                      <div className="mainl">
                        <textarea
                          className="form-control d-none"
                          placeholder="Project brief"
                        ></textarea>
                        <figure className="mainl-c d-none">
                          <img src="images/mail-pic.png" />
                        </figure>
                        <MailEditor setValue={setValue} config={config} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#projects-modal"
                  data-bs-dismiss="modal"
                  className="btn col-lg-5 mx-auto btnsm"
                >
                  {" "}
                  Create{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
