import { FaRegEnvelope } from "react-icons/fa";
import {
  BsCheck2Circle,
  BsFillCartFill,
  BsFillGeoAltFill,
} from "react-icons/bs";

const Data = {
  ExpertSerachAll: [
    {
      id: 1,
      // icon:<FaRegEnvelope/>,
      titel:
        "Senior Consultant Gehrman Lehman Group (GLG),Emissary Exchange and Manager",
      email: "Dhruv@nextyn.com",
      socal: " Dhruv_nextyn",
      call: " +91234567890",
      location: "New York, USA",
      img: "/images/expert-user-img.svg",
      company: "Yahoo Company",
      price: "$525",
      link: "/expert-projectdetails",
    },
  ],
};
export default Data;
