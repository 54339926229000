export const categoryList = [
  {
    label: "E-Commerce",
    key: "E-Commerce",
  },
  {
    label: "Travel",
    key: "Travel",
  },
  {
    label: "Lifestyle",
    key: "Lifestyle",
  },
  {
    label: "Healthcare",
    key: "Healthcare",
  },
  {
    label: "Pharmaceutical",
    key: "Pharmaceutical",
  },
  {
    label: "Food & Beverage",
    key: "Food & Beverage",
  },
  {
    label: "Retail",
    key: "Retail",
  },
  {
    label: "Entertainment",
    key: "Entertainment",
  },
  {
    label: "Professional Services",
    key: "Professional Services",
  },
  {
    label: "Internet",
    key: "Internet",
  },
  {
    label: "Technology",
    key: "Technology",
  },
  {
    label: "Education",
    key: "Education",
  },
  {
    label: "Society",
    key: "Society",
  },
  {
    label: "Logistics",
    key: "Logistics",
  },
  {
    label: "Supply Chain",
    key: "Supply Chain",
  },
  {
    label: "Telecommunications",
    key: "Telecommunications",
  },
  {
    label: "Real Estate",
    key: "Real Estate",
  },
  {
    label: "Energy",
    key: "Energy",
  },
  {
    label: "Climate & Sustainability",
    key: "Climate & Sustainability",
  },
  {
    label: "Pet Care",
    key: "Pets",
  },
  {
    label: "Media",
    key: "Media",
  },
  {
    label: "Sports & Gaming",
    key: "Gaming",
  },
  {
    label: "Agriculture & Plants",
    key: "Agriculture & Plants",
  },
  {
    label: "Insurance",
    key: "Insurance",
  },
  {
    label: "Industrial Machinery",
    key: "Industrial Machinery",
  },
  {
    label: "Home",
    key: "Home",
  },

  {
    label: "Science",
    key: "Science",
  },
  {
    label: "Construction",
    key: "Construction",
  },

  {
    label: "Automotive",
    key: "Automotive",
  },
  {
    label: "Finance",
    key: "Finance",
  },
];
