import { message, Upload } from "antd";
import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import "../App.css";
import no_result from "../assests/images/No-data-cuate.svg";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import FindExpert from "./FindExpert";
import NotesSections from "./NotesSections";
import Transcript from "./Transcript";

const { Dragger } = Upload;

const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};
const fileTypes = ["JPG", "PNG", "GIF"];

function ShareWithMe() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [noteListData, setNoteListData] = useState("");
  const [value, setValue] = useState("");
  const [folderData, setFolderData] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [coverList, setCoverList] = useState([]);
  const [sleeveList, setSleeveList] = useState([]);
  const [recordingData, setRecordingData] = useState("");
  const [file, setFile] = useState(null);
  const [fileprogress, setFileprogress] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [experts, setExperts] = useState([]);
  const [company, setCompany] = useState("");

  const [industry, setIndustry] = useState("");
  const [tags, setExpertise] = useState("");
  const [country, setCountry] = useState("");
  const [searchRgx, setSearchRgx] = useState("");

  const headers = {
    "x-access-token": userData.access_token,
  };

  const handleFileChange = (file) => {
    setFile(file);
  };
  //console.log(file);
  const props = {
    name: "file",
    multiple: true,
    accept: "image/png,image/gif,image/jpg",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setFile(info);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 2,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  // console.log(file);

  const getFolders = async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_LIST}/${userData.user._id}/true`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result);
        setFolderData(result);
        setLoading(true);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // console.log(folderData);

  const getContents = async () => {
    try {
      const res = await fetch(
        `${API_PATH.NOTE_LIST}/${userData.user._id}/true`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setSpinner(true);
        setNoteListData(result);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getRecentlyViewd = async () => {
    try {
      const res = await fetch(`${API_PATH.CONTENT_LIST}/${userData.user._id}`, {
        method: "GET",
        headers: headers,
      });

      const result = await res.json();
      if (result) {
        setCoverList(result);
        setLoading1(true);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

    try {
      const res = await fetch(`${API_PATH.SLEEVE_LIST}/${userData.user._id}`, {
        method: "GET",
        headers: headers,
      });

      const result1 = await res.json();
      if (result1) {
        setSleeveList(result1);
        setLoading1(true);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // if (loading1) {
  //   var contentdata = coverList;
  //   // console.log(contentdata);
  //   var sleevedata = sleeveList;
  //   if (contentdata.length > 0 && sleevedata.length > 0) {
  //     var allDatas = [...contentdata, ...sleevedata];
  //   } else if (contentdata.length > 0) {
  //     var allDatas = [...contentdata];
  //   } else if (sleevedata.length > 0) {
  //     var allDatas = [...sleevedata];
  //   }
  //   // checkPermisionOfUsertable(allDatas);
  // }

  // console.log(allDatas);

  useEffect(() => {
    onInitFunctions();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const onInitFunctions = () => {
    getFolders();
    getContents();
    getRecentlyViewd();
    getRecordings();
  };

  const getRecordings = async () => {
    try {
      let domain = userData.user.email.split("@")[1];
      const res = await fetch(
        `${API_PATH.MEETING_LIST}/${domain}/${userData.user._id}/true`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        console.log(result, "resultData-->");
        setSpinner(true);
        setRecordingData(result);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getExperts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, country, company, tags, industry, searchRgx]);

  const getExperts = () => {
    // setLoading(true);
    axios
      .get(`${API_PATH.EXPERT_LIST}/${currentPage}/${userData.user._id}`, {
        headers,
        params: {
          country,
          company,
          tags,
          industry,
          searchRgx,
          category: userData.user
            ? userData.user.selectedCategory?.join(",")
            : "",
          share: true,
        },
      })
      .then((res) => {
        setSpinner(true);
        setLoading(false);
        setExperts([...res.data.data]);
        setAlldatas(res.data.count);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  document.title = "Client | Collaborate";
  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div request-page projects-scope">
                <h2 className="mb-0 search-btn-back">Shared with me</h2>
                {/*noteListData.length === 0 && folderData.length === 0 &&
                recordingData && recordingData.length === 0 && coverList && coverList.length === 0 ? */}

                {spinner ? (
                  <>
                    {noteListData.length === 0 && recordingData.length === 0 ? (
                      loading ? (
                        <>
                          <div className="text-center noResultFound">
                            <div className="container">
                              <div
                                className="row d-flex justify-content-center align-items-center"
                                style={{ height: "calc(100vh - 100px)" }}
                              >
                                <div className="col-md-6">
                                  <img
                                    src={no_result}
                                    alt="srv"
                                    style={{
                                      height: "400px",
                                      marginTop: "50px",
                                    }}
                                  ></img>
                                </div>
                                <div className="col-md-6">
                                  <h3
                                    style={{
                                      fontSize: "14px",
                                      textAlign: "left",
                                      fontWeight: "300",
                                      lineHeight: "26px",
                                    }}
                                  >
                                    Oops! Doesn't look like anything has been
                                    shared with you yet.{" "}
                                  </h3>
                                  <h3
                                    style={{
                                      fontSize: "14px",
                                      textAlign: "left",
                                      fontWeight: "300",
                                      lineHeight: "26px",
                                    }}
                                  >
                                    We'll keep an eye out and display as soon as
                                    a colleague shares a recording or a document
                                    with you.
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center noResultFound">
                            <div className="container">
                              <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-md-6">
                                  <img
                                    src={no_result}
                                    alt="srv"
                                    style={{
                                      height: "400px",
                                      marginTop: "50px",
                                    }}
                                  ></img>
                                </div>
                                <div className="col-md-6">
                                  <h3
                                    style={{
                                      fontSize: "18px",
                                      textAlign: "left",
                                      fontWeight: "300",
                                      lineHeight: "26px",
                                    }}
                                  >
                                    Oops! Doesn't look like anything has been
                                    shared with you yet. We'll keep an eye out
                                    and display as soon as a colleague shares a
                                    recording or a document with you.
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </>
                      )
                    ) : (
                      <div className="request-body-sec collaborate-page">
                        {console.log(noteListData.length)}
                        {noteListData.length > 0 &&
                        folderData.length > 0 &&
                        recordingData &&
                        recordingData.length === 0 &&
                        experts &&
                        experts.length === 0 ? (
                          <>
                            <div className="notes-sections mt-4">
                              <NotesSections
                                noteList={noteListData}
                                setSpinner={setSpinner}
                                spinner={spinner}
                              />
                            </div>
                            {/* <div className="folder-sections mt-5">
                          <FolderSections folderList={folderData} />
                        </div>
                        <div className="recente-vew-divs mt-5">
                          <RecenetViewTable
                            allDatas={allDatas}
                            func={getRecentlyViewd}
                          />
                        </div> */}
                            <div className="folder-sections mt-4">
                              <Transcript recordingList={recordingData} />
                            </div>
                            <div className="folder-sections mt-2">
                              <FindExpert />
                            </div>
                          </>
                        ) : (
                          <>
                            {noteListData.length > 0 ? (
                              <>
                                <div className="notes-sections mt-4">
                                  <NotesSections noteList={noteListData} />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {/* {folderData.length > 0 ? (
                          <>
                            <div className="folder-sections mt-5">
                              <FolderSections folderList={folderData} />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {allDatas && allDatas.length > 0 ? (
                          <>
                            <div className="recente-vew-divs mt-5">
                              <RecenetViewTable
                                allDatas={allDatas}
                                func={getRecentlyViewd}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )} */}
                            {recordingData.length > 0 ? (
                              <>
                                <div className="folder-sections mt-4">
                                  <Transcript recordingList={recordingData} />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "20px" }}
                    >
                      <div role="status">
                        <Spinner animation="border" />
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="folder-sections mt-5">
                  <Transcript recordingList={recordingData} />
                </div> */}
              </div>
              {console.log(experts.length, "experts.length")}
              {/* {experts.length > 0 ? ( */}
              <>
                <div className="folder-sections mt-2">
                  {userData?.user?.company_id?.shortName === "nextyn-com" ? (
                    <FindExpert />
                  ) : (
                    ""
                  )}
                </div>
              </>
              {/* ) : (
                <></>
              )} */}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </>
  );
}

export default ShareWithMe;
