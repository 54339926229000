import React from "react";
import { InlineWidget } from "react-calendly";
import { useNavigate } from "react-router-dom";

function Calendly() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(userData);
  const history = useNavigate();

  const prefill = {
    email: userData?.data?.email,
    name: userData?.data?.fullName,
  };

  const onEventScheduled = () => {
    history("nextyn.com");
  };

  return (
    <div>
      <InlineWidget
        url="https://calendly.com/rasesh-seth/30min"
        // url="https://calendly.com/"
        prefill={prefill}
        onEventScheduled={onEventScheduled}
      />
    </div>
  );
}

export default Calendly;
