import Avatar from "@mui/material/Avatar";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import API_PATH from "./Constants/api-path";
import "./Sidebar.css";
import {
  AiOutlineLogout,
  BsBell,
  IoOpenOutline,
  IoSettingsOutline,
  SlArrowRight,
} from "./Utils/Icons";
import sharewithme from "./assests/images/group.svg";
import starred from "./assests/images/star.png";
import nextyn_logo from "./assests/images/Nextyn_logo.png";

function Sidebar() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profilePopover, setProfilePopover] = useState(false);
  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  let count = 0;
  if (loading) {
    count = notifications.filter((item) => item.isRead === false).length;
  }

  const getAvailableSize = () => {
    // console.log(userData.user.company_id);
    if (userData.user.company_id) {
      const bytes = userData.user.company_id.bucketSize;
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 5368709120) return "0 Byte";
      var i = parseInt(
        Math.floor(Math.log(5368709120 - bytes) / Math.log(1024), 2)
      );
      // console.log(i)
      return (
        Math.round((5368709120 - bytes) / Math.pow(1024, i), 2) + " " + sizes[i]
      );
    } else {
      return 0;
    }
  };

  const getPercentageUse = () => {
    if (userData.user.company_id) {
      const bytes = userData.user.company_id.bucketSize;
      // console.log( (bytes/5368709120))
      return bytes / 5368709120;
    } else {
      return 0;
    }
  };

  let isAuth = localStorage.getItem("isAuth");
  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("isAuth");
  };

  const getData = async () => {
    try {
      const res = await fetch(
        `${API_PATH.GET_NOTIFICATION_BY_SOCKET}/${userData.user._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setNotifications([...result]);
        setLoading(true);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      // return toast.error("something went wrong server error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
    }
  };

  window.addEventListener("mouseup", (e) => {
    const pol = document.getElementById("userProfile");
    // ? close the menu if the user clicks outside of it
    if (e.target != pol && e.target.parentNode != pol) {
      setProfilePopover(false);
      console.log("sdf");
    }
  });
  return (
    <>
      <div className="sidebar-menu" id="navAccordion">
        <div className="inside0div">
          <div className="logo-admin mb-3">
            <NavLink to="/projects">
              <img src={nextyn_logo} alt="logo" />
            </NavLink>
            {/* <p className="logo-bottom mt-2">
            Welcome,{" "}
            <span className="logo-bottom-name" style={{ color: "#5746ec" }}>
              {userData.user.firstNmae}
            </span>
          </p> */}
          </div>

          <div className="main-menu d-none d-lg-block">
            <ul className="list-unstyled mainMenuUL">
              <li>
                <h5> Dashboard </h5>
                <Link to="/dashboard" activeclassname="menu_active">
                  <span className="menu-icon-new">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      ></path>
                    </svg>
                  </span>
                  <span className="menu-text"> Home Page </span>{" "}
                </Link>

                {userData.user.role_id &&
                userData.user.role_id.findExperts?.view === true ? (
                  <></>
                ) : // <Link
                //   to="/find-expert"
                //   className={
                //     userData?.user?.company_id?.shortName === "nextyn-com"
                //       ? ""
                //       : ""
                //   }
                //   activeclassname="menu_active"
                // >
                //   <span className="menu-icon-new">
                //     <img src="/images/search-icon.svg" alt="sr" />
                //   </span>
                //   <span className="menu-text"> Find Experts </span>{" "}
                // </Link>
                null}

                {userData.user.role_id &&
                userData.user.role_id.projects?.view === true ? (
                  <NavLink to="/projects" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="/images/side-icon2.svg" alt="sr" />
                    </span>
                    <span className="menu-text"> Projects </span>{" "}
                  </NavLink>
                ) : null}

                {userData.user.role_id &&
                userData.user.role_id.collaborate?.view === true ? (
                  <NavLink to="/call_recordings" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="/images/side-icon3.svg" alt="sr" />
                    </span>
                    <span className="menu-text"> Call Recordings </span>{" "}
                  </NavLink>
                ) : null}

                {userData.user.role_id &&
                userData.user.role_id.sharedWithMe?.view === true ? (
                  <NavLink to="/share" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src={sharewithme} width="20px" alt="sr"></img>
                    </span>
                    <span className="menu-text"> Shared with me</span>{" "}
                  </NavLink>
                ) : null}

                {userData.user.role_id &&
                userData.user.role_id.projects?.view === true ? (
                  <></>
                ) : // <NavLink
                //   to="/starred"
                //   className={
                //     userData?.user?.company_id?.shortName === "nextyn-com"
                //       ? ""
                //       : ""
                //   }
                //   activeclassname="menu_active"
                // >
                //   <span className="menu-icon-new">
                //     <img src={starred} alt="sr" />
                //   </span>
                //   <span className="menu-text"> Starred Experts </span>{" "}
                // </NavLink>
                null}
              </li>

              <li>
                {/* <NavLink
                  to="/chat-gpt"
                  className={
                    userData?.user?.company_id?.shortName === "nextyn-com"
                      ? ""
                      : ""
                  }
                  activeclassname="menu_active"
                >
                  <span className="menu-icon-new">
                    <img src="/images/chatGpt.svg" alt="sr" />
                  </span>
                  <span className="menu-text"> ChatGpt </span>{" "}
                </NavLink> */}

                {/* {userData.user.role_id &&
                  userData.user.role_id.collaborate?.view === true ? (
                  <NavLink to="/collaborate" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="/images/side-icon3.svg" alt="sr" />
                    </span>
                    <span className="menu-text"> Call Recordings </span>{" "}
                  </NavLink>
                ) : null} */}

                {/* {userData.user.role_id &&
                  userData.user.role_id.sharedWithMe?.view === true ? (
                  <NavLink to="/share" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src={sharewithme} width="20px" alt="sr"></img>
                    </span>
                    <span className="menu-text"> Shared with me</span>{" "}
                  </NavLink>
                ) : null} */}

                {/* {userData.user.role_id &&
                userData.user.role_id.conversation.view === true ? (
                  <NavLink to="/conversations" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="/images/side-icon4.svg" alt="sr" />
                    </span>
                    <span className="menu-text"> Conversations</span>{" "}
                  </NavLink>
                ) : null} */}

                {userData.user.role_id &&
                userData.user.role_id.expertsCall?.view === true ? (
                  <></>
                ) : // <NavLink to="/expert-call" activeclassname="menu_active">
                //   {" "}
                //   <span className="menu-icon-new">
                //     <img src="/images/icn.png" alt="sr" />
                //   </span>{" "}
                //   <span className="menu-text"> Expert Calls </span>{" "}
                // </NavLink>
                null}

                {/* {userData.user.role_id &&
                userData.user.role_id.news?.view === true ? ( */}
                {/* <NavLink to="/news" activeclassname="menu_active">
                  {" "}
                  <span className="menu-icon-new">
                    <img src="/images/search-icon6.svg" alt="sr" />
                  </span>{" "}
                  <span className="menu-text"> News </span>{" "}
                </NavLink> */}
                {/* ) : null} */}
              </li>

              <li>
                <h5> GENERAL </h5>
                {userData?.user?.role_id &&
                userData?.user?.role_id?.userManagement?.view === true ? (
                  <NavLink
                    to="/helpsupport"
                    activeclassname="menu_active"
                    className="menu-text"
                    data-bs-toggle="collapse"
                    data-bs-target="#home-collapse"
                    aria-expanded="false"
                  >
                    {" "}
                    <span className="menu-icon-new">
                      <img src="/images/search-icon7.svg" alt="sr" />
                    </span>
                    <span className="menu-text"> User Management </span>{" "}
                  </NavLink>
                ) : null}

                {userData?.user?.role_id &&
                userData?.user?.role_id?.userManagement?.view === true ? (
                  <div className="collapse" id="home-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal">
                      <li>
                        <NavLink
                          to="/usermangement/roles"
                          className="link-dark  text-decoration-none"
                          style={{ marginTop: "-16px", paddingLeft: "47px" }}
                        >
                          <span className="menu-text"> Add Role </span>{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/usermangement/users"
                          className="link-dark  text-decoration-none"
                          style={{ marginTop: "-16px", paddingLeft: "47px" }}
                        >
                          <span className="menu-text"> Users </span>{" "}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                ) : null}

                <NavLink to="/help-support" activeclassname="menu_active">
                  {" "}
                  <span className="menu-icon-new">
                    <img src="/images/search-icon8.svg" alt="sr" />
                  </span>
                  <span className="menu-text"> Help & Support </span>{" "}
                </NavLink>

                <a
                  href="https://nextyn.com/privacy-policy"
                  target="_blank"
                  activeclassname="menu_active"
                >
                  {" "}
                  <span className="menu-icon-new">
                    <img src="/images/search-icon9.svg" alt="sr" />
                  </span>{" "}
                  <span className="menu-text"> Privacy </span>{" "}
                </a>

                {/* <NavLink
                to="/storage"
                style={{
                  pointerEvents: "none",
                  borderTop: "1px solid #ededf0",
                }}
                activeclassname="menu_active"
              >
                {" "}
                <span className="menu-icon-new">
                </span>
                <span className="menu-text"> Storage </span>{" "}
              </NavLink>
              <div className="progress" style={{ marginLeft: "15px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${getPercentageUse()}%` }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>

              <p className="storageInfo">{getAvailableSize()} of 5 GB used</p> */}
              </li>
            </ul>
            <div className="userProfileWrapper">
              <div className="user_Profiles" id="userProfile">
                <div
                  className="d-flex align-items-center justify-content-between p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setProfilePopover(!profilePopover)}
                >
                  <div className="d-flex align-items-center">
                    <Avatar
                      style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
                      sx={{
                        bgcolor: "#EFEFEF",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      {userData?.user?.name === undefined
                        ? userData?.user?.name[0]?.toUpperCase()
                        : userData?.user?.name[0]?.toUpperCase()}
                    </Avatar>
                    <div className="profileSidebarDetails">
                      <h5>{userData?.user?.name}</h5>
                      {/* <p>{userData?.user?.email}</p> */}
                    </div>
                  </div>
                  <div>
                    <SlArrowRight />
                  </div>
                </div>
                {/* <div className="p-2" style={{ borderTop: "1px solid #d9d9d9" }}>
                  <NavLink
                    to={"/categories"}
                    className="mb-0 text-center fw-bold"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      color: "black",
                    }}
                  >
                    Customize Categories
                  </NavLink>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`profilePopover`}>
        <div className={`positibsw ${profilePopover && "active"}`}>
          <div className="d-flex align-items-center">
            <Avatar
              style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
              sx={{
                bgcolor: "#EFEFEF",
                height: "30px",
                width: "30px",
              }}
            >
              {userData?.user?.name === undefined
                ? userData?.user?.name[0]?.toUpperCase()
                : userData?.user?.name[0]?.toUpperCase()}
            </Avatar>
            <div className="profileSidebarDetails">
              <h5>{userData?.user?.name}</h5>
              <p>{userData?.user?.email}</p>
            </div>
          </div>
          <hr />
          <ul className="profileUl">
            <li>
              <NavLink
                to={"/profile"}
                onClick={() => setProfilePopover(false)}
                className="d-flex align-items-center"
              >
                <IoSettingsOutline className="me-2 fs-5" /> Account Setting
              </NavLink>
            </li>
            <li>
              <Badge count={count}>
                <NavLink
                  to={"/notification"}
                  onClick={() => setProfilePopover(false)}
                  className="d-flex align-items-center"
                >
                  <BsBell className="me-2 fs-5" /> Notifications
                </NavLink>
              </Badge>
            </li>
            <li>
              <NavLink
                to={"/change-password"}
                className="d-flex align-items-center"
                onClick={() => setProfilePopover(false)}
              >
                <IoOpenOutline className="me-2 fs-5" /> Change Password
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/"}
                onClick={handleLogout}
                className="d-flex align-items-center"
              >
                <AiOutlineLogout className="me-2 fs-5" /> Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default Sidebar;
