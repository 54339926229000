import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import TopMenu from "../TopMenu";
import SharedRecentViewDetails from "./SharedRecentViewDetails";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { Dropdown, FormControl, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../App.css";
import userColored from "../assests/images/userColored.svg";
import API_PATH from "../Constants/api-path";
import { CopyToClipboard } from "../Utils/CopyToClipboard";

const SharedRecentView = () => {
  let history = useNavigate();
  let { folder_id, sleeve_id } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [datas, setDatas] = useState({});
  const [userEmail, setEmail] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [permittedUsers, setPermittedUsers] = useState("");
  const [show, setShow] = useState(false);
  const [userPermission, setUserPermission] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
  });

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const getDatas = async () => {
    //console.log(data);
    axios
      .get(`${API_PATH.SLEEVE_DETAILS}/${sleeve_id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        //getUsers();
        //console.log(res);
        setDatas(res.data);
        setPermittedUsers(res.data.permittedUsers);
        checkPermisionOfUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkPermisionOfUser = (obj) => {
    // console.log(obj);
    const filteredArray = obj.permittedUsers.filter(
      (o) => o.userId === userData.user._id
    );
    if (filteredArray.length > 0) {
      setUserPermission(filteredArray[0].permission);
    } else if (obj.userId && obj.userId.id === userData.user._id) {
      setUserPermission("all");
    }
  };
  //console.log(datas);

  // console.log(userPermission);

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      let p = `${localDateString} ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const updateNotePermission = async (permission, email) => {
    setPayload({
      email: email,
      permission: permission,
    });
  };
  //console.log(payload);
  const makeUpdate = async () => {
    try {
      const res = await fetch(
        `${API_PATH.SLEEVE_PERMISSION}/${sleeve_id}/${userData.user._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({
            permission: payload.permission,
            email: payload.email,
          }),
        }
      );
      const result = await res.json();
      if (result) {
        setEmail("");
        getDatas();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const updatePermission = async (permission, email) => {
    try {
      const res = await fetch(
        `${API_PATH.SLEEVE_PERMISSION}/${sleeve_id}/${userData.user._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({ permission: permission, email: email }),
        }
      );
      const result = await res.json();
      if (result) {
        // console.log(result);
        getDatas();
      } else {
        // console.log(7);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      // console.log(8);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  const getPUserList = () => {
    if (permittedUsers instanceof Array) {
      return permittedUsers.map(function (object, i) {
        const { _id, firstNmae, username, email, permission } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                    />
                  </div>

                  {/* <div
                    className="profileIcon"
                    style={{
                      backgroundImage: `url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${email})`,
                    }}
                  ></div> */}
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">{firstNmae ? firstNmae : username}</span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can edit and invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        onClick={() => updatePermission("edit", email)}
                      >
                        Can edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  const stopSharing = async (e) => {
    e.preventDefault();

    try {
      let payload = {};
      payload.permittedUsers = [];
      const res = await fetch(`${API_PATH.SLEEVE_UPDATE}/${sleeve_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        getDatas();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const deleteSleeve = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(
        `${API_PATH.REMOVE_SLEEVE}?sleeveId=${sleeve_id}&userId=${userData.user._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
        }
      );

      const result = await res.json();
      if (result) {
        //history("/share");
        window.history.back();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div>
        <div className="page-wrapper chiller-theme toggled">
          <div className="main-sec d-lg-flex">
            <div className="sidebar-menu" id="navAccordion">
              <Sidebar />
            </div>
          </div>
          <main className="body-total content-wrapper float-start w-100">
            <div className="container-fluid">
              <div className="d-block d-lg-block">
                <div className="top-bar-menu float-start" id="header">
                  <TopMenu />
                </div>
              </div>
              {Object.keys(datas).length > 0 ? (
                <div className="dasb-div bk-div float-start w-100">
                  <div className="body-expart-div projects-pages support-page">
                    <div className="d-flex top-ps-seection justify-content-between align-items-center mb-4">
                      <div className="coverInnerBreadcum">
                        <span
                          className="coverInnerBreadcumH3"
                          onClick={() =>
                            history({
                              pathname: `/call_recordings/folders-details?coverID=${datas.coverId._id}`,
                            })
                          }
                        >
                          {datas.coverId ? datas.coverId.name : ""}
                        </span>
                        <span>/{datas.name ? datas.name : datas.sleeveName}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          className="text-center mb-0 me-3"
                          style={{ fontSize: "15px" }}
                        >
                          {convertDate(datas.createdAt)}
                        </p>

                        <div className="d-flex align-items-center">
                          {datas ? (
                            datas.permittedUsers?.length > 0 ? (
                              <OverlayTrigger
                                trigger="hover"
                                key={Math.random()}
                                placement={"top"}
                                overlay={
                                  <Popover
                                    id={`popover-positioned-${Math.random()}`}
                                  >
                                    <Popover.Body className="p-2">
                                      {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                              Public link enabled
                            </h6> */}
                                      <div>
                                        <small>
                                          Shared with{" "}
                                          {datas.permittedUsers?.length} people
                                        </small>
                                      </div>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {datas.permittedUsers?.length > 0 ? (
                                  <img src={userColored} />
                                ) : (
                                  <></>
                                )}
                              </OverlayTrigger>
                            ) : (
                              <div
                                style={{
                                  color: "#737373",
                                  marginRight: "20px",
                                }}
                              >
                                <span>Only you</span>
                              </div>
                            )
                          ) : (
                            <></>
                          )}
                          {userPermission === "view" ? (
                            <></>
                          ) : (
                            <>
                              {userPermission === "edit" ? (
                                <>
                                  <Button
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                  >
                                    <MoreHorizIcon />
                                  </Button>

                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    <MenuItem
                                      className="dropdown-item"
                                      data-bs-toggle="modal"
                                      data-bs-target="#RemoveModal4"
                                      href="#"
                                      onClick={handleClose}
                                    >
                                      Remove
                                    </MenuItem>
                                  </Menu>
                                </>
                              ) : (
                                <>
                                  <button
                                    style={{
                                      // backgroundColor: "rgb(0, 168, 45)",
                                      color: "#ffffff",
                                      marginLeft: "15px",
                                    }}
                                    type="button"
                                    to="#"
                                    className="one-ex-btn btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal6"
                                  >
                                    Share
                                  </button>
                                  <Button
                                    style={{ marginRight: "0px" }}
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                  >
                                    <MoreHorizIcon />
                                  </Button>

                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    {/* <MenuItem
                                      onClick={handleClick}
                                      className="dropdown-item"
                                      data-bs-toggle="modal"
                                      data-bs-target="#NotesModal"
                                      href="#"
                                    >
                                      Edit
                                    </MenuItem> */}
                                    <MenuItem
                                      className="dropdown-item"
                                      data-bs-toggle="modal"
                                      data-bs-target="#RemoveModal4"
                                      href="#"
                                      onClick={handleClose}
                                    >
                                      Remove
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="faq-section">
                      {datas ? (
                        <SharedRecentViewDetails datas={datas} />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </main>
        </div>
      </div>

      <div
        className="modal fade addmodal cm-modal-all"
        id="shareModal6"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mt-4">
                <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText={window.location.href} />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value={window.location.href}
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div>

                <div className="mt-4 inviteSection">
                  <div className="inviteTitle">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      className="inputGroupInput"
                      name="email"
                      value={userEmail}
                      onChange={(event) => {
                        handleRequestInputs(event);
                        handleChange(event);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                        >
                          Can edit and invite
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() =>
                              updateNotePermission("editi", userEmail)
                            }
                          >
                            Can edit and invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() =>
                              updateNotePermission("edit", userEmail)
                            }
                          >
                            Can edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() =>
                              updateNotePermission("view", userEmail)
                            }
                          >
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {show && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ border: "none" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        setEmail("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#5746ec", border: "none" }}
                      onClick={makeUpdate}
                    >
                      Submit
                    </button>
                  </div>
                )}

                {permittedUsers?.length > 0 ? (
                  <button className="accessInfo" type="button">
                    <span style={{ transform: "rotateZ(90deg)" }}>
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        id="qa-SHARE_DIALOG_SHARED_WITH_ARROW"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 7l6 5-6 5V7z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>

                    <div
                      id="qa-SHARE_DIALOG_SHARED_WITH_MORE"
                      className="s1E8jgYqDWXyHdyBw59R awJGfcqMABRSyxZlnZGS _7_HBJzy3qX1OrTcUZny"
                    >
                      Who has access
                      <span className="Oy7u6Y9EGKJJM_TfDDta">
                        {permittedUsers ? permittedUsers?.length : 0}
                      </span>
                    </div>
                  </button>
                ) : null}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">{getPUserList()}</div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                    onClick={stopSharing}
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="RemoveModal4"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "none" }}>
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "18px" }}>
                    Do you really want to deactivate these records? This process
                    cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-end"
              style={{ border: "none" }}
            >
              <button type="button" className="btn btn-secondary">
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                onClick={deleteSleeve}
                className="btn btn-primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedRecentView;
