import axios from "axios";
import $ from "jquery";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Pagination1 from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import "../App.css";
import API_PATH from "../Constants/api-path";
import "./Dashboard.css";
import Starred from "./Starred";

function FindExpert() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [experts, setExperts] = useState([]);
  const history = useNavigate();
  const [user_id, setUser_id] = useState(userData.user._id);
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);

  const headers = {
    "x-access-token": userData.access_token,
  };

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    setCurrentPage(pageNumber);

    history({
      pathname: `/starred`,
      search: `?ctPage=${btoa(pageNumber)}`,
    });
  };

  const changeStatus = () => {
    //console.log("here");
    return setLoading(true);
  };

  const changeQueryStatus = () => {
    //console.log("here");
    return setLoading(false);
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const getExperts = () => {
    // setLoading1(true);
    axios
      .get(`${API_PATH.ALL_STARRED_EXPERT}/${currentPage}/${user_id}`, {
        headers: headers,
      })

      .then((res) => {
        console.log(res);
        // setLoading1(false);
        setLoading(true);
        setExperts([...res.data.data]);
        setAlldatas(res.data.count);
      })
      .catch((error) => {
        // setLoading1(false);
        // console.log(error);
      });
  };

  // console.log(allDatas);

  useEffect(() => {
    getExperts();
  }, [currentPage]);

  useEffect(() => {
    getExperts();
  }, []);

  // console.log(loading);

  // console.log(experts);

  useEffect(() => {
    let { ctPage } = queryString.parse(window.location.search);
    
    if (ctPage) {
      ctPage=atob(ctPage);
      if(!isNaN(parseInt(ctPage))) {
        setCurrentPage(parseInt(ctPage));
        getExperts(ctPage);
      }
    } else {
      getExperts(1);
    }
    setLoading(false);
    setTimeout(() => {
      setLoading(true);
    }, 3000);
  }, [currentPage]);

  let isAuth = localStorage.getItem("isAuth");
  //console.log(isAuth);
  document.title = "Expert Cloud -  Find Experts";
  if (!isAuth) {
    return (window.location.href = "/");
  }
  return (
    <>
      {/* <LoadingScreen
        loading={isLoding}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        text="Loading..."
      /> */}

      <div className="container-fluid">
        <div className="d-block mobile-fill-width">
          {/* <div className="top-bar-menu float-start" id="header">
            <TopMenu1 />
          </div> */}
        </div>

        <div className="dasb-div bk-div float-start w-100">
          {/* <TopSelectOption /> */}

          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <>
              <h2 className=""> Starred Experts </h2>
              <p style={{ fontSize: "20px", color: "#526fd7" }}>
                A depository of all your favourite experts
              </p>
            </>

            <div className="tab-content maxWidth" id="pills-tabContent">
              <div
                className="tab-pane pt-2 fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Starred props={experts} />
                      {allDatas > 10 ? (
                        <div className="pagination-background">
                          <Pagination1
                            activePage={currentPage}
                            itemsCountPerPage={10}
                            totalItemsCount={allDatas}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade projects-modal"
        id="expertmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="projects-modal-divs">
                <div className="d-flex position-relative align-items-center">
                  <h2 className="text-center mx-auto">
                    {" "}
                    How many experts would you like to consult?
                  </h2>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-8 p-0 mx-auto d-flex justify-content-center">
                    <a id="#" className="one-ex-btn btn">
                      {" "}
                      One Expert{" "}
                    </a>
                    <a id="#" className="more-ex-btn btn">
                      {" "}
                      More than one{" "}
                    </a>
                  </div>
                  <hr className="col-lg-8 mt-4 mx-auto " />

                  <div className="notes col-lg-8 mx-auto p-0">
                    <span> NOTE: </span>{" "}
                    <span>
                      {" "}
                      If your project requires multiple experts, please click on
                      “More than one” and our consultants will reach out to you
                      to assist with a custom recruitment.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindExpert;
