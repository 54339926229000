export const categoryArrayFour = [
  {
    label: "Industrial Machinery & Manufacturing",
    key: "Industrial Machinery",
  },
  {
    label: "Home",
    key: "Home",
  },

  {
    label: "Science",
    key: "Science",
  },
  {
    label: "Construction",
    key: "Construction",
  },

  {
    label: "Automotive",
    key: "Automotive",
  },
  {
    label: "Finance",
    key: "Finance",
  },
  {
    label: "Select All",
    key: "selectAll",
  },
];
