import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerDiv() {
  const [value, onChange] = useState(new Date());
  return (
    <>
      <DatePicker onChange={onChange} value={value} />
    </>
  );
}
export default DatePickerDiv;
