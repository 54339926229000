import { FaRegEnvelope } from "react-icons/fa";
import {
  BsCheck2Circle,
  BsFillCartFill,
  BsFillGeoAltFill,
} from "react-icons/bs";

const NotificationPin = [
  {
    id: 1,
    // icon:<FaRegEnvelope/>,
    titel: "Expert @Williamson , Accepted...",
    days: "4 mins ago",
    orderid: "Call Started at 13-04-2022 : 13:00 IST",
    img: "images/noti-img1.svg",
  },
  {
    id: 2,
    // icon:<BsCheck2Circle/>,
    titel: "Nextyn Updated",
    days: "4 mins ago",
    orderid: "Lorem ipsum dolor sit amet, consectet...",
    img: "images/noti-img1.svg",
  },
  {
    id: 3,
    // icon:<BsFillCartFill/>,
    titel: "Nextyn Updated",
    days: "4 mins ago",
    orderid: "Call Started at 13-04-2022 : 13:00 IST",
    img: "images/noti-img1.svg",
  },
  {
    id: 4,
    // icon:<BsFillGeoAltFill/>,
    titel: "Expert @Williamson , Accepted...",
    days: "4 mins ago",
    orderid: "Lorem ipsum dolor sit amet, consectet...",
    img: "images/noti-img1.svg",
  },
];
export default NotificationPin;
