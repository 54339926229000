import React, { useEffect, useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { Button } from "antd";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination1 from "react-js-pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";
import { Editor } from "@tinymce/tinymce-react";
import { Checkbox } from "antd";
import countryList from "./CountryList";

export const CreateProjectModal = () => {
  let animatedComponents = makeAnimated();
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [startDate, setStartDate] = useState("");
  const [value, setValue] = useState("");
  const [inputList, setInputList] = useState([{ company: "" }]);
  const [selectList, setSelectList] = useState([{ country: "" }]);
  const [emailList, setEmailList] = useState([{ email: "" }]);
  const [, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [, setCall_recorded] = useState(0);
  const [loading1, setLoading1] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const headers = React.useMemo(() => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  }), [userData?.token]);


  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const inputRef7 = useRef();

  const handleClose = React.useCallback(() => {
    setStartDate("");
    setValue("");
  }, []);

  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
  });

  const handelChange = React.useCallback((e) => {
    let key = e.target.name;
    let value = e.target.value;
    setProjectDetails({ ...projectDetails, [key]: value });
  }, [projectDetails]);

  const handleEditorChange = React.useCallback((content) => {
    setValue(content);
  }, []);

  const handlePageChange = React.useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const getProjects = React.useCallback(() => {
    axios
      .get(`${API_PATH.PRJECT_LIST}/${currentPage}`, {
        headers: headers,
      })

      .then((res) => {
        setLoading1(true);
        setAlldatas(res.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [currentPage, headers])

  //COMMENTED COZ NOT FINDING THIS API ON SERVER ERROR
  // useEffect(() => {
  //   getProjects();
  // }, [currentPage, getProjects]);

  const handelChecked = React.useCallback((e) => {
    if (e.target.checked) {
      setCall_recorded((current) => current + 1);
      // console.log("✅ Checkbox is checked");
    } else {
      setCall_recorded((current) => current - 1);
      // console.log("⛔️ Checkbox is NOT checked");
    }
    setIsSubscribed((current) => !current);
  }, []);

  const handleInputChangeCompany = React.useCallback((e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  }, [inputList]);

  const handleInputChangeCountry = React.useCallback((e, index) => {
    const { id, value } = e.target;
    const list = [...selectList];
    list[index][id] = value;
    setSelectList(list);
  }, [selectList]);


  const checkEmail = React.useCallback((email) => {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      setMessage("");
    } else {
      return setMessage("Email is invalid");
    }
  }, []);


  const handleInputChangeEmail = React.useCallback((e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    checkEmail(list[index].email);
    setEmailList(list);
  }, [checkEmail, emailList]);


  const handleRemoveClick = React.useCallback((data, index) => {
    if (data === "input") {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    } else if (data === "select") {
      const list = [...selectList];
      list.splice(index, 1);
      setSelectList(list);
    } else if (data === "email") {
      const list = [...emailList];
      list.splice(index, 1);
      setEmailList(list);
    }
  }, [emailList, inputList, selectList]);

  // handle click event of the Add button
  const handleAddClick = React.useCallback((data) => {
    if (data === "input") {
      setInputList([...inputList, { company: "" }]);
    } else if (data === "select") {
      setSelectList([...selectList, { country: "" }]);
    } else if (data === "email") {
      setEmailList([...emailList, { email: "" }]);
    }
  }, [emailList, inputList, selectList]);

  const handleSubmit = React.useCallback(async (e) => {
    e.preventDefault();
    setSpinner(true);

    let countryData = [];
    let companyData = [];
    let emailData = [];
    if (loginDetails?.user?.email) {
      emailData?.push(loginDetails?.user?.email?.split("@")[0]);
    }

    for (var Country in selectList) {
      countryData?.push(selectList[Country]?.country);
    }

    for (var Company in inputList) {
      companyData?.push(inputList[Company]?.company);
    }

    for (var Email in emailList) {
      if (
        emailList[Email]?.email !== emailData[0] &&
        emailList[Email]?.email !== ""
      ) {
        emailData?.push(emailList[Email]?.email);
      }
    }

    let payload = {
      project_title: projectDetails?.project_title,
      user_id: loginDetails?.user?._id,
      invite_team: emailData?.join(","),
      country: countryData[0],
      target_companies: companyData?.join(","),
      question: value,
      project_deadline: startDate?.toISOString(), // Convert to ISO string
      expected_calls: projectDetails?.expected_calls,
    };
    if (
      payload.project_title === "" ||
      payload.expected_calls === "" ||
      payload.question === "" ||
      payload.project_deadline === ""
    ) {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      <></>;
    }

    try {
      const res = await fetch(`${API_PATH?.CREATE_PROJECT}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (res.ok) {
        const result = await res.json();
        if (
          result?.status === true &&
          result?.message === "Insert successfully."
        ) {
          setSpinner(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return toast.success("Project created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        return toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return toast.error("Something went wrong. Server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [emailList, headers, inputList, loginDetails?.user?._id, loginDetails?.user?.email, projectDetails?.expected_calls, projectDetails?.project_title, selectList, startDate, value])

  return (
    <>
      <div
        className="modal fade pro-jects1 bd-example-modal-lg"
        id="createProjectModal1"
        tabIndex="-1"
        aria-labelledby="createProjectModal1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-ps-modal-1 ">
                <h2 className="text-center"> Create New Project </h2>
                <p
                  className="text-center mt-4"
                  style={{ color: "#526fd7", fontSize: "20px !important" }}
                >
                  {" "}
                  Detail out your requirement to launch a custom recruitment
                  request{" "}
                </p>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        {" "}
                        Project Title *{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="project_title"
                        onChange={handelChange}
                        ref={inputRef}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        Number of Expected Calls *
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="expected_calls"
                        onChange={handelChange}
                        ref={inputRef2}
                      >
                        <option value>Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        Project Deadline *
                      </label>
                      <DatePicker
                        style={{ cursor: "default" }}
                        inputProps={{ readOnly: true }}
                        className="form-select"
                        minDate={new Date()}
                        selected={startDate}
                        placeholderText="MM/DD/YYYY"
                        onChange={setStartDate}
                        dateFormat="dd/MM/yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-2">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label d-flex"
                        style={{ color: "black", fontWeight: "500" }}
                      >
                        Geography of experts
                      </label>

                      {selectList.map((x, i) => {
                        return (
                          <div
                            className="box"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                              width: "100%"
                            }}
                            key={i}
                          >
                            <Select
                              style={{
                                borderRadius: "10px",
                                border: "1px solid rgb(210,210,210)",
                                marginRight: "8px",
                              }}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              options={countryList}
                              placeholder="Select Country"
                              onChange={(e) =>
                                handleInputChangeCountry(
                                  {
                                    target: {
                                      value: e?.value,
                                      id: "country",
                                    },
                                  },
                                  i
                                )
                              }
                              ref={inputRef5}
                            />

                            <div className="btn-box">
                              {selectList.length !== 1 && (
                                <DangerousOutlinedIcon
                                  className="mr10"
                                  onClick={() =>
                                    handleRemoveClick("select", i)
                                  }
                                />
                              )}
                              {selectList.length - 1 === i && (
                                <AddCircleOutlineIcon
                                  onClick={() => handleAddClick("select")}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className="form-label d-flex"
                        style={{ color: "black", fontWeight: "500" }}
                      >
                        Target Companies
                      </label>
                      {inputList.map((x, i) => {
                        return (
                          <div
                            className="box"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                              width: "100%"
                            }}
                            key={i}
                          >
                            <input
                              style={{
                                padding: "1vh",
                                borderRadius: "10px",
                                border: "1px solid rgb(210,210,210)",
                                width: "100%",
                                marginRight: "1vh",
                              }}
                              name="company"
                              placeholder="Google"
                              value={x.company}
                              onChange={(e) => handleInputChangeCompany(e, i)}
                              ref={inputRef6}
                            />

                            <div className="btn-box">
                              {inputList.length !== 1 && (
                                <DangerousOutlinedIcon
                                  className="mr10"
                                  onClick={() =>
                                    handleRemoveClick("input", i)
                                  }
                                />
                              )}
                              {inputList.length - 1 === i && (
                                <AddCircleOutlineIcon
                                  onClick={() => handleAddClick("input")}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="form-label d-flex"
                        style={{ color: "black", fontWeight: "500" }}
                      >
                        Invite your team (Optional){" "}
                      </label>
                      {emailList.map((x, i) => {
                        return (
                          <div
                            className="box"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                            }}
                            key={i}
                          >
                            <div className="col-sm-11 my-1">
                              <label
                                className="sr-only"
                                htmlFor="inlineFormInputGroupUsername"
                              >
                                Enter Email
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inlineFormInputGroupUsername"
                                  placeholder="Enter Email"
                                  name="email"
                                  value={x.email}
                                  onChange={(e) =>
                                    handleInputChangeEmail(e, i)
                                  }
                                  ref={inputRef7}
                                />
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    {"@" +
                                      loginDetails?.user?.email?.split(
                                        "@"
                                      )[1]}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="btn-box">
                              {emailList.length !== 1 && (
                                <DangerousOutlinedIcon
                                  className="mr10"
                                  onClick={() =>
                                    handleRemoveClick("email", i)
                                  }
                                />
                              )}
                              {emailList.length - 1 === i && (
                                <AddCircleOutlineIcon
                                  onClick={() => handleAddClick("email")}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                      {message === "" ? (
                        <></>
                      ) : (
                        <p className="text-danger">{message}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        {" "}
                        Project Overview *{" "}
                      </label>
                      <div className="mainl">
                        <div className="">
                          <Editor
                            apiKey="4zsbw146cia5a0qmlr6rucoz820ppv27r55qepau8vvaz016"
                            initialValue=""
                            branding={false}
                            setValue={setValue}
                            value={value}
                            id="notesEditor"
                            onEditorChange={handleEditorChange}
                            init={{
                              height: 233,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | ",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="radio2">
                    <Checkbox
                      className="me-2"
                      onChange={handelChecked}
                      value={isSubscribed}
                      name="call_recorded"
                      style={{ color: "#526fd7" }}
                      required
                    ></Checkbox>

                    <label style={{ color: "#526fd7" }}>
                      Request calls to be recorded
                    </label>
                  </div>
                  <div className="d-grid gap-2 col-10 mx-auto py-4">

                    <Button
                      id="closebtn"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        outline: "none",
                        textTransform: "inherit",
                      }}
                      className="btn col-lg-5 mx-auto btnsm"
                      type="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        to={""}
        className="btn viewButton"
        data-bs-toggle="modal"
        data-bs-target="#createProjectModal1"
      >
        {" "}
        Create Project{" "}
      </button>{" "}
      {loading1 ? (
        <>
          {allDatas > 10 ? (
            <div className="pagination-background">
              <Pagination1
                activePage={currentPage}
                itemsCountPerPage={10}
                totalItemsCount={allDatas}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemclassName="page-item"
                linkclassName="page-link"
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
