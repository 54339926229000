import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";

function LoginForm() {
  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const history = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("userData"));

  const [isAuth, setIsAuth] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [userData, setValue] = useState({
    password: "",
    email: "",
    registrationType: "client"
  });

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setValue({ ...userData, [key]: value });
  };

  const [toastClosed, setToastClosed] = useState(false);
  const [toastClosedInvalid, setToastClosedInvalid] = useState(false);
  const [toastShown, setToastShown] = useState(false);

  const handleClick = () => {
    if (!toastClosed) {
      toast("This is a toast message!", {
        onClose: () => setToastClosed(true),
      });
    }
  };

  useEffect(() => {
    if (isAuth) {
      let redirectPath = localStorage.getItem("redirectPath");
      if (redirectPath) {
        localStorage.removeItem("redirectPath");
        history(redirectPath);
      } else {
        history("/dashboard");
        setTimeout(() => {
          return toast.success("Login Successful!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }, 100);
      }
    }
  }, [isAuth]);

  const loginUser = async (e) => {
    e.preventDefault();
    //console.log("here");
    setIsAuth(false);
    if (userData.email !== "" && userData.password !== "") {
      setSpinner(true);
      try {
        const res = await fetch(`${API_PATH.LOGINURL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });

        const result = await res.json();
        console.log(result, "result");
        if (result) {
          setSpinner(false);
          if (result.status === 200) {
            result.user = JSON.parse(atob(result.user));
            result.access_token = atob(result.access_token);
            result.refresh_token = atob(result.refresh_token);

            localStorage.setItem("userData", JSON.stringify(result));
            //       localStorage.setItem('token', result.token);
            // localStorage.setItem('tokenExpirationDate', result.tokenExpirationDate);
            console.log(result?.user?.passString, "passString");
            if (result.user.passString) {
              history("/reset-password");
            } else {
              setIsAuth(true);
              localStorage.setItem("isAuth", true);
              localStorage.setItem(
                "ChangePassword",
                JSON.stringify(userData.password)
              );
              history("/dashboard");
            }
          } else {
            // const res1 = await fetch(`${API_PATH.SIGNUPURL}`, {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   body: JSON.stringify(userData),
            // });
            // const result1 = await res1.json();
            // if (result1.data) {
            //   let payload = {
            //     username: result1.data.name,
            //     company: result1.data.company,
            //     contactNumber: result1.data.contact_number,
            //     email: result1.data.email,
            //     password: userData.password,
            //     contactCode: result1.data.contact_code,
            //     country: result1.data.country,
            //     role_id: "629862f8f12b176d0ef72c7b",
            //   };

            //   const res2 = await fetch(`${API_PATH.USER_MANAGEMENT_POST}`, {
            //     method: "POST",
            //     headers: {
            //       "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify(payload),
            //   });
            //   const result2 = await res2.json();
            //   if (result2.status === 200) {
            //     loginUser();
            //   }
            // }
            setSpinner(false);
            // if (!toastClosedInvalid) {
            return toast.error("Invalid credentials", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              // onClose: () => setToastClosedInvalid(true),
            });
            // }
          }
        } else {
          // console.log("fdddddddddddddddddddddfdf");
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      // if (!toastClosed) {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        onClose: () => setToastClosed(true),
      });
      // }
    }
  };

  // useEffect(() => {
  //   if (isAuth) {
  //     history("/projects");
  //     setIsAuth(false);
  //   }
  // }, [isAuth]);

  const something = (event) => {
    if (event.keyCode === 13) {
      loginUser(event);
    }
  };

  return (
    <>
      <Form autoComplete="off">
        <div className="form-group">
          <label> Business Email* </label>
          <input
            type="text"
            name="email"
            onChange={handleInputs}
            className="form-control"
            autoComplete="nope"
            placeholder="Enter Your Business Email"
          />
        </div>

        <div className="form-group">
          <label> Password* </label>
          <div className="lable-input">
            <input
              name="password"
              onChange={handleInputs}
              id="password-field"
              type={InputType}
              className="form-control"
              autoComplete="nope"
              onKeyDown={(e) => something(e)}
              placeholder="Password"
            />
            <span onClick={() => setVisible((visibilty) => !visibilty)}>
              {visible ? (
                <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
              ) : (
                <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
              )}
            </span>
          </div>
          <NavLink
            className="d-flex justify-content-end"
            to="/forgot-password"
            style={{ textDecoration: "none" }}
          >
            <small style={{ color: "#523EE8" }}>Forgot Password?</small>
          </NavLink>
        </div>
        <div className="form-group">
          <Button onClick={loginUser} className="btn login-btn">
            {spinner ? (
              <>
                <Spinner
                  size="sm"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "5px",
                  }}
                />
                Login
              </>
            ) : (
              "Login"
            )}
          </Button>
        </div>
      </Form>

      <div className="form-group">
        <p className="text-center">
          <NavLink to="/demo" className="register-btn px-2">
            Request Demo
          </NavLink>

          {/* <button onClick={handleClick}>Show Toast</button> */}
        </p>
      </div>
    </>
  );
}
export default LoginForm;
