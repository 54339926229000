import Stack from "@mui/material/Stack";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../Constants/api-path";

const StarredButton = ({ datas }) => {
  const [isStarr, setIsstar] = useState(false);
  let clientDatas = JSON.parse(localStorage.getItem("userData"));
  console.log(isStarr, "isStarr");
  console.log(datas, "datas-->");

  useEffect(() => {
    setIsstar(datas?.isStarred);
  }, [isStarr]);

  const handelOneExpertStarred = async () => {
    var payload = {
      expertId: datas?._id ? datas?._id : datas?._id,
      userId: clientDatas?.user?._id,
    };
    try {
      const res = await fetch(`${API_PATH.ONE_EXPERT_STARRED}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": clientDatas.access_token,
        },
        body: JSON.stringify(payload),
      });
      const result = await res.json();
      if (result) {
        // window.location.reload();
        datas.isStarred = !isStarr;
        setIsstar(!isStarr);
        return toast.success(result.msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handelOneExpertStarredCancel = async () => {
    var payload = {
      expertId: datas?._id ? datas?._id : datas?._id,
      userId: clientDatas?.user?._id,
    };
    try {
      const res = await fetch(`${API_PATH.ONE_EXPERT_STARRED}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": clientDatas.access_token,
        },
        body: JSON.stringify(payload),
      });
      const result = await res.json();
      if (result) {
        window.location.reload();
        return toast.success(result.msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Tooltip
          placement="leftTop"
        // title={isStarr ? "Unmarked as favourite" : "Mark as favourite"}
        >
          <button
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              padding: "0",
            }}
            onClick={handelOneExpertStarred}
          >
            {isStarr === true ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"
                  fill="rgba(87,70,236,1)"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26zm0-2.292l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.949 4.773L12 15.968z"
                  fill="rgba(87,70,236,1)"
                />
              </svg>
            )}
          </button>
        </Tooltip>
      </Stack>
    </>
  );
};

export default StarredButton;
