import { css } from "@emotion/css";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown, FormControl, InputGroup } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../Constants/api-path";
import Sidebar from "../Sidebar";
import { BsChevronLeft, Calendar, Timer } from "../Utils/Icons";
import { Button, Drawer, Collapse, Input, Spin, Select, Menu } from "antd";
import $ from "jquery";
import moment from "moment";
import TopMenu from "../components/TopMenu";
import ReactPlayer from "react-player";
import { Howl, Howler } from "howler";
import {
  BsPlayCircleFill,
  BsPauseCircleFill,
  BsSkipStartFill,
  BsSkipEndFill,
  BsVolumeUp,
  BsVolumeMute,
  BsFillRewindFill,
  BsFillFastForwardFill,
} from "react-icons/bs";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./CollaborateSearchDetails.css";
import "rc-slider/assets/index.css";
import {
  SearchOutlined,
  CloseOutlined,
  CalendarOutlined,
  MenuOutlined,
} from "@ant-design/icons";

const { Panel } = Collapse;

const ROOT_CSS = css({
  height: 430,
});
const TranscriptDetail = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [recordingData, setRecordingData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [highlight, setHighlight] = useState(false);
  const [isSearchType, setIsSearchType] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [userEmail, setEmail] = useState("");
  const audioRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [highlightText, setHighlightText] = useState("");
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState(0);
  const [occurrencesLength, setOccurrencesLength] = useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [activePanels, setActivePanels] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [sound, setSound] = useState(null);
  const [duration, setDuration] = useState(0);
  const [updateSliderInterval, setUpdateSliderInterval] = useState(null);
  const manuallySetTimeRef = useRef(false);
  const [scrolledId, setScrolledId] = useState([]);
  const [timestampData, setTimestampData] = useState([]);
  const [currentTimestampIndex, setCurrentTimestampIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropdownItems, setDropdownItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const audioPlayerRef = useRef();

  useEffect(() => {
    const handleScroll = (event) => {
      event.stopPropagation();
    };
    const audioPlayer = audioPlayerRef.current;
    audioPlayer?.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      audioPlayer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Update handlePlay and handlePause functions
  const handlePlay = () => {
    if (sound) {
      // Play only if it's not already playing
      if (!sound?.playing()) {
        const seekPosition = manuallySetTimeRef.current
          ? currentTime
          : sound.seek(); // Use the stored currentTime when available

        sound?.seek(seekPosition);
        sound?.play();
        setIsPlaying(true);
      }
    }
  };

  const handlePause = () => {
    if (sound) {
      setCurrentTime(sound.seek()); // Update currentTime when paused
      manuallySetTimeRef.current = true;
      sound?.pause();
      setIsPlaying(false);
    }
  };

  const handleSeek = (time) => {
    if (sound) {
      sound.seek(time);
      setCurrentTime(time);
      manuallySetTimeRef.current = true;
    }
  };

  // Update handleSliderChange and handleSliderAfterChange functions
  const handleSliderChange = (value) => {
    setCurrentTime(value);
    if (sound && !sound.playing()) {
      sound.play(); // Play the audio when the slider is moved
    }
    sound.seek(value);

    // Scroll to the highlighted text when the slider is moved
    scrollToHighlight(`txt_${formatTimeForJump(value)}`);
  };

  const handleSliderAfterChange = (value) => {
    if (sound && sound.playing()) {
      setIsPlaying(true);
      highlightTimestamp(value);

      // Scroll to the highlighted text after the slider has been released
      scrollToHighlight(`txt_${formatTimeForJump(value)}`);
    }
  };

  const handleRewind = () => {
    const newTime = currentTime - 5;
    handleSeek(newTime < 0 ? 0 : newTime);
  };

  const handleForward = () => {
    const newTime = currentTime + 5;
    handleSeek(newTime > duration ? duration : newTime);
  };

  const loadAudio = async () => {
    if (
      recordingData &&
      recordingData.recording_files &&
      recordingData.recording_files[0] &&
      recordingData.recording_files[0].s3_download_url
    ) {
      const newSound = new Howl({
        src: [recordingData.recording_files[0].s3_download_url],
        volume: volume,
        html5: true,
        onload: () => {
          setDuration(newSound.duration());
          setLoading(false);
        },
        onplay: () => {
          Howler.ctx.resume();
          setIsPlaying(true);
          const updateSliderInterval = setInterval(() => {
            const newTime = newSound.seek();
            if (Math.abs(newTime - currentTime) > 1) {
              setCurrentTime(newTime);
            }
          }, 250); // Update every 250 milliseconds

          setUpdateSliderInterval(updateSliderInterval);
        },
        onpause: () => {
          setIsPlaying(false);
          clearInterval(updateSliderInterval);
        },
        onend: () => {
          setCurrentTime(0);
          setIsPlaying(false);
          clearInterval(updateSliderInterval);
        },
      });

      setSound(newSound);

      await new Promise((resolve) => {
        newSound.load(() => {
          resolve();
        });
      });

      setSound(newSound);
    }
  };

  useEffect(() => {
    loadAudio();
    return () => {
      if (sound) {
        sound.stop();
      } else {
        console.log("No sound instance found");
      }
    };
  }, [recordingData, volume]);

  // useEffect(() => {
  //   if (sound) {
  //     sound.unload();
  //   }
  //   if (
  //     recordingData &&
  //     recordingData?.recording_files &&
  //     recordingData?.recording_files[0] &&
  //     recordingData?.recording_files[0]?.s3_download_url
  //   ) {
  //     const newSound = new Howl({
  //       src: [recordingData?.recording_files[0]?.s3_download_url],
  //       volume: volume,
  //       onload: () => {
  //         setDuration(newSound.duration());
  //       },
  //       onplay: () => {
  //         Howler.ctx.resume();
  //         setIsPlaying(true);

  //         // Periodically update the slider while playing
  //         const updateSliderInterval = setInterval(() => {
  //           setCurrentTime(newSound.seek());
  //         }, 1000); // Update every second (adjust the interval as needed)

  //         setUpdateSliderInterval(updateSliderInterval); // Save the interval ID
  //       },
  //       onpause: () => {
  //         setIsPlaying(false);
  //         clearInterval(updateSliderInterval);
  //       },
  //       onend: () => {
  //         setCurrentTime(0);
  //         setIsPlaying(false);
  //         clearInterval(updateSliderInterval);
  //       },
  //     });

  //     setSound(newSound);

  //     return () => {
  //       newSound.unload();
  //     };
  //   }
  // }, [recordingData, volume]);

  const createHighlighted = (value) => {
    const formattedTime = formatTimeForJump(value);
    manuallySetTimeRef.current = true;
    setCurrentTime(formattedTime);

    if (sound) {
      sound.seek(formattedTime);
      // Check if the sound is not already playing
      // if (!sound.playing()) {
      //   sound.play();
      //   setIsPlaying(true);
      // }
    }
    scrollToHighlight(`txt_${formattedTime}`);
  };

  // useEffect(() => {
  let interval;

  if (isPlaying && !manuallySetTimeRef.current) {
    interval = setInterval(() => {
      setCurrentTime(sound.seek());
      highlightTimestamp(sound.seek());
    }, 1000);
  } else {
    clearInterval(interval);
    manuallySetTimeRef.current = false;
  }

  useEffect(() => {
    return () => {
      if (sound) {
        sound.off(); // Remove all event listeners
        sound.unload(); // Unload the sound
      }
    };
  }, [sound]);

  const wordPara = document.getElementsByClassName("wordPara");
  const highlightTimestamp = (currentTime) => {
    if (sound.playing()) {
      $(".words").removeClass("highlight");
      for (var i = 0; i < wordPara.length; i++) {
        var startTime = Number(wordPara[i].getAttribute("id"));
        var endTime = Number(wordPara[i].getAttribute("data-end"));
        var current_timeMS = currentTime * 1000;
        if (startTime < current_timeMS && endTime > current_timeMS) {
          var speaker_wrapper = wordPara[i]?.childNodes;
          var wordWrapper = speaker_wrapper[1]?.childNodes;
          var words = wordWrapper[1]?.childNodes;
          for (var j = 0; j < words.length; j++) {
            const time = Number(words[j]?.id?.slice(4));
            if (time < current_timeMS) {
              if (j > 0) {
                words[j - 1].classList.remove("highlight");
              }
              words[j].classList.add("highlight");
            }
          }

          var paraId = document.getElementById(wordPara[i].id);
          if (paraId) {
            // setScrolledId([...scrolledId, wordPara[i]?.id]);
            // scrollToHighlight(wordPara[i].id);
            paraId.scrollIntoView({
              behavior: "auto",
              block: "start",
            });
          }
        }
      }
    }
  };

  let occurrences = [];

  // function createHighlighted(el) {
  //   audioRef.current.setJumpTime = el;
  // }

  const handlePanelClick = (panelKey) => {
    setActivePanels((prevActivePanels) => {
      if (prevActivePanels.includes(panelKey)) {
        return prevActivePanels.filter((key) => key !== panelKey);
      } else {
        return [panelKey];
      }
    });
  };

  const [opens, setOpens] = useState(false);
  const showDrawer = () => {
    setOpens(!opens);
  };
  const onClose = () => {
    setOpens(false);
  };

  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
  });

  const speakerColor = {
    A: "rgb(254, 86, 91)",
    B: "rgb(254, 227, 111)",
    C: "#126fd6",
    D: "rgb(0, 127, 170)",
    E: "#191970	",
    F: "#2F4F4F",
  };

  const getRecordingDetails = async () => {
    try {
      const res = await fetch(
        `${API_PATH.MEETING_ID_GET}/${searchParams.get("meetingId")}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setRecordingData(result?.data);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString} - ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  function getTimeDifference(start, end) {
    if (start && end) {
      var timeDiff = moment(end).diff(moment(start));
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      hh = ("0" + hh).slice(-2);

      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);

      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ("0" + ss).slice(-2);

      var total = hh + ":" + mm + ":" + ss;
      return total;
    }
  }

  useEffect(() => {
    getRecordingDetails();
  }, []);

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  const updatePermission = async (permission, email) => {
    if (email !== "") {
      try {
        const res = await fetch(
          `${API_PATH.MEETING_ID_UPDATE}/${searchParams.get("meetingId")}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({ permission: permission, email: email }),
          }
        );

        const result = await res.json();
        if (result) {
          getRecordingDetails();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      // return toast.error("Please fill the email", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
    }
  };

  const stopSharing = async (e) => {
    e.preventDefault();

    try {
      let payload = {};
      payload.permittedUsers = [];
      const res = await fetch(
        `${API_PATH.MEETING_ID_UPDATE}/${searchParams.get("meetingId")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await res.json();
      if (result) {
        getRecordingDetails();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setPayload({ ...payload, [key]: value });
  };

  function closeHightlightedText() {
    setHighlight(false);
    setIsSearchType(true);
    setHighlightText("");
    const scriptBody = document.querySelectorAll("#scriptBody .words");
    scriptBody.forEach(function (p, index) {
      p.innerHTML = p.textContent;
    });
    setSearchKeyword("");
    setDropdownActive(false);
  }

  const getPUserList = () => {
    if (recordingData.permittedUsers instanceof Array) {
      return recordingData.permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, permission, username } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                      alt={email}
                    />
                  </div>
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">
                        {firstNmae ? firstNmae : username}
                      </span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  // highlight text when click on highlighted text
  function searchHighlights(txt) {
    clearHighlights();

    occurrences = [];
    var text = txt?.split(" ");
    const scriptBody = document.querySelectorAll("#scriptBody .words");
    let regExp = new RegExp(txt, "gi");
    setIsSearchType(false);
    setHighlight(true);
    handlePause();

    // scriptBody.forEach(function (p) {
    //   p.innerHTML = p.textContent;
    // });

    setCurrent(1);

    setTimeout(function () {
      setHighlightText(txt);
    }, 400);

    text.forEach(function (tx, de) {
      let regExp = new RegExp(tx, "gi");
      scriptBody.forEach(function (p, index) {
        if (p.textContent.match(regExp)) {
          var matches = p.textContent.match(regExp);
          matches.forEach(function (match, n) {
            occurrences.push(index);
          });
          p.innerHTML = p.textContent.replace(regExp, "<mark>$&</mark>");
        }
      });
    });
    displayOcc();
  }

  function clearHighlights() {
    let highlightedElements = document.querySelectorAll("#scriptBody mark");
    highlightedElements.forEach((el) => {
      el.outerHTML = el.innerHTML;
    });
  }
  function scrollToHighlight(id) {
    let pickHighlights = document.getElementById(id);
    if (pickHighlights) {
      var viewportOffset = pickHighlights.getBoundingClientRect();
      // these are relative to the viewport
      var top = viewportOffset.top;

      var wndw = document.querySelector("#messagesEndRef .mainComment2");

      wndw.scrollTo({ top: wndw.scrollTop + top - 300 });
    }
  }

  function selectMarkedAsSelected(crrn, ln = null) {
    let aln = ln !== null ? ln : occurrencesLength;
    if (crrn > 0 && crrn <= aln) {
      let mark = document.querySelectorAll("#scriptBody mark");
      mark.forEach((mrk) => {
        mrk.classList.remove("selected");
      });

      // Highlight all occurrences, not just the current one
      mark.forEach((mrk, index) => {
        if (index >= crrn - 1 && index < crrn - 1 + aln) {
          mrk.classList.add("selected");
        }
      });

      scrollToHighlight(crrn - 1);
      setCurrent(crrn);
    }
  }

  function displayOcc() {
    let lengthOccurrences = occurrences.length;
    setOccurrencesLength(lengthOccurrences);
    let currViewMatch = current;
    currViewMatch = currViewMatch > 0 ? currViewMatch : 0;
    currViewMatch = currViewMatch > lengthOccurrences ? 1 : currViewMatch;
    currViewMatch =
      currViewMatch == 0 && lengthOccurrences > 0 ? 1 : currViewMatch;

    let insertNbrOcc =
      lengthOccurrences > 0 ? " of " + lengthOccurrences : " matches found";

    setCount(insertNbrOcc);
    setCurrent(currViewMatch);
    selectMarkedAsSelected(currViewMatch, lengthOccurrences);
  }

  function selectMarkedAsSelected(crrn, ln = null) {
    let aln = ln !== null ? ln : occurrencesLength;
    if (crrn > 0 && crrn <= aln) {
      let mark = document.querySelectorAll("#scriptBody mark");
      mark.forEach((mrk) => {
        mrk.classList.remove("selected");
      });

      // Highlight all occurrences, not just the current one
      // mark.forEach((mrk, index) => {
      //   if (index >= crrn - 1 && index < crrn - 1 + aln) {
      //     mrk.classList.add("selected");
      //   }
      // });
      mark[crrn - 1].classList.add("selected");
      var selected = $("mark.selected").parent(".words").attr("id");
      handlePause();
      const formattedTime = formatTimeForJump(Number(selected?.slice(4)));
      setCurrentTime(formattedTime);
      setIsPlaying(false);
      scrollToHighlight(selected);
      setCurrent(crrn);
    }
  }

  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const formatTimeForJump = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    return `${String(seconds).padStart(2, "0")}`;
  };

  function convertMS(time) {
    var hour = "";
    var min = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      sec = Math.floor((time / 1000) % 60);
    if (hours) {
      hour = `${doubleDigit(hours)}:`;
    }
    return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
  }

  function logTimestamps(timestamps) {
    const newTimestamps = timestamps.map(({ end }) => {
      return end;
    });

    setTimestampData(newTimestamps);
    setCurrentTimestampIndex(0);
    createHighlighted(newTimestamps[0]);
  }

  useEffect(() => {}, [timestampData]);
  const handleNextTimestamp = () => {
    if (currentTimestampIndex < timestampData.length - 1) {
      const nextIndex = currentTimestampIndex + 1;
      setCurrentTimestampIndex(nextIndex);
      createHighlighted(timestampData[nextIndex]);
    }
  };

  const handlePrevTimestamp = () => {
    if (currentTimestampIndex > 0) {
      const prevIndex = currentTimestampIndex - 1;
      setCurrentTimestampIndex(prevIndex);
      createHighlighted(timestampData[prevIndex]);
    }
  };

  const handleDropdownSelect = (selectedItem) => {
    setSearchActive(false);
  };

  const CustomDropdown = ({ items, onSelect, searchKeyword }) => {
    return (
      <>
        <div
          className="custom-dropdown"
          onClick={() => {
            searchHighlights(searchKeyword);
          }}
        >
          {items.length > 0 ? (
            <div>
              {items.map((item, index) => (
                <div key={index} className="ng-star-inserted">
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{
                      __html: item.txt.replace(
                        new RegExp(searchKeyword, "gi"),
                        (match) =>
                          `<span style="background-color: yellow;">${match}</span>`
                      ),
                    }}
                  ></p>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-results-message ng-star-inserted d-flex justify-conteny-center align-items-center text-center">
              <p className="para text-center">No result</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const stringToHtml = (para, txt) => {
    var pars = document.createElement("p");
    pars.classList.add("para");
    pars.innerHTML = pars.textContent.replace(txt, "<mark>$&</mark>");
    return pars;
  };

  function searchInputSearchs() {
    let val = document.getElementById("searchResults").value;
    if (val) {
      setSearchKeyword(val);
      const scriptBody = document.querySelectorAll("#scriptBody p");
      let regExp = new RegExp(val, "gi");
      setDropdownActive(true);
      occurrences = [];
      let parArr = [];
      scriptBody.forEach(function (p, index) {
        if (p.textContent.match(regExp)) {
          var matches = p.textContent.match(regExp);
          matches.forEach(function (match, n) {
            occurrences.push(index);
          });
          // var newTxt = p.innerHTML.replace(regExp, "<mark>$&</mark>");
          // let createP = document.createElement("p");
          // createP.innerHTML = newTxt;
          parArr.push({ txt: p.textContent, reg: regExp });
        }
      });

      setDropdownItems(parArr);
    }
  }

  const inActiveSearch = () => {
    setSearchActive(false);
    setDropdownActive(false);
    setDropdownItems([]);
  };

  const handleClear = () => {
    setSearchKeyword("");
    setDropdownActive(false);
    setCurrent(1);
    document.querySelector("#messagesEndRef .mainComment2").scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function replaceTxt(txt, para) {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }

  document.title = "Client | Call Recordings";

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div> */}

            <div className="w-100">
              <div
                className="body-expart-div request-page projects-scope"
                style={{ paddingTop: "0px" }}
              >
                <span className="sp-hr w-100" />

                <div
                  className="projects-details-page details-message-part"
                  style={{ marginBottom: "20px" }}
                >
                  {/* <div>Current Timestamp: {getCurrentTimestamp()}</div> */}
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h3
                      className="d-flex align-items-center"
                      style={{ fontSize: "24px" }}
                    >
                      <Link to={"/call_recordings"}>
                        <BsChevronLeft className="search-btn-back me-3" />
                      </Link>
                      Recording & Transcript
                    </h3>
                    <div className="d-flex align-items-center">
                      {isSearchType ? (
                        <div
                          className={
                            "me-3 searchIconRecording active position-relative"
                          }
                        >
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="Search"
                            className="searchInput"
                            id="searchResults"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchInputSearchs();
                              }
                            }}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            // onClick={() => setSearchActive(true)}
                            // onBlur={() => setSearchActive(false)}
                            value={searchKeyword}
                            prefix={
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M10.25 2a8.25 8.25 0 0 1 6.34 13.53l5.69 5.69a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-5.69-5.69A8.25 8.25 0 1 1 10.25 2ZM3.5 10.25a6.75 6.75 0 1 0 13.5 0 6.75 6.75 0 0 0-13.5 0Z"></path>
                              </svg>
                            }
                            addonAfter={
                              searchActive && (
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-width="0"
                                  viewBox="0 0 15 15"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => {
                                    // setSearchActive(false);
                                    handleClear();
                                  }}
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              )
                            }
                          />
                          {dropdownActive && (
                            <CustomDropdown
                              items={dropdownItems}
                              onSelect={handleDropdownSelect}
                              searchKeyword={searchKeyword}
                            />
                          )}
                        </div>
                      ) : (
                        <div
                          className={
                            highlight
                              ? "me-3 searchIconRecording position-relative active"
                              : "me-3 searchIconRecording position-relative"
                          }
                        >
                          <span className="ant-input-group-wrapper ant-input-group-wrapper-lg">
                            <span className="ant-input-wrapper ant-input-group">
                              <span className="ant-input-affix-wrapper ant-input-affix-wrapper-focused ant-input-affix-wrapper-lg">
                                <span className="ant-input-prefix">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10.25 2a8.25 8.25 0 0 1 6.34 13.53l5.69 5.69a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-5.69-5.69A8.25 8.25 0 1 1 10.25 2ZM3.5 10.25a6.75 6.75 0 1 0 13.5 0 6.75 6.75 0 0 0-13.5 0Z"></path>
                                  </svg>
                                </span>
                                <span className="ant-input ant-input-lg">
                                  <div>{highlightText}</div>
                                </span>
                              </span>
                              <span className="ant-input-group-addon">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center highlightedControl me-3">
                                    <span
                                      className="me-2"
                                      onClick={() => {
                                        selectMarkedAsSelected(current - 1);
                                        handlePrevTimestamp();
                                      }}
                                    >
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        stroke-width="0"
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M8.2 751.4c0 8.6 3.4 17.401 10 24.001 13.2 13.2 34.8 13.2 48 0l451.8-451.8 445.2 445.2c13.2 13.2 34.8 13.2 48 0s13.2-34.8 0-48L542 251.401c-13.2-13.2-34.8-13.2-48 0l-475.8 475.8c-6.8 6.8-10 15.4-10 24.2z"></path>
                                      </svg>
                                    </span>
                                    <span className="me-2">
                                      {current}
                                      {count}
                                    </span>
                                    <span
                                      onClick={() => {
                                        selectMarkedAsSelected(current + 1);
                                        handleNextTimestamp();
                                      }}
                                    >
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        stroke-width="0"
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M8.2 275.4c0-8.6 3.4-17.401 10-24.001 13.2-13.2 34.8-13.2 48 0l451.8 451.8 445.2-445.2c13.2-13.2 34.8-13.2 48 0s13.2 34.8 0 48L542 775.399c-13.2 13.2-34.8 13.2-48 0l-475.8-475.8c-6.8-6.8-10-15.4-10-24.199z"></path>
                                      </svg>
                                    </span>
                                  </div>
                                  <svg
                                    onClick={() => {
                                      closeHightlightedText();
                                      handleClear();
                                    }}
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-width="0"
                                    viewBox="0 0 15 15"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </span>
                            </span>
                          </span>
                        </div>
                      )}
                      <img
                        src="/images/share.png"
                        width="18px"
                        alt="png"
                        // type="button"
                        to="#"
                        className="me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal2"
                        style={{ cursor: "pointer" }}
                      />{" "}
                      {/* <button
                        style={{
                          // backgroundColor: "rgb(0, 168, 45)",
                          color: "#ffffff",
                          width: "120px",
                        }}
                        type="button"
                        to="#"
                        className="one-ex-btn btn me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal2"
                      >
                        Share
                      </button> */}
                      <MenuOutlined onClick={showDrawer} />
                      {/* <svg
                        onClick={showDrawer}
                        className="diamod-btn"
                        width="100%"
                        height="100%"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        fit=""
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                      >
                        <path
                          d="M4.55556 1H17.254L20.5556 5.8254L10.9048 17L1 5.8254L4.55556 1Z"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M1.25391 5.8254L15.7301 5.8254M20.5555 5.8254L15.7301 5.8254M12.9364 1L15.7301 5.8254M15.7301 5.8254L10.9047 16.746"
                          stroke="currentColor"
                          stroke-width="1.25"
                        ></path>
                        <path
                          d="M8.87171 1L6.33203 5.8254L10.9035 16.746"
                          stroke="currentColor"
                          stroke-width="1.25"
                        ></path>
                      </svg> */}
                      <Drawer
                        title="Summary"
                        placement="right"
                        width={500}
                        style={{ padding: 0 }}
                        className="p-0 drawer-transcript"
                      >
                        <div style={{ display: "flex" }}>
                          <Collapse
                            bordered={false}
                            defaultActiveKey={["1"]}
                            style={{ flex: 1 }}
                          >
                            <Collapse.Panel
                              header={
                                <span>
                                  Panel 1 Header{" "}
                                  <span style={{ color: "grey" }}>(2:59)</span>
                                </span>
                              }
                              key="1"
                            >
                              {/* Content for Panel 1 */}
                            </Collapse.Panel>
                            <Collapse.Panel header="Panel 2" key="2">
                              {/* Content for Panel 2 */}
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </Drawer>
                    </div>{" "}
                    {/* <Button type="primary" onClick={showDrawer}>
                      Open
                    </Button> */}
                  </div>

                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center text-center"
                      style={{ minHeight: "100vh" }}
                    >
                      <Spin size="large" />
                    </div>
                  ) : (
                    <>
                      {Object.keys(recordingData).length > 2 ? (
                        <div className="d-flex sdfe">
                          <div className="cl">
                            <div className="message-text-area12 mt-4">
                              <>
                                <ul
                                  className="position-relative"
                                  id="messagesEndRef"
                                >
                                  <div className="mainComment2">
                                    <div className="elastic-header">
                                      <p>
                                        {replaceTxt(recordingData?.topic, [
                                          "nextyn",
                                          "micro",
                                          "consulting",
                                          "-consulting",
                                          "call - ",
                                          "call",
                                          "-",
                                        ])}
                                      </p>
                                      <div className="d-flex align-items-center sdeejd">
                                        <li>
                                          <Calendar className="me-2" />{" "}
                                          {convertDate(
                                            recordingData?.recording_files[0]
                                              ?.recording_start,
                                          )}
                                        </li>
                                        <li>
                                          <Timer className="me-2" />
                                          {getTimeDifference(
                                            recordingData?.recording_files[0]
                                              ?.recording_start,
                                            recordingData?.recording_files[0]
                                              ?.recording_end
                                          )}
                                        </li>
                                      </div>
                                    </div>

                                    <div className={ROOT_CSS}>
                                      <li className="adminMessage clearfix p-3">
                                        {recordingData?.assembly_response
                                          ?.auto_highlights ? (
                                          <div className="summary-content">
                                            <h6 className="smmry">
                                              SUMMARY KEYWORDS
                                            </h6>
                                            <p
                                              style={{ marginBottom: "5px" }}
                                              className="highlightsWords"
                                            >
                                              {recordingData?.assembly_response?.auto_highlights_result?.results.map(
                                                function (object, i) {
                                                  const {
                                                    count,
                                                    rank,
                                                    text,
                                                    timestamps,
                                                  } = object;

                                                  return (
                                                    <>
                                                      <span
                                                        onClick={() => {
                                                          searchHighlights(
                                                            text
                                                          );
                                                          logTimestamps(
                                                            timestamps,
                                                            0
                                                          );
                                                        }}
                                                      >
                                                        {text}
                                                      </span>
                                                      {", "}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </p>
                                          </div>
                                        ) : null}
                                        <div id="scriptBody">
                                          {recordingData?.assembly_response?.utterances?.map(
                                            (value) => (
                                              <>
                                                <div
                                                  className="position-relative mt-4 wordPara"
                                                  id={value.start}
                                                  data-end={value.end}
                                                >
                                                  <div
                                                    className="speakerName"
                                                    style={{
                                                      backgroundColor:
                                                        "#EFEFEF",
                                                    }}
                                                  >
                                                    {value.speaker}
                                                  </div>
                                                  <div
                                                    style={{
                                                      paddingLeft: "50px",
                                                    }}
                                                    className="speaker_wrapper"
                                                  >
                                                    <h6
                                                      className="mb-1 d-flex align-items-center"
                                                      style={{
                                                        fontWeight: 600,
                                                        color: "#000",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      Speaker {value.speaker}{" "}
                                                      <span
                                                        style={{
                                                          color: "#8294a5",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                          display:
                                                            "inline-block",
                                                          marginLeft: "14px",
                                                        }}
                                                      >
                                                        {convertMS(value?.end)}
                                                      </span>
                                                    </h6>
                                                    <p
                                                      style={{
                                                        fontSize: "12px",
                                                        color: "#05294b",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        createHighlighted(
                                                          value?.start
                                                        );
                                                      }}
                                                    >
                                                      {value.words.map(
                                                        (word) => (
                                                          <>
                                                            <span
                                                              id={`txt_${word.start}`}
                                                              className="words"
                                                            >
                                                              {word.text}{" "}
                                                            </span>
                                                          </>
                                                        )
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                      </li>
                                    </div>
                                  </div>
                                  <div className="messageEditorWrapper2">
                                    <div className="form-group">
                                      <div className="main3">
                                        {/* <AudioPlayer
                                      autoPlay={false}
                                      src={
                                        recordingData?.recording_files[0] &&
                                        recordingData?.recording_files[0]
                                          ?.s3_download_url
                                      }

                                      ref={audioRef}
                                    // other props here
                                    /> */}

                                        <div
                                          ref={audioPlayerRef}
                                          className="audio-player mt-2"
                                        >
                                          {loading ? (
                                            <Spin size="large" />
                                          ) : (
                                            <>
                                              <div className="progress_section">
                                                <div className="current_time">
                                                  {formatTime(currentTime)}
                                                </div>
                                                <div className="progress-contaoiner">
                                                  <div className="progress-contaoiner">
                                                    <Slider
                                                      value={currentTime}
                                                      min={0}
                                                      max={duration}
                                                      onChange={
                                                        handleSliderChange
                                                      }
                                                      onAfterChange={
                                                        handleSliderAfterChange
                                                      }
                                                      style={{ width: "100%" }}
                                                      trackStyle={{
                                                        backgroundColor:
                                                          "#526fd7",
                                                      }}
                                                      handleStyle={{
                                                        borderColor: "#526fd7",
                                                        backgroundColor:
                                                          "#526fd7",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="total_time">
                                                  {formatTime(duration)}
                                                </div>
                                              </div>
                                              <div className="progress_section_control">
                                                <div className="controls d-flex justify-content-center mb-3">
                                                  <button
                                                    onClick={handleSeek.bind(
                                                      null,
                                                      0
                                                    )}
                                                    className="control-button"
                                                  >
                                                    <BsSkipStartFill />
                                                  </button>
                                                  <button
                                                    onClick={handleRewind}
                                                    className="control-button"
                                                  >
                                                    <BsFillRewindFill />
                                                  </button>
                                                  {isPlaying ? (
                                                    <button
                                                      onClick={handlePause}
                                                      className="control-button"
                                                    >
                                                      <BsPauseCircleFill />
                                                    </button>
                                                  ) : (
                                                    <button
                                                      onClick={handlePlay}
                                                      className="control-button"
                                                    >
                                                      <BsPlayCircleFill />
                                                    </button>
                                                  )}
                                                  <button
                                                    onClick={handleForward}
                                                    className="control-button"
                                                  >
                                                    <BsFillFastForwardFill />
                                                  </button>
                                                  <button
                                                    onClick={handleSeek.bind(
                                                      null,
                                                      duration - 5
                                                    )}
                                                    className="control-button"
                                                  >
                                                    <BsSkipEndFill />
                                                  </button>
                                                </div>
                                                {/* <div className="volume-control d-flex justify-content-end">
                                          <div className="me-4">
                                            {volume > 0 ? (
                                              <button onClick={() => handleVolumeChange(0)} className="control-button">
                                                <BsVolumeUp />
                                              </button>
                                            ) : (
                                              <button onClick={() => handleVolumeChange(1)} className="control-button">
                                                <BsVolumeMute />
                                              </button>
                                            )}
                                          </div>
                                          <Slider value={Math.round(volume * 100)} onChange={(value) => handleVolumeChange(value / 100)} style={{ width: '100%' }} />
                                        </div> */}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ul>
                              </>
                            </div>
                          </div>
                          <div
                            className={
                              opens ? "nxtCl mt-4 active" : "nxtCl mt-4"
                            }
                            style={{ marginLeft: "8px" }}
                          >
                            <div className="mainComment2 smrry">
                              <div className="elastic-header">
                                <p className="mb-0">Summary</p>
                              </div>
                              {recordingData?.assembly_response
                                ?.auto_chapters === true ? (
                                <>
                                  <div className="smry-para">
                                    {recordingData?.assembly_response?.chapters.map(
                                      (summrData, id) => {
                                        return (
                                          <>
                                            <Collapse
                                              bordered={false}
                                              defaultActiveKey={null}
                                              style={{ flex: 1 }}
                                              key={id}
                                              className="collapseBid"
                                            >
                                              <Collapse.Panel
                                                header={
                                                  <p
                                                    onClick={() =>
                                                      createHighlighted(
                                                        summrData?.start
                                                      )
                                                    }
                                                    className="mb-0 collapse-header"
                                                  >
                                                    {summrData?.headline}{" "}
                                                    <small className="mr-4">
                                                      (
                                                      {convertMS(
                                                        summrData?.start
                                                      )}
                                                      )
                                                    </small>
                                                  </p>
                                                }
                                                key="1"
                                              >
                                                <ul className="listType">
                                                  <li
                                                    style={{
                                                      boxShadow: "none",
                                                      paddingBottom: 0,
                                                      fontSize: "12px",
                                                      color: "#000000D9",
                                                    }}
                                                  >
                                                    {summrData?.summary}
                                                  </li>
                                                </ul>
                                                {/* <p>{summrData?.summary}</p> */}
                                              </Collapse.Panel>
                                            </Collapse>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </>
                              ) : null}

                              {/* <ul className="py-3">
                            <li>
                              <div className="d-flex">
                                <div style={{ width: "100%" }}>
                                  <p className="mb-0">
                                    Call center industry in India, including
                                    market size and pricing.{" "}
                                    <small>(7:38)</small>
                                  </p>
                                </div>
                                <div
                                  style={{ width: "30px", textAlign: "center" }}
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.2 275.4c0-8.6 3.4-17.401 10-24.001 13.2-13.2 34.8-13.2 48 0l451.8 451.8 445.2-445.2c13.2-13.2 34.8-13.2 48 0s13.2 34.8 0 48L542 775.399c-13.2 13.2-34.8 13.2-48 0l-475.8-475.8c-6.8-6.8-10-15.4-10-24.199z"></path>
                                  </svg>
                                </div>
                              </div>
                            </li>
                          </ul> */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div
        className="modal fade pro-jects1"
        id="shareModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "5px" }}
            >
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {/* <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90" />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90"
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div> */}

                <div className="mt-4 inviteSection text-left">
                  <div className="inviteTitle text-start">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      className="inputGroupInput"
                      name="email"
                      value={payload.email}
                      onChange={(event) => {
                        handleRequestInputs(event);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#000",
                          }}
                        >
                          {getDefaultText(payload.permission)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() =>
                              updatePermission("editi", payload.email)
                            }
                          >
                            Can invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() =>
                              updatePermission("view", payload.email)
                            }
                          >
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {/* {show ? ( */}
                <div
                  className="d-flex justify-content-end"
                  style={{ border: "none" }}
                >
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-secondary"
                    style={{ marginRight: "1rem" }}
                    //   onClick={() => {
                    //     setEmail("");
                    //   }}
                  >
                    Cancel
                  </button>
                  {recordingData?.permittedUsers?.filter(
                    (o) => o.email === userEmail.trim()
                  ).length > 0 ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#5746ec", border: "none" }}
                      disabled
                      // onClick={makeUpdate}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#5746ec", border: "none" }}
                      onClick={() =>
                        updatePermission(payload.permission, payload.email)
                      }
                    >
                      Submit
                    </button>
                  )}
                </div>
                {/* ) : (
                  ""
                )} */}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">{getPUserList()}</div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                    onClick={stopSharing}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranscriptDetail;
