import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Progress } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { BsPlusLg } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import no_found from "../assests/images/Post-traumatic.svg";
import userColored from "../assests/images/userColored.svg";
import API_PATH from "../Constants/api-path";
import MailEditor from "../MailEditor";
import Sidebar from "../Sidebar";
import TopMenu from "../TopMenu";
import { CopyToClipboard } from "../Utils/CopyToClipboard";
import FolderViewTable from "./FolderViewTable";

const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const FolderView = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [coverDetailsData, setCoverDetailsData] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEmail, setEmail] = useState("");
  const [noteDetailsData, setNoteDetailsData] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [value, setValue] = useState("");
  const [contentData, setcontentData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
    coverId: "",
  });
  const [sleeveData, setSleeveData] = useState({
    sleeveName: "",
    sleeveType: "",
    summary: "",
    publicUrl: "",
    transcript: "",
    userId: "",
    companyId: "",
    roleId: "",
    coverId: "",
    isAllowAll: true,
    permittedUsers: [],
    fileType: "",
    fileSize: 0,
  });

  const [coverData, setcoverData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
  });

  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
  });

  const [file, setFile] = useState(null);
  const [fileprogress, setFileprogress] = useState(0);

  const handleContentInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setcontentData({ ...contentData, [key]: value });
  };

  const handleCoversInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setcoverData({ ...coverData, [key]: value });
  };

  const handleSleevesInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setSleeveData({ ...sleeveData, [key]: value });
  };
  const [show, setShow] = useState(false);
  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const getCover = async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_DETAILS}/${searchParams.get("coverID")}/${userData.user._id
        }`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result);
        setCoverDetailsData(result);
        setcoverData({
          ...coverData,
          name: result.cover.name,
          description: result.cover.description,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getCover();
  }, []);

  // console.log(coverData);
  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const stopSharing = async (e) => {
    e.preventDefault();

    try {
      let payload = {};
      payload.permittedUsers = [];
      const res = await fetch(
        `${API_PATH.COVER_UPDATE}?coverID=${searchParams.get("coverID")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await res.json();
      if (result) {
        getCover();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const updateNotePermission = async (permission, email) => {
    setPayload({
      email: email,
      permission: permission,
    });
  };

  // console.log(payload);
  const makeUpdate = async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_PERMISSION}/${searchParams.get("coverID")}/${userData.user._id
        }`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({
            permission: payload.permission,
            email: payload.email,
          }),
        }
      );

      const result = await res.json();
      if (result) {
        setEmail("");
        getCover();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const updatePermission = async (permission, email) => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_PERMISSION}/${searchParams.get("coverID")}/${userData.user._id
        }`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({ permission: permission, email: email }),
        }
      );

      const result = await res.json();
      if (result) {
        getCover();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  //console.log(noteDetailsData);
  const getPUserList = () => {
    if (coverDetailsData.cover.permittedUsers instanceof Array) {
      return coverDetailsData.cover.permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, permission, username } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                    />
                  </div>

                  {/* <div
                    className="profileIcon"
                    style={{
                      backgroundImage: `url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${email})`,
                    }}
                  ></div> */}
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">
                        {firstNmae ? firstNmae : username}
                      </span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can edit and invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        onClick={() => updatePermission("edit", email)}
                      >
                        Can edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  const getExtentionFromFileNmae = (fileName) => {
    return (
      fileName
        .substring(fileName.lastIndexOf(".") + 1, fileName.length)
        .toLowerCase() || fileName.toLowerCase()
    );
  };

  const checkFileType = (fileName) => {
    const videoType = ["mp4", "mov", "mkv", "webm", "avi"];
    const audioType = ["mp3", "aav"];
    const imageType = [
      "jpeg",
      "png",
      "gif",
      "tiff",
      "psd",
      "eps",
      "ai",
      "indd",
      "raw",
    ];
    const pdfType = ["pdf"];
    const docType = ["doc", "docx", "odt", "ppt"];
    const excelType = ["xls", "xlsx", "ods"];
    const textType = ["txt", "js", "html", "css", "py", "java", "ts"];
    const ext = getExtentionFromFileNmae(fileName);
    if (videoType.includes(ext)) return "video";
    else if (audioType.includes(ext)) return "audio";
    else if (imageType.includes(ext)) return "image";
    else if (pdfType.includes(ext)) return "pdf";
    else if (docType.includes(ext)) return "doc";
    else if (excelType.includes(ext)) return "excel";
    else if (textType.includes(ext)) return "text";
    else return "system";
  };

  const createContent = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("createContentdisabled1").disabled = true;
    try {
      let payload = {
        ...contentData,
      };
      payload.description = value;
      payload.coverId = coverDetailsData.cover._id;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id._id;
      // console.log(payload);
      const res = await fetch(`${API_PATH.CONTENT_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        setSpinner(false);
        document.getElementById("closeContentModal1").click();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return toast.success("Content Created Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const createSleeve = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("createSleevedisabled1").disabled = true;

    try {
      if (file) {
        var data = new FormData();
        let fileName = `${Date.now()}-${file.name.split(" ").join("+")}`;
        data.append("file", file, fileName);
        axios
          .request({
            method: "post",
            url: `${API_PATH.UPLOAD_MEDIA}/${userData.user.company_id.shortName}`,
            data: data,
            headers: headers,
            onUploadProgress: (p) => {
              // console.log(p);
              setFileprogress(Math.floor((p.loaded / p.total) * 100));
            },
          })
          .then(async (s3Result) => {
            // console.log(s3Result);

            if (s3Result) {
              let payload = {
                ...sleeveData,
              };
              payload.sleeveType = checkFileType(file.name);
              payload.coverId = coverDetailsData.cover._id;
              payload.fileType = file.type;
              payload.fileSize = s3Result.data.data.size;
              payload.fileExt = getExtentionFromFileNmae(file.name);
              payload.sleeveKey = fileName;
              payload.publicUrl = s3Result.data.data.location;
              payload.summary = value;
              payload.companyId = userData.user.company_id;
              payload.userId = userData.user._id;
              payload.roleId = userData.user.role_id._id;
              // console.log(payload);
              const res = await fetch(`${API_PATH.SLEEVE_CREATE}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": userData.access_token,
                },
                body: JSON.stringify(payload),
              });

              const result = await res.json();
              if (result) {
                setSpinner(false);
                document.getElementById("FolderModal1").click();
                userData.user.company_id.bucketSize =
                  userData.user.company_id.bucketSize - payload.fileSize;
                localStorage.setItem("userData", JSON.stringify(userData));
                setFileprogress(0);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
                return toast.success("Sleeve Created Sucessfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              } else {
                return toast.error("something went wrong server error", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              }
            } else {
              return toast.error("Please upload a file", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const deleteNote = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${API_PATH.COVER_DELETE}?coverID=${searchParams.get("coverID")}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
        }
      );

      const result = await res.json();
      if (result) {
        document.getElementById("DeleteModal2").click();
        window.history.go(-1);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const editCover = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("editCoverdisabled1").disabled = true;
    try {
      let payload = {
        ...coverDetailsData.cover,
      };
      payload.name = coverData.name;
      payload.description = value;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id._id;
      // console.log(payload);
      const res = await fetch(
        `${API_PATH.COVER_UPDATE}?coverID=${searchParams.get("coverID")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await res.json();
      if (result) {
        setSpinner(false);
        document.getElementById("coverModal1").click();
        getCover();
        return toast.success("Cover updated sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div>
            <div className="dasb-div bk-div float-start w-100">
              {coverDetailsData.cover ? (
                <>
                  <div className="body-expart-div projects-pages support-page">
                    <div className="d-flex top-ps-seection justify-content-between align-items-center">
                      <div className="coverInnerBreadcum">
                        <span>{coverDetailsData.cover.name}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        {coverDetailsData ? (
                          coverDetailsData.cover.permittedUsers?.length > 0 ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              key={Math.random()}
                              placement={"top"}
                              overlay={
                                <Popover
                                  id={`popover-positioned-${Math.random()}`}
                                >
                                  <Popover.Body className="p-2">
                                    {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                              Public link enabled
                            </h6> */}
                                    <div>
                                      <small>
                                        Shared with{" "}
                                        {
                                          coverDetailsData.cover.permittedUsers
                                            .length
                                        }{" "}
                                        people
                                      </small>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {coverDetailsData.cover.permittedUsers?.length >
                                0 ? (
                                <img
                                  src={userColored}
                                  style={{ marginRight: "12px" }}
                                />
                              ) : (
                                <></>
                              )}
                            </OverlayTrigger>
                          ) : (
                            <div
                              style={{
                                color: "#737373",
                                marginRight: "12px",
                              }}
                            >
                              <span style={{ whiteSpace: "nowrap" }}>
                                Only you
                              </span>
                            </div>
                          )
                        ) : (
                          <></>
                        )}

                        <button
                          style={{
                            // backgroundColor: "rgb(0, 168, 45)",
                            color: "#ffffff",
                          }}
                          type="button"
                          to="#"
                          className="btn actionNav one-ex-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#shareModal4"
                        >
                          Share
                        </button>

                        <div className="dropdown">
                          <button
                            className="btn add-ps-btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <BsPlusLg /> Create
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#contentModal1"
                              >
                                <i
                                  class="fa fa-text-width"
                                  aria-hidden="true"
                                  style={{ width: "14px" }}
                                ></i>
                                <span style={{ marginLeft: "8px" }}>
                                  Create Content
                                </span>
                              </a>
                            </li>

                            <li>
                              <a
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#FolderModal1"
                              >
                                <i
                                  class="fa fa-files-o"
                                  aria-hidden="true"
                                  style={{ width: "14px" }}
                                ></i>
                                <span style={{ marginLeft: "8px" }}>
                                  Create File
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <Button
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <MoreHorizIcon />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#coverModal1"
                            href="#"
                            onClick={handleClose}
                          >
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span style={{ marginLeft: "10px" }}>Edit</span>
                          </MenuItem>
                          <MenuItem
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#DeleteModal2"
                            href="#"
                            onClick={handleClose}
                          >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                            <span style={{ marginLeft: "10px" }}>Delete</span>
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    {coverDetailsData.contents.length !== 0 ||
                      coverDetailsData.sleeve.length !== 0 ? (
                      <div className="faq-section">
                        {coverDetailsData ? (
                          <FolderViewTable data={coverDetailsData} />
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="text-center noResultFound">
                          <div className="container">
                            <div className="row d-flex justify-content-center align-items-center">
                              <div className="col-md-6">
                                <img
                                  src={no_found}
                                  alt="srv"
                                  style={{ height: "400px", marginTop: "50px" }}
                                ></img>
                                {/* <svg
                                  class="SVGInline-svg StyledManWithIceCream--1fwimz7-svg dRWpfu-svg"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 179 250"
                                  style={{ height: "400px", marginTop: "50px" }}
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M148.017 226.599c0 .267-.012.532-.035.794l12.858-1.387.273-.003c.737-.013 1.774-.032.317.601-1.27.552-6.004 1.67-9.594 2.518-1.958.462-3.576.844-4.105 1.01 1.489.359 7.101.845 12.527 1.314 5.967.517 11.707 1.013 11.487 1.3-.184.241-3.263.097-7.029-.078-5.381-.251-12.165-.567-13.899.078-1.706.635 3.43 2.687 7.759 4.415 3.155 1.261 5.882 2.35 5.214 2.593-1.179.43-11.424-3.108-16.628-4.905-1.783-.616-2.974-1.027-3.007-1.005-.144.096.891 1.41 1.989 2.805h.001c1.304 1.657 2.698 3.428 2.313 3.404-.252-.016-1.971-1.515-3.714-3.037-1.774-1.548-3.574-3.119-3.882-3.172a58.093 58.093 0 0 1-2.429-.493c-.267-.058-1.868.721-3.416 1.475-1.448.705-2.849 1.388-3.071 1.342-.487-.101.907-1.333 1.99-2.29.706-.624 1.281-1.132 1.114-1.132l-.107.001c-2.12 0-9.231.001-10.653-1.118-.93-.732.567-1.075 2.448-1.505 2.195-.503 4.913-1.125 4.913-2.624 0-4.784 4.487-8.667 9.211-8.667 4.723 0 7.155 2.982 7.155 7.766zm17.953 2.59c-.683-.007-3.645-.583-1.97-.889 1.675-.306 5.508-.306 6.098.292.59.597-3.444.605-4.128.597zm9.878 5.102c-.495.187.953.687 1.636.716.683.029 1.668-.278.684-.642-.984-.363-1.825-.261-2.32-.074zm-7.616 4.744c-.431-.092-4.311-1.505-3.195-1.597 1.116-.092 3.539.385 3.799 1.045.261.66-.174.644-.604.552z"
                                    fill="#FF6DA2"
                                  ></path>
                                  <path
                                    d="M106.952 62.373c2.428-.895-12.124-19.7-12.92-20.15-.795-.452-8.543 8.516-8.278 9.536.265 1.02 18.77 11.51 21.198 10.614z"
                                    fill="#E0CF77"
                                  ></path>
                                  <path
                                    d="M93.962 42.196c.573.332-.074 3.5-2.27 5.858-2.196 2.357-5.524 4.622-6.307 3.36-.784-1.261.377-2.686 2.573-5.043 2.196-2.357 5.43-4.508 6.004-4.175z"
                                    fill="#C59F65"
                                  ></path>
                                  <path
                                    d="M15.703 240.685c6.61 0 12.264-.465 9.519-3.205-2.542-2.539-19.334-8.147-22.122-4.066-1.54 2.254-3.159 6.76.45 6.976 3.61.217 11.055.295 12.153.295zM94.52 222.9c-3.087-.332-9.28 1.602-11.99 2.611 0 0-7.586.477-8.235 3.99-.496 2.685-.152 7.459 3.236 6.198 3.388-1.261 10.276-4.104 11.233-4.642 7.27-4.094 9.614-7.742 5.755-8.157z"
                                    fill="#1A2460"
                                  ></path>
                                  <path
                                    d="M65.97 122.111c-7.762 3.103-26.039 1.293-33.477 0 0 6.787-1.756 9.189-5.636 9.189-7.115 27.145-22.395 90.669-23.947 102.302 1.294 2.586 5.433 3.103 7.761 0 2.329-3.102 24.9-46.535 35.895-67.864 7.438 22.945 24.819 65.475 28.7 64.7 3.88-.776 6.647-3.01 7.294-3.979L65.97 122.111z"
                                    fill="#7482DE"
                                  ></path>
                                  <path
                                    d="M77.55 73.541c-4.657.776-7.114-7.433-7.761-11.634l-6.791 1.94v9.694c1.617 6.14 6.791 17.45 14.552 13.573 6.344-3.17 17.847-13.669 22.252-27.1-4.31-3.98-2.653-5.306-3.979-8.621-.995-1.327-2.159 5.05-2.321 5.968-1.229 6.962-11.295 15.404-15.952 16.18zm22.712-22.454c-.663 0-.995-.663-.663-1.99 1.326-.331 3.647 2.653 3.979 3.98.331 1.325.864 2.294.201 2.626-.317.158-.814-.411-.995-.995-.197-.637-.081-1.3-.533-1.3-.331 0-1.124-.689-1.124-1.352 0-.663-.202-.97-.865-.97zm-46.845-27.66c-.876 1.768-5.174 6.95-6.998 8.424l-.663 2.627c6.202 3.683 18.167 10.166 16.416 6.63-1.75-3.536-1.459-6.63-1.094-7.735 2.554-2.21 7.661-6.851 7.661-7.735 0-.885 1.46-1.842 2.189-2.21-.876-1.768.73-7.367 1.094-8.84l-10.944-6.63-7.661 6.63c.365 2.21.875 7.072 0 8.84z"
                                    fill="#DD6652"
                                  ></path>
                                  <path
                                    d="M57.794 19.317c-.875 1.768-3.948 4.777-5.407 5.882 0-1.104 1.03-4.775-1.16-8.091l-.001-.003c-2.189-3.315-4.375-6.628-1.093-7.732 3.284-1.105 1.095-7.735 3.284-6.63 2.188 1.105 3.283-3.316 6.566-1.106 3.284 2.21 6.567-3.314 7.661-1.104 1.095 2.21 2.19 4.42 4.378 4.42 2.189 0 4.378 4.42 3.283 5.524-.875.884 1.095 3.315-1.094 4.42-2.189 1.106-4.914 1.018-6.572-.64-1.658-1.657-3.865.247-5.637.64-1.663.37-1.51-2.508-2.984.687-.652 1.412-.13 1.523-1.224 3.733z"
                                    fill="#1A2460"
                                  ></path>
                                  <path
                                    d="M57.795 29.264c-1.094-3.315 3.284-9.945 4.378-11.05 1.095-1.105 2.554-2.579 2.189-1.105-.365 1.473-1.313 3.757-2.189 5.525-1.094 2.21 0 3.315 1.095 4.42.875.884 4.013-.369 5.472-1.105-.365 1.841-1.314 5.967-2.19 7.735-1.094 2.21-3.282 3.315-6.566 2.21-3.283-1.105-1.094-3.315-2.189-6.63z"
                                    fill="#1A2460"
                                  ></path>
                                  <path
                                    d="M57.465 14.395c.36-1.989 2.879-2.79 3.034 0-.443 1.138-1.294 2.924-1.441 3.433-.184.638-.176.74-.704.74-.469 0-.768-.867-.663-1.327.216-.943-.412-1.814-.226-2.846z"
                                    fill="#DD6652"
                                  ></path>
                                  <path
                                    d="M64.03 65.881c1.552 13.185 1.94 42.981 1.94 56.231-12.417 4.653-31.044 1.292-38.805-.97 4.527-17.755 13.776-53.275 14.552-53.322-3.88.324-12.612-.194-16.492-4.847 1.293-8.08 7.373-25.595 21.343-31.024.97 2.909 6.567 8.834 15.104 8.834 5.433 4.653 7.209 15.403 9.15 21.22-.971.97-3.688 3.103-6.792 3.878z"
                                    fill="#FDC8DA"
                                  ></path>
                                  <path
                                    d="M28.134 133.745c-1.553-4.653-1.617-47.181-1.94-68.833 2.586 1.615 9.119 5.041 14.551 5.817-3.557 15.188-9.035 48.724-5.93 57.255 3.104 8.532-3.448 7.054-6.681 5.761z"
                                    fill="#DD6652"
                                  ></path>
                                  <path
                                    d="M41.717 67.82c-8.537 0-14.552-3.231-16.492-4.847v4.847c2.587 1.94 9.313 5.623 15.522 4.848l.97-4.848zm29.103-5.817c-3.105 3.103-5.821 3.878-6.791 3.878l-.97-3.878c1.617-.323 5.238-1.357 6.79-2.908l.97 2.908z"
                                    fill="#EBA3BF"
                                  ></path>
                                  <path
                                    d="M3.28 142.491c4.76-2.418 57.613-4.307 59.561 0 1.949 4.307 3.647 39.117 0 41.842-3.646 2.726-57.613 4.307-59.561 0-1.948-4.307-4.76-39.424 0-41.842z"
                                    fill="#333"
                                  ></path>
                                  <path
                                    d="M28.048 131.377c-4.576-.897-8.32 6.281-6.24 9.87h4.16c-.693-2.243-1.248-6.28 2.08-6.28 4.16 0 7.904 0 8.736 1.345.923 1.494.555 4.038.832 4.935h4.16c-.416-13.01-9.152-8.973-13.728-9.87z"
                                    fill="#333"
                                  ></path>
                                  <path
                                    d="M37.137 132.957c0 2.015-2.375 3.648-5.305 3.648-2.93 0-5.305-1.633-5.305-3.648 0-2.014 1.05-6.962 3.98-6.962 2.929 0 6.63 4.948 6.63 6.962z"
                                    fill="#DD6652"
                                  ></path>
                                </svg> */}
                              </div>
                              <div className="col-md-6">
                                <h3
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "left",
                                    fontWeight: "300",
                                    lineHeight: "26px",
                                  }}
                                >
                                  <span style={{ fontWeight: "500" }}>
                                    Oops,
                                  </span>{" "}
                                  Folder is empty!
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </main>
      </div>

      {/*share modals*/}
      <div
        className="modal fade addmodal cm-modal-all"
        id="shareModal4"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mt-4">
                <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText={window.location.href} />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value={window.location.href}
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div>

                <div className="mt-4 inviteSection">
                  <div className="inviteTitle">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      className="inputGroupInput"
                      name="email"
                      value={userEmail}
                      onChange={(event) => {
                        handleRequestInputs(event);
                        handleChange(event);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                        >
                          {payload.email !== "" && payload.permission !== ""
                            ? payload.permission === "editi"
                              ? "edit and invite"
                              : payload.permission
                            : "Can edit and invite"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() =>
                              updateNotePermission("editi", userEmail)
                            }
                          >
                            Can edit and invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() =>
                              updateNotePermission("edit", userEmail)
                            }
                          >
                            Can edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() =>
                              updateNotePermission("view", userEmail)
                            }
                          >
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {show && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ border: "none" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        setEmail("");
                        setPayload({ email: "", permission: "" });
                      }}
                    >
                      Cancel
                    </button>
                    {coverDetailsData.cover.permittedUsers.filter((o) => o.email === userEmail.trim()).length > 0 ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        disabled
                      // onClick={() => makeUpdate()}
                      >
                        Invite
                      </button>)
                      : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ backgroundColor: "#5746ec", border: "none" }}
                          onClick={makeUpdate}
                        >
                          Invite
                        </button>

                      )}
                  </div>
                )}

                {coverDetailsData &&
                  coverDetailsData.cover.permittedUsers &&
                  coverDetailsData.cover.permittedUsers?.length > 0 ? (
                  <>
                    <button className="accessInfo" type="button">
                      <span style={{ transform: "rotateZ(90deg)" }}>
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          id="qa-SHARE_DIALOG_SHARED_WITH_ARROW"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 7l6 5-6 5V7z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      {coverDetailsData.cover.permittedUsers &&
                        coverDetailsData.cover.permittedUsers?.length > 0 ? (
                        <div
                          id="qa-SHARE_DIALOG_SHARED_WITH_MORE"
                          className="s1E8jgYqDWXyHdyBw59R awJGfcqMABRSyxZlnZGS _7_HBJzy3qX1OrTcUZny"
                        >
                          Who has access
                          <span className="Oy7u6Y9EGKJJM_TfDDta">
                            {coverDetailsData.cover.permittedUsers
                              ? coverDetailsData.cover.permittedUsers?.length
                              : 0}
                          </span>
                        </div>
                      ) : null}
                    </button>
                  </>
                ) : (
                  <></>
                )}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">
                        {coverDetailsData ? getPUserList() : <></>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                    onClick={stopSharing}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {Content modal} */}
      <div
        className="modal fade addmodal cm-modal-all"
        id="contentModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Your Content
              </h5>
              <button
                id="closeContentModal1"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center">Create Your Content</h2>
                {/* <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p> */}
                <form method="get" action="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> Content Title* </label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleContentInputs}
                          className="form-control"
                        // placeholder="Content Title*"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> Pick Folder </label>

                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="coverId"
                          onChange={handleContentInputs}
                        >
                          {coverDetailsData ? (
                            <option>{coverDetailsData.cover.name}</option>
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label> Content Area * </label>
                    <div className="message-modal">
                      <textarea
                        placeholder="Write...."
                        className="form-control d-none"
                        name="description"
                        onChange={handleContentInputs}
                      ></textarea>
                      <figure className="mx-auto d-none">
                        <img src="images/textarea-img.png" alt="texta" />
                      </figure>
                      <MailEditor setValue={setValue} config={config} />
                    </div>
                  </div>

                  <div className="">
                    <Button
                      className="btn submit-bn"
                      style={{ textTransform: "capitalize" }}
                      onClick={createContent}
                      id="createContentdisabled1"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Create Content
                        </>
                      ) : (
                        "Create Content"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {sleeve Modal} */}

      <div
        className="modal fade addmodal cm-modal-all"
        id="FolderModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Your Files
              </h5>
              <button
                id="closeSleeveModal1"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center">Add Your Files</h2>

                <form method="get" action="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> File Name Title* </label>
                        <input
                          type="text"
                          name="sleeveName"
                          onChange={handleSleevesInputs}
                          className="form-control"
                        // placeholder="Content Title*"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> Pick Folder </label>

                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="coverId"
                          onChange={handleSleevesInputs}
                        >
                          {coverDetailsData ? (
                            <option>{coverDetailsData.cover.name}</option>
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label style={{ width: "100%" }}> Content Area * </label>

                    <FileUploader handleChange={handleFileChange} name="file" />

                    {fileprogress === 0 ? (
                      <></>
                    ) : (
                      <Progress
                        strokeColor={{
                          "0%": "#108ee9",
                          "100%": "#87d068",
                        }}
                        percent={fileprogress}
                      />
                    )}
                  </div>

                  <div className="">
                    <Button
                      className="btn submit-bn"
                      style={{ textTransform: "capitalize" }}
                      onClick={createSleeve}
                      id="createSleevedisabled1"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Create File
                        </>
                      ) : (
                        "Create File"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {Delete Modal} */}

      <div
        className="modal fade"
        id="DeleteModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "12px", marginBottom: "-1rem" }}>
                    Do you really want to deactivate these records? This process
                    cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-end"
              style={{ border: "none" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                style={{ border: "none" }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={deleteNote}
                className="btn btn-primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* {coverModal} */}
      <div
        className="modal fade addmodal cm-modal-all"
        id="coverModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Folder
              </h5>
              <button
                id="closeCoverModal1"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center"> Edit Folder </h2>
                {/* <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p> */}
                <form method="get" action="">
                  <div className="form-group">
                    <label> Folder Name * </label>
                    <input
                      type="text"
                      name="name"
                      value={coverData.name}
                      onChange={handleCoversInputs}
                      className="form-control"
                    // placeholder="Note TItle"
                    />
                  </div>

                  <div className="form-group">
                    <label> Description * </label>
                    <div className="message-modal">
                      <textarea
                        name="description"
                        value={coverData.description}
                        onChange={handleCoversInputs}
                        placeholder="Write...."
                        className="form-control d-none"
                      ></textarea>
                      <figure className="mx-auto d-none">
                        <img src="images/textarea-img.png" alt="texta" />
                      </figure>
                      <MailEditor
                        value={coverData.description}
                        setValue={setValue}
                        config={config}
                      />
                    </div>
                  </div>
                  <div className="">
                    <Button
                      className="btn submit-bn"
                      style={{ textTransform: "capitalize" }}
                      onClick={editCover}
                      id="editCoverdisabled1"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Edit Folder
                        </>
                      ) : (
                        "Edit Folder"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FolderView;
