// privateRoute
import React from 'react';
import { Navigate } from 'react-router-dom';
// import {isExpired,decodeToken} from "react-jwt";
// // import { connect } from 'react-redux';
// const userData = JSON.parse(localStorage.getItem("userData"));
// const token = userData?.access_token;
const PrivateRoute = ({children,token}) => {
    // console.log(token, "token");
    // console.log(children, "children")
  let isAuth = JSON.parse(localStorage.getItem("isAuth"));
//   const myDecodeToken = decodeToken(token);
// console.log("hgdfshdcyuddc",myDecodeToken);
    if(!isAuth){
        return <Navigate to="/" replace/>
    }
    return children;
    // if(myDecodeToken === null){
    //   return <Navigate to="/" replace/>
    // }
    // return children;
}

export default PrivateRoute;    