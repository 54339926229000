import React, { useState, useEffect, useRef } from "react";
import TopMenu1 from "../TopMenu1";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import "moment-timezone";
import "./FullCalendar.css";
import Sidebar from "../Sidebar";
import TranscriptModal from "./TranscriptModal";
import { toast } from "react-toastify";
import API_PATH from "../Constants/api-path";
import {
  Link,
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import countries from "../Utils/country";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import TimezoneSelect from "react-timezone-select";
import FlashCard from "../Utils/FlashCard";
import SuccessModal from "../Utils/SuccessModal";
function Fullcalendar() {
  const [selectEvent, setSelectEvent] = useState([]);
  const [selectedProfile, setselectedProfile] = useState("");
  const UserData = JSON.parse(localStorage.getItem("userData"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [scheduledEvent, setScheduledEvent] = useState("");
  const [expertTimeZone, setExpertTimeZone] = useState("");
  let [selectedTimezone, setSelectedTimezone] = useState({});
  const [clientTimeZone, setClientTimeZone] = useState({});
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  var tz = moment.tz.guess();
  let calendarRef = useRef();

  function getQueryVariable(variable) {
    console.log("sdds", variable);
    let index = countries.findIndex((o) => o.nicename === variable);
    if (index >= 0) {
      return index;
    } else return 0;
  }

  useEffect(() => {
    console.log(
      'localStorage.getItem("timeZone")',
      localStorage.getItem("timeZone")
    );
    if (localStorage.getItem("timeZone")) {
      setClientTimeZone(localStorage.getItem("timeZone"));
      setSelectedTimezone(localStorage.getItem("timeZone"));
    } else {
      setClientTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, []);
console.log("selectedtimezone",selectedTimezone);
  const getExpertSchedule = async () => {
    const headers = {
      "Content-Type": "text/plain",
      "x-access-token": UserData.access_token,
    };
    const user_id = userData.user._id;

    const res = await fetch(
      `${API_PATH.EXPERT_SCHEDULE}/${searchParams.get("expertId")}/${user_id}`,
      {
        method: "GET",
        headers: headers,
      }
    );

    let result = await res.json();
    if (result) {
      let calendarEvents = [];
      result.data.forEach((element) => {
        let obj = {};
        obj["title"] = element.title;
        obj["start"] = getDate(element.start);
        obj["end"] = getDate(element.end);
        calendarEvents.push(obj);
      });
      setScheduledEvent(calendarEvents);
      // setSelectEvent(calendarEvents);
      console.log(calendarEvents, "987656787");
      setExpertTimeZone(
        moment.tz.zonesForCountry(
          countries[getQueryVariable(result?.timeZone?.data[0]["country "])].iso
        )[0]
      );

      console.log(
        moment.tz.zonesForCountry(
          countries[getQueryVariable(result?.timeZone?.data[0]["country "])].iso
        )[0]
      );
      setselectedProfile(result.profile);
      // history({
      //   pathname: "/expert-profile",
      //   search: `?${searchParams.get("expertId")}`,
      // });
    } else {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  console.log("tttttzzzzzzzz",clientTimeZone);
  console.log("zzzzzzzzzz",expertTimeZone);

  const getDate = (dayString) => {
    if (clientTimeZone) {
      return moment(dayString).tz(clientTimeZone).format();
    } else {
      return moment(dayString).tz(tz).format();
    }
  };

  // const goToDetailsPage = (expertId) => {
  //   history({
  //     pathname: "/expert-profile",
  //     search: `?${createSearchParams({ expertId })}`,
  //   });
  // };

  useEffect(() => {
    getExpertSchedule();
  }, [clientTimeZone, isSuccessModal, isErrorModal]);

  // add 0 if time value is less than 9
  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }

  setSelectedTimezone = (e) => {
    if (e.value !== undefined) {
      localStorage.setItem("timeZone", e.value);
      calendarRef?.current?.getApi().setOption("timeZone", e.value);
      calendarRef?.current?.getApi().changeView("timeGridWeek");
      setClientTimeZone(e.value);
      window.location.reload();
    }
  };

  const getFullCalender = () => {
    if (Array?.isArray(scheduledEvent)) {
      return (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
          timeZone={clientTimeZone}
          initialView="timeGridWeek"
          nowIndicator={true}
          firstDay={1}
          titleFormat={{ month: "long", year: "numeric" }}
          selectAllow={(selectInfo) => {
            return moment().tz(clientTimeZone).diff(selectInfo.startStr) <= 0;
          }}
          dayHeaderFormat={(args) => {
            return (
              <>
                <div className="fc_custom_date">
                  {doubleDigit(args.date.day.toString())}
                </div>
                <div className="fc_custom_weekDay">
                  {moment(args.date).format("dddd").substring(0, 3)}
                </div>
              </>
            );
          }}
          ref={calendarRef}
          allDaySlot={false}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
          }}
          events={scheduledEvent}
          locale={"en-GB"}
          selectable={true}
          // selectHelper={true}
          eventResizableFromStart={true}
          dateClick={(dateClickInfo) => {
            console.log("dateClickInfo", dateClickInfo);
          }}
          editable={false}
          headerToolbar={{
            start: "today prev,next,title",
            center: "",
            right: "",
          }}
          slotDuration={"00:15:00"}
          slotLabelInterval={"01:00"}
          viewDidMount={(view) => {
            // get all available time from time grid
            let viewM = view?.el?.querySelectorAll(
              ".fc-scrollgrid-shrink-cushion"
            );

            // add header for time slot
            view.el.querySelector(".fc-timegrid-axis-frame").innerHTML =
              "<span class='expertTime'>Expert</span><span class='yourTime'>You</span>";

            // assign time according to expert timezone and local
            for (var i = 0; i < viewM.length; i++) {
              var getTime = convertLocalToTz(
                viewM[i].innerHTML,
                expertTimeZone
              );
              var getTimeZoned = getTime.split(":");
              var getTimeSplited = viewM[i].innerHTML.split(":");
              viewM[i].innerHTML =
                "<span class='expertTime expertTime_" +
                i +
                "'>" +
                getTimeZoned[0] +
                ":" +
                getTimeZoned[1] +
                "</span><span class='yourTime yourTime_" +
                i +
                "'>" +
                doubleDigit(getTimeSplited[0]) +
                ":" +
                getTimeSplited[1] +
                "</span>";
            }
          }}
          selectMirror={false}
          eventAllow={(dropInfo, draggedEvent) => {}}
          eventDragStop={(info) => {
            console.log(info);
          }}
          eventOverlap={false}
          eventBackgroundColor={"#5746ec"}
          eventOrder={"title, start, duration"}
          unselectAuto={false}
          eventResourceEditable={false}
          select={(selectionInfo) => {
            let tempEvents = [];
            var tempObj = {};
            tempObj["id"] = new Date().getTime();
            tempObj["title"] = "Client Availability";
            tempObj["start"] = moment.utc(selectionInfo.startStr).format();
            tempObj["end"] = moment.utc(selectionInfo.endStr).format();
            tempEvents.push(tempObj);
            calendarRef?.current?.getApi().addEventSource(tempEvents);
            setSelectEvent([...selectEvent, ...tempEvents]);
          }}
          droppable={true}
        />
      );
    }
  };
console.log("sdsdsdsdsdsdsd",selectEvent);
  // convert time into expert timezone
  function convertLocalToTz(dt, timeZon = "") {
    var dt = new Date("2022-01-01 " + dt);
    var utcTime = moment(dt).utc().format();
    var otherTimeZone = moment.tz(utcTime, timeZon).format("HH:mm");
    return otherTimeZone;
  }

  const getModal = () => {
    if (selectEvent.length > 0) {
      return <SuccessModal data={selectEvent} />;
    } else return <FlashCard data={selectEvent} />;
  };
  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          {getFullCalender()}

          <div className="bttmFttr">
            <div className="d-flex justify-content-end">
              <Link to={"/find-expert"}>
                <button className="btn me-3">Cancel</button>
              </Link>
              <button
                className="btn nextBttn"
                data-bs-toggle="modal"
                data-bs-target="#createProjectModal1"
                aria-current="page"
                disabled={selectEvent.length === 0}
                // onClick={getModal()}
              >
                Next
              </button>
            </div>
          </div>
        </main>
      </div>
      <TranscriptModal
        datas={selectedProfile}
        selectEvent={selectEvent}
        expertId={searchParams.get("expertId")}
        selected={selectEvent}
        setIsSuccessModal={setIsSuccessModal}
        setIsErrorModal={setIsErrorModal}
      />
      <TimezoneSelect
        value={clientTimeZone}
        className="customSie"
        onChange={setSelectedTimezone}
      />
      {isSuccessModal ? (
        <SuccessModal data={selectEvent} isSuccessModal={isSuccessModal} />
      ) : (
        <></>
      )}
      {isErrorModal ? (
        <FlashCard data={selectEvent} isErrorModal={isErrorModal} />
      ) : (
        <></>
      )}
    </>
  );
}

export default Fullcalendar;
